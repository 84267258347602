import { call, put, select, all } from 'redux-saga/effects'

import {
  BASIC_TOP_CATEGORY_ID,
  BASIC_ROCK_CATEGORY_ID,
  MAKEUP_TOP_CATEGORY_ID,
  MAKEUP_ROCK_CATEGORY_ID,
} from '@common/constants'

import { SetLayerAction } from '../ConfiguratorScreen.types'

import fetchProducts from './fetchProducts.saga'

const categoryIds = [
  BASIC_TOP_CATEGORY_ID,
  BASIC_ROCK_CATEGORY_ID,
  MAKEUP_TOP_CATEGORY_ID,
  MAKEUP_ROCK_CATEGORY_ID,
]

export default function* (action: SetLayerAction): any {

  try {

    const { products } = yield select(state => state.configuratorScreen)

    const { layer } = action

    if (!products[layer] || (products[layer] && !products[layer].length)) {

      yield call(fetchProducts, layer, categoryIds[layer])

    }

  }
  catch (err) {

    yield call(console.error, err)

  }

}

import React from 'react'

import {
  StyleSheet,
  Platform,
  View,
  TextInput,
} from 'react-native'

import {
  TEXT_SM,
  TEXT_13,
  TEXT_MD,
  COLOR_GREY,
  COLOR_PINK, MON_500
} from '@common/constants'

import Text from '@components/Text.component'
import IconButton from '@components/IconButton.component'

const TextInputComponent = ({
  containerStyle,
  label,
  error,
  inputProps,
  buttonProps,
  style
}: any) => (

  <View
    style={[
      styles.container,
      containerStyle,
    ]}
    >

    <Text
      style={[
        styles.label,
        !!error && styles.labelError,
      ]}>
      {label}
    </Text>

    <View
      style={[
        styles.inputContainer,
        !!error && styles.inputContainerError,
        style
      ]}>

      <TextInput
        style={[
          styles.input,
          Platform.OS === 'web' && { outlineStyle: 'none' }
        ]}
        { ...inputProps } />

      {!!buttonProps &&
      <IconButton
        style={styles.button}
        { ...buttonProps }/>
      }

    </View>

    {!!error &&
    <Text
      style={[
        styles.error,
      ]}>
      {error}
    </Text>
    }

  </View>

)

const styles = StyleSheet.create({

  container: {
    marginBottom: 25,
  },

  inputContainer: {
    paddingVertical: 8,
    paddingHorizontal: 22,
    borderRadius: 22,
    borderWidth: 2,
    borderColor: 'white',
    flexDirection: 'row',
    alignItems: 'center',
    height: 54,
    backgroundColor: '#fff',
  },

  inputContainerError: {
    borderColor: COLOR_PINK,
  },

  label: {
    marginBottom: 4,
    paddingLeft: 10,
    fontSize: TEXT_13,
    fontFamily: MON_500,
    color: COLOR_GREY,
  },

  labelError: {
    fontFamily: MON_500,
    fontSIze: TEXT_SM,
  },

  input: {
    flex: 1,
    height: 54,
    fontFamily: MON_500,
    fontSize: TEXT_MD
  },

  button: {
    marginRight: 0,
  },

  error: {
    paddingLeft: 10,
    fontFamily: MON_500,
    color: COLOR_GREY,
    fontSize: TEXT_SM,
    marginTop: 2,
  },

})

export default TextInputComponent

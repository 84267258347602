import { call, put } from 'redux-saga/effects'

import validate from 'validate.js'

import { setFormErrors } from '@common/actions'

import { mapValidationErrors } from '@common/utils'

export default function* validateForm(name, constraints, data) {

  yield put(setFormErrors(name, {}))

  const errors: object = yield call(validate, data, constraints, { fullMessages: false })

  if (errors) {

    yield put(setFormErrors(name, mapValidationErrors(errors)))

    throw Error(`Validation failed for '${name}' form`)

  }

  return mapValidationErrors(errors)

}

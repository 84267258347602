
import {
  Look,
} from '@screens/Profile/ProfileScreen.types'

import {
  ConfiguratorScreenActionType as ActionType,
  Product,
  ProductVariant,
  ViewType,
  INIT,
  INIT_SUCCESS,
  INIT_FAILURE,
  FETCH_PRODUCTS_ATTEMPT,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  SET_LAYER,
  SET_BROWSER_VISIBLE,
  SET_ITEM,
  SET_LOOK,
  SET_VIEW_TYPE,
  NAVIGATE_TO_PROFILE,
  STORE_LOOK,
  STORE_LOOK_SUCCESS,
  STORE_LOOK_FAILURE,
  SHARE_LOOK,
  SHARE_LOOK_FAILURE,
  SHARE_LOOK_SUCCESS,
} from './ConfiguratorScreen.types'

export const init = (): ActionType => ({
  type: INIT,
})

export const initSuccess = (): ActionType => ({
  type: INIT_SUCCESS,
})

export const initFailure = (error: any): ActionType => ({
  type: INIT_FAILURE,
  error,
})

export const fetchProductsAttempt = (layer: number): ActionType => ({
  type: FETCH_PRODUCTS_ATTEMPT,
  layer,
})

export const fetchProductsSuccess = (layer: number, products: Product[], dressSize: string): ActionType => ({
  type: FETCH_PRODUCTS_SUCCESS,
  layer,
  products,
  dressSize,
})

export const fetchProductsFailure = (layer: number, error: any): ActionType => ({
  type: FETCH_PRODUCTS_FAILURE,
  layer,
  error,
})

export const setLayer = (layer: number): ActionType => ({
  type: SET_LAYER,
  layer,
})

export const setBrowserVisible = (visible: boolean): ActionType => ({
  type: SET_BROWSER_VISIBLE,
  visible,
})

export const setItem = (item: ProductVariant): ActionType => ({
  type: SET_ITEM,
  item,
})

export const setLook = (look: Look): ActionType => ({
  type: SET_LOOK,
  look,
})

export const setViewType = (viewType: ViewType): ActionType => ({
  type: SET_VIEW_TYPE,
  viewType,
})

export const navigateToProfile = (): ActionType => ({
  type: NAVIGATE_TO_PROFILE,
})

export const storeLook = (): ActionType => ({
  type: STORE_LOOK,
})

export const storeLookSuccess = (look: Look): ActionType => ({
  type: STORE_LOOK_SUCCESS,
  look,
})

export const storeLookFailure = (error: any): ActionType => ({
  type: STORE_LOOK_FAILURE,
  error,
})

export const shareLook = (): ActionType => ({
  type: SHARE_LOOK,
})

export const shareLookSuccess = (): ActionType => ({
  type: SHARE_LOOK_SUCCESS,
})

export const shareLookFailure = (error: any): ActionType => ({
  type: SHARE_LOOK_FAILURE,
  error,
})


import {
  RegisterScreenActionType as ActionType,
  INIT,
  INIT_SUCCESS,
  INIT_FAILURE,
  RESET,
  SET_ERROR,
  PICK_DATE,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
} from './RegisterScreen.types'

export const init = (): ActionType => ({
  type: INIT,
})

export const initSuccess = (salutations: any[], countries: any[]): ActionType => ({
  type: INIT_SUCCESS,
  salutations,
  countries,
})

export const initFailure = (error: any): ActionType => ({
  type: INIT_FAILURE,
  error,
})

export const reset = (): ActionType => ({
  type: RESET,
})

export const pickDate = (): ActionType => ({
  type: PICK_DATE,
})

export const setError = (error?: string): ActionType => ({
  type: SET_ERROR,
  error,
})

export const register = (): ActionType => ({
  type: REGISTER,
})

export const registerSuccess = (): ActionType => ({
  type: REGISTER_SUCCESS,
})

export const registerFailure = (error: any): ActionType => ({
  type: REGISTER_FAILURE,
  error,
})


import produce from 'immer'

import {
  CommonActionType,
  SET_FORM_VALUE,
  SET_FORM_ERRORS,
} from '@common/types'

import {
  PasswordRecoveryScreenState,
  PasswordRecoveryScreenActionType,
  RESET,
  SET_ERROR,
  REQUEST_PASSWORD_RECOVERY_EMAIL,
  REQUEST_PASSWORD_RECOVERY_EMAIL_SUCCESS,
  REQUEST_PASSWORD_RECOVERY_EMAIL_FAILURE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
} from './PasswordRecoveryScreen.types'

const INITIAL_STATE: PasswordRecoveryScreenState = {
  busy: false,
  formValues: {
    email: '',
    recoveryToken: '',
    newPassword: '',
    newPasswordConfirm: '',
  },
  formErrors: {
  },
}

export default produce((
  draft: PasswordRecoveryScreenState,
  action: CommonActionType|PasswordRecoveryScreenActionType
) => {

  switch (action.type) {

    case RESET:
      if (action.route.params.step === 1)
        return INITIAL_STATE
      else
      if (action.route.params.step === 2)
        return {
          ...INITIAL_STATE,
          formValues: {
            ...INITIAL_STATE.formValues,
            email: draft.formValues.email,
          },
        }
      break

    case SET_FORM_VALUE:
      if (action.form === 'passwordRecovery') {
        draft.formValues[action.key] = action.val
      }
      break

    case SET_FORM_ERRORS:
      if (action.form === 'passwordRecovery') {
        draft.formErrors = action.errors
      }
      break

    case SET_ERROR:
      draft.error = action.error
      break

    case REQUEST_PASSWORD_RECOVERY_EMAIL:
    case CHANGE_PASSWORD:
      draft.busy = true
      break

    case REQUEST_PASSWORD_RECOVERY_EMAIL_SUCCESS:
    case CHANGE_PASSWORD_SUCCESS:
      draft.busy = false
      break

    case REQUEST_PASSWORD_RECOVERY_EMAIL_FAILURE:
    case CHANGE_PASSWORD_FAILURE:
      draft.busy = false
      break

  }

}, INITIAL_STATE)


import { takeLatest } from '@redux-saga/core/effects'

import loginScreen from '@screens/Login/LoginScreen.saga'
import registerScreen from '@screens/Register/RegisterScreen.saga'
import passwordRecoveryScreen from '@screens/PasswordRecovery/PasswordRecoveryScreen.saga'
import configuratorScreen from '@screens/Configurator/ConfiguratorScreen.saga'
import detailsScreen from '@screens/Details/DetailsScreen.saga'
import profileScreen from '@screens/Profile/ProfileScreen.saga'
import changeEmailScreen from '@screens/ChangeEmail/ChangeEmailScreen.saga'
import changePasswordScreen from '@screens/ChangePassword/ChangePasswordScreen.saga'
import changeProfileScreen from '@screens/ChangeProfile/ChangeProfileScreen.saga'
import storesScreen from '@screens/Stores/StoresScreen.saga'
import looksScreen from '@screens/Looks/LooksScreen.saga'
import appointmentScreen from '@screens/Appointment/AppointmentScreen.saga'
import finishScreen from '@screens/Finish/FinishScreen.saga'

import {
  INIT,
  MAKE_APPOINTMENT,
} from '@common/types'

import init from './init.saga'
import makeAppointment from './makeAppointment.saga'

function* saga() {

  yield takeLatest(INIT, init)

  yield takeLatest(MAKE_APPOINTMENT, makeAppointment)

  yield* loginScreen()
  yield* registerScreen()
  yield* passwordRecoveryScreen();

  yield* configuratorScreen()
  yield* detailsScreen()
  yield* profileScreen()
  yield* changeEmailScreen()
  yield* changePasswordScreen()
  yield* changeProfileScreen()
  yield* storesScreen()
  yield* looksScreen()
  yield* appointmentScreen()
  yield* finishScreen()

}

export default saga

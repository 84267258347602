
import {
  AlertModalActionType as ActionType,
  SHOW_ALERT_MODAL,
  ALERT_MODAL_DISMISSED,
} from './AlertModal.types'

export const showAlertModal = (message: string, dismissLabel: string): ActionType => ({
  type: SHOW_ALERT_MODAL,
  message,
  dismissLabel,
})

export const alertModalDismissed = (): ActionType => ({
  type: ALERT_MODAL_DISMISSED,
})

import { call, put, select } from 'redux-saga/effects'

import http from '../../../axios'

import {
  setError,
  changeProfileSuccess,
  changeProfileFailure,
} from '../ChangeProfileScreen.actions'

import constraints from '../ChangeProfileForm.constraints'

import validateForm from '../../../sagas/validateForm.saga'

import { goBack } from '@navigation/root'

import { showAlertModal } from '@modals/Alert/AlertModal.actions'

export default function* () {

  try {

    const {
      defaultBillingAddressId,
    } = yield select(state => state.profile)

    const {
      salutationId,
      firstName,
      lastName,
      street,
      zipcode,
      city,
      countryId,
      phoneNumber,
      /*
      dressSize,
      weddingDate,
      */
    } = yield select(state => state.changeProfileScreen.formValues)

    const formValues = yield select(state => state.changeProfileScreen.formValues)

    yield put(setError())

    yield call(validateForm, 'changeProfile', constraints, {
      firstName,
      lastName,
      street,
      zipcode,
      city,
      phoneNumber,
    })

    yield call(http.post, '/account/change-profile', {
      salutationId,
      firstName,
      lastName,
      /*
      customFields: {
        customer_info_dress_size: dressSize,
        customer_info_wedding_date: weddingDate,
      },
      */
    })

    yield call(http.patch, '/account/address/' + defaultBillingAddressId, {
      salutationId,
      firstName,
      lastName,
      street,
      zipcode,
      city,
      countryId,
      phoneNumber,
    })

    yield put(changeProfileSuccess(
      salutationId,
      firstName,
      lastName,
      street,
      zipcode,
      city,
      countryId,
      phoneNumber,
    ))

    yield call(goBack)

    yield put(showAlertModal('Ihr Profil wurde geändert!', 'Ok'))

  }
  catch (err) {

    yield call(handleError, err)

  }

}

function* handleError(err) {

  yield call(console.error, err)

  yield put(changeProfileFailure(err))

}

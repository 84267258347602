import {Montserrat_500Medium, Montserrat_600SemiBold} from '@expo-google-fonts/montserrat';

export const LAYER_SWITCH_BUTTON_SPACING = 8

export const BROWSER_WIDTH = 288
export const BROWSER_ITEM_SIZE = 96

//export const BROWSER_HEIGHT = 256
//export const BROWSER_ITEM_SIZE = 124

export const BROWSER_ITEM_SPACING = 16

export const PREVIEW_IMAGE_WIDTH = 1280
export const PREVIEW_IMAGE_HEIGHT = 1280

export const TEXT_SM = 11
export const TEXT_13 = 13
export const TEXT_MD = 15
export const TEXT_XL = 38
export const LINE_HEIGHT_XL = 40

export const UNNA_REG = 'Unna_400Regular'
export const MON_300 = 'Montserrat_300Light'
export const MON_500 = 'Montserrat_500Medium'
export const MON_600 = 'Montserrat_600SemiBold'
export const MON_700 = 'Montserrat_700Bold'

export const RADIUS_17 = 17

export const COLOR_GREY = '#61646C'
export const COLOR_GREY_LIGHT = '#B9B9B9'
export const COLOR_GREY_LIGHTER = '#EFF1F3'
export const COLOR_BLACK = '#292B2E'
export const COLOR_PILE = '#FFF0E8'
export const COLOR_PINK = '#FFAAAA'

export const BOTTOM_BAR_HEIGHT = 92

export const COLOR_GROUP_ID = 'b072ce36bdce4b5d936db7ef725498eb'

export const BASIC_TOP_CATEGORY_ID = '1d5d551d2fa54150bf1e4962c6a28aa5'
export const BASIC_ROCK_CATEGORY_ID = 'e152dd33e74449679fb4ee0c3d0a4324'
export const MAKEUP_TOP_CATEGORY_ID = '3a276c88a7ef4a2aa6f54799d41b8729'
export const MAKEUP_ROCK_CATEGORY_ID = 'dc45727a82a94b32868e255b9ad432c6'

export const FALLBACK_PRODUCT_COLOR = '#EAEAE5'

import { call, put } from 'redux-saga/effects'

import http from "../../../axios";

import { InitAction } from '../LoginScreen.types';

import { showAlertModal  } from "@modals/Alert/AlertModal.actions";

export default function* (action: InitAction): any {

  try {

    if (action.route.params) {

      yield call(processRouteParams, action.route.params)

    }

  }
  catch (err) {

    yield call(console.error, err)

  }

}

function* processRouteParams(params: any) {

  try {

    if (params.action === 'confirmRegistration' &&
        params.hash &&
        params.em) {

      yield call(confirmRegistration, params.hash, params.em)

    }

  }
  catch (err) {

    yield call(console.error, err)

  }

}

function* confirmRegistration(hash: string, em: string) {

  try {

    yield call(http.post, '/account/register-confirm', {
      hash,
      em,
    })

    yield put(showAlertModal('Dein Account wurde erfolgreich aktiviert. Du kannst Dich jetzt einloggen.', 'Ok'))

  }
  catch (err) {

    yield call(console.error, err)

  }

}

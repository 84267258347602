import { call, put, select } from 'redux-saga/effects'

import {
  findStoresSuccess,
  findStoresFailure,
} from '../StoresScreen.actions'

import validateForm from '../../../sagas/validateForm.saga'

import fetchStores from './fetchStores.saga'

const constraints = {
  location: {
    presence: {
      allowEmpty: false,
      message: 'Bitte Ort eingeben',
    },
  },
}

export default function* (): any {

  try {

    const { location } = yield select(state => state.storesScreen.formValues)

    yield call(validateForm, 'stores', constraints, {
      location,
    })

    const stores = yield call(fetchStores, location)

    yield put(findStoresSuccess(stores))

  }
  catch (err) {

    yield call(console.error, err)

    yield put(findStoresFailure(err))

  }

}

import produce from 'immer'

import {
  GlobalState,
  CommonActionType,
  INIT_SUCCESS,
} from '@common/types'

import {
  LoginScreenActionType,
  LOGIN_SUCCESS,
} from '@screens/Login/LoginScreen.types'

import {
  ProfileScreenActionType,
  LOGOUT_SUCCESS,
} from '@screens/Profile/ProfileScreen.types'

const INITIAL_STATE: GlobalState = {
  apiUrl: '',
  apiKey: '',
  storefrontUrl: '',
}

export default produce((
  draft: GlobalState,
  action: CommonActionType|LoginScreenActionType|ProfileScreenActionType
) => {

  switch (action.type) {

    case INIT_SUCCESS:
      draft.apiUrl = action.apiUrl
      draft.apiKey = action.apiKey
      draft.storefrontUrl = action.storefrontUrl
      draft.loggedIn = !!action.customer
      break

    case LOGIN_SUCCESS:
      draft.loggedIn = true
      break

    case LOGOUT_SUCCESS:
      draft.loggedIn = false
      break

  }

}, INITIAL_STATE)

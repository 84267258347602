
export interface  LoginScreenFormValues {
  username: string,
  password: string,
}

export interface  LoginScreenFormErrors {
  username: any,
  password: any,
}

export interface  LoginScreenState {
  busy: boolean,
  formValues: LoginScreenFormValues,
  formErrors: LoginScreenFormErrors,
  error?: string,
}

export const RESET = 'LoginScreen.reset'

export const SET_ERROR = 'LoginScreen.setError'

export const INIT = 'LoginScreen.init'

export const LOGIN = 'LoginScreen.login'
export const LOGIN_SUCCESS = 'LoginScreen.login.success'
export const LOGIN_FAILURE = 'LoginScreen.login.failure'

export const PASSWORD_RECOVERY = 'LoginScreen.passwordRecovery'

export interface ResetAction {
  type: typeof RESET,
}

export interface SetErrorAction {
  type: typeof SET_ERROR,
  error?: string,
}

export interface InitAction {
  type: typeof INIT,
  route: any,
}

export interface LoginAction {
  type: typeof LOGIN,
}

export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS,
  customer: any,
}

export interface LoginFailureAction {
  type: typeof LOGIN_FAILURE,
  error: any,
}

export interface PasswordRecoveryAction {
  type: typeof PASSWORD_RECOVERY,
}

export type LoginScreenActionType =
  ResetAction |
  SetErrorAction |
  InitAction |
  LoginAction |
  LoginSuccessAction |
  LoginFailureAction |
  PasswordRecoveryAction

import React from 'react'

import {
  StyleSheet,
  View,
} from 'react-native'

import LayerSwitch from './LayerSwitch.component'
import Configurator from './Configurator/Configurator.component'
import BottomBar from './BottomBar.component'

const ConfiguratorScreen = ({
  loggedIn,
  layers,
  products,
  browserVisible,
  browserLoading,
  selectedLayer,
  selectedItems,
  look,
  price,
  viewType,
  setLayer,
  setBrowserVisible,
  setItem,
  setViewType,
  storeLook,
  shareLook,
  navigateToProfile,
}: any) => (

  <View style={styles.container}>

    <LayerSwitch
      layers={layers}
      selectedLayer={selectedLayer}
      switchLayer={(index: number) => setLayer(index)} />

    <Configurator
      showPrices={loggedIn}
      browserVisible={browserVisible}
      browserLoading={browserLoading}
      setItem={setItem}
      hideBrowser={() => setBrowserVisible(false)}
      showBrowser={() => setBrowserVisible(true)}
      shareLook={shareLook}
      setViewType={setViewType}
      layers={layers}
      products={products}
      selectedLayer={selectedLayer}
      selectedItems={selectedItems}
      viewType={viewType}/>

    <BottomBar
      look={look}
      price={price}
      showPrice={loggedIn}
      navigateToProfile={navigateToProfile}
      storeLook={storeLook}/>

  </View>

)

const styles = StyleSheet.create({

  container: {
    flex: 1,
  },

})

export default ConfiguratorScreen


import { takeLatest } from 'redux-saga/effects'

import {
  INIT,
  NAVIGATE_TO_PROFILE,
  SET_ITEM,
  SET_LAYER,
  SHARE_LOOK,
  STORE_LOOK,
} from './ConfiguratorScreen.types'

import init from './sagas/init.saga'
import setLayer from './sagas/setLayer.saga'
import setItem from './sagas/setItem.saga'
import navigateToProfile from './sagas/navigateToProfile.saga'
import shareLook from './sagas/shareLook.saga'
import storeLook from './sagas/storeLook.saga'

export default function* () {

  yield takeLatest(INIT, init)

  yield takeLatest(SET_LAYER, setLayer)

  yield takeLatest(SET_ITEM, setItem)

  yield takeLatest(NAVIGATE_TO_PROFILE, navigateToProfile)

  yield takeLatest(SHARE_LOOK, shareLook)

  yield takeLatest(STORE_LOOK, storeLook)

}

import {call, put, select, take} from 'redux-saga/effects'

import {
  setFormValue,
} from '@common/actions'

import {showDateModal} from "@modals/Date/DateModal.actions";

import {
  DATE_MODAL_ACCEPTED,
  DATE_MODAL_DISMISSED,
} from "@modals/Date/DateModal.types";

export default function* () {

  try {

    const { date } =  yield select(state => state.appointmentScreen.formValues)

    yield put(showDateModal('Bitte wähle ein Datum:', date))

    const result = yield take([DATE_MODAL_DISMISSED, DATE_MODAL_ACCEPTED])

    if (result.type === DATE_MODAL_DISMISSED || !result.date) {

      return

    }

    yield put(setFormValue('appointment', 'date', result.date))

  }
  catch (err) {

    yield call(console.error, err)


  }

}

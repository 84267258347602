
import {
  ChangeProfileScreenActionType as ActionType,
  INIT,
  INIT_SUCCESS,
  INIT_FAILURE,
  RESET,
  SET_ERROR,
  CHANGE_PROFILE,
  CHANGE_PROFILE_SUCCESS,
  CHANGE_PROFILE_FAILURE,
} from './ChangeProfileScreen.types'

export const init = (): ActionType => ({
  type: INIT,
})

export const initSuccess = (salutations: [], countries: [], profile: any): ActionType => ({
  type: INIT_SUCCESS,
  salutations,
  countries,
  profile,
})

export const initFailure = (error: any): ActionType => ({
  type: INIT_FAILURE,
  error,
})

export const reset = (): ActionType => ({
  type: RESET,
})

export const setError = (error?: string): ActionType => ({
  type: SET_ERROR,
  error,
})

export const changeProfile = (): ActionType => ({
  type: CHANGE_PROFILE,
})

export const changeProfileSuccess = (
  salutationId: string,
  firstName: string,
  lastName: string,
  street: string,
  zipcode: string,
  city: string,
  countryId: string,
  phoneNumber: string,
): ActionType => ({
  type: CHANGE_PROFILE_SUCCESS,
  salutationId,
  firstName,
  lastName,
  street,
  zipcode,
  city,
  countryId,
  phoneNumber,
})

export const changeProfileFailure = (error: any): ActionType => ({
  type: CHANGE_PROFILE_FAILURE,
  error,
})

import React from 'react'

import {
  StyleSheet,
} from 'react-native'

import Modal from '@components/Modal.component';
import TimeSelect from "@modals/Time/TimeSelect.component";

const TimeModal = ({
  visible,
  times,
  selectedTime,
  timeModalAccepted,
  timeModalDismissed,
}: any) => (

  <Modal
    visible={visible}
    onDismiss={timeModalDismissed}
    content={

      <TimeSelect
        times={times}
        selectedTime={selectedTime}
        setTime={(time: string) => timeModalAccepted(time)}/>

    }

  />

)

const styles = StyleSheet.create({

})

export default TimeModal

import React from 'react'

import {
  StyleSheet,
  ScrollView,
  View,
} from 'react-native'

import Text from '@components/Text.component'
import Button from '@components/DarkButton.component'
import Message from '@components/Message.component'
import BusyScreen from '@components/BusyScreen.component'

import LoginForm from './LoginForm.component'

import {
  TEXT_MD,
  TEXT_13,
  COLOR_GREY,
  UNNA_REG,
  TEXT_XL,
  COLOR_BLACK,
  LINE_HEIGHT_XL,
  MON_500,
  MON_600,
  MON_700, COLOR_PILE
} from '@common/constants'
import DarkButton from '@components/DarkButton.component';

const LoginScreen = ({
   busy,
   formValues,
   formErrors,
   error,
   setFormValue,
   login,
   passwordRecovery,
   navigation,
 }: any) => (

  busy ?

    <BusyScreen message='Bitte warten'/>

    :

    <ScrollView
      style={styles.container}>

      {!!error &&
      <Message message={error} style={styles.error} />
      }

      <View style={styles.hero}>
        <Text style={styles.headline} >
          Willkommen im LeMoos Brautkleid Designer
        </Text>
        <Text style={styles.text} >
          Damit Du Dein Traumkleid einfach selbst designen kannst, brauchen wir noch ein paar Informationen von Dir.
        </Text>
      </View>

      <LoginForm
        formName="login"
        formValues={formValues}
        formErrors={formErrors}
        setFormValue={setFormValue}
        submit={login}/>

      <View style={styles.btnContainerInner}>
        <Button
          onPress={passwordRecovery}
          label="Passwort vergessen?"
          style={styles.forgot}
          pressedColor={'transparent'}
        />
        <Button
          style={styles.loginBtn}
          onPress={login}
          label="Anmelden"
        />


        {/*<Button*/}
        {/*  onPress={skipLogin}*/}
        {/*  label="Überspringen"*/}
        {/*/>*/}

        <View style={styles.containerRegister} >
          <Text style={styles.registerText}>
            Du hast noch kein Profil?
          </Text>
          <Button
            onPress={() => navigation.navigate('Register')}
            label="Registrieren"
            style={styles.registerBtn}
          />
        </View>
      </View>

  </ScrollView>

)

const styles = StyleSheet.create({

  container: {
    flex: 1,
  },

  error: {
    backgroundColor: '#fff',
    borderRadius: 22,
  },

  hero: {
    alignSelf: 'flex-end',
    paddingHorizontal: 24,
    marginHorizontal: 'auto',
    marginBottom: 24,
  },

  headline: {
    fontSize: TEXT_XL,
    fontFamily: UNNA_REG,
    lineHeight: LINE_HEIGHT_XL,
    color: COLOR_BLACK,
    fontWeight: '500',
  },

  text: {
    color: COLOR_GREY,
    fontSize: TEXT_MD,
    lineHeight: 23,
    fontFamily: MON_500,
    marginTop: 24
  },

  loginBtn: {
    width: '100%',
  },

  bottomContainer: {
    padding: 24,
  },

  btnContainerInner: {
    flex: 1,
    paddingHorizontal: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },

  btn: {
    width:  '100%',
    height: 48,
    color: '#61646C'
  },

  btnLabel: {
    position: 'absolute',
    color: '#F9EAE8',
    fontSize: TEXT_MD,
    fontFamily: MON_700,
    top: 15,
    zIndex: 1,
  },

  forgot: {
    marginTop: 4,
    marginBottom: 20,
    width: '100%',
    padding: 0,
    backgroundColor: 'transparent',
    textAlign: 'end',
    color: COLOR_GREY,
    fontSize: TEXT_13,
    fontFamily: MON_600,
  },

  containerRegister: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'row',
    width: '100%',
    marginTop: 15,
    paddingHorizontal: 22,
  },
  pressed: {
    backgroundColor: COLOR_PILE,
  },
  registerText: {
    fontSize: TEXT_13,
    color: COLOR_GREY,
    fontFamily: MON_500,
  },
  registerBtn: {
    backgroundColor: 'transparent',
    fontSize: TEXT_13,
    color: COLOR_BLACK,
    fontFamily: MON_600,
    padding: 0,
    height: 'auto',
    marginLeft: 3,
  }

})

export default LoginScreen

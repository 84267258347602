import {
  call,
  put,
  select,
  take,
} from 'redux-saga/effects'

import http from '../../../axios'

import {
  changeDressSizeSuccess,
  changeDressSizeFailure,
} from '../ProfileScreen.actions'

import {showPromptModal} from '@modals/Prompt/PromptModal.actions';

import {
  PROMPT_MODAL_ACCEPTED,
  PROMPT_MODAL_DISMISSED,
} from '@modals/Prompt/PromptModal.types';

export default function* () {

  try {

    const { dressSize, weddingDate } = yield select(state => state.profile)

    yield put(showPromptModal('Welche Konfektionsgröße hast Du:', 'Abbrechen', 'Ok', dressSize))

    const result = yield take([PROMPT_MODAL_DISMISSED, PROMPT_MODAL_ACCEPTED])

    if (result.type === PROMPT_MODAL_DISMISSED || !result.input) {

      return

    }

    yield call(http.post, '/account/change-info', {
      dressSize: result.input,
      weddingDate,
    })

    yield put(changeDressSizeSuccess(result.input))

  }
  catch (err) {

    yield call(console.error, err)

    yield put(changeDressSizeFailure(err))

  }

}

import React from 'react'

import {
  StyleSheet,
  View,
} from 'react-native'

import {
  TEXT_13,
  MON_500,
} from "../../common/constants";

import Text from "@components/Text.component";
import SecureInput from '@components/SecureInput.component'

const ChangePasswordForm = ({
  formName,
  formValues,
  formErrors,
  setFormValue,
  submit,
}: any) => (

  <View
    style={styles.container}>

    <SecureInput
      label="Aktuelles Passwort:*"
      error={formErrors.password}
      inputProps={{
        value: formValues.password,
        onChangeText: (password: string) => setFormValue(formName, 'password', password),
        onSubmitEditing: submit,
      }}
    />

    <SecureInput
      label="Neues Passwort:*"
      error={formErrors.newPassword}
      inputProps={{
        value: formValues.newPassword,
        onChangeText: (newPassword: string) => setFormValue(formName, 'newPassword', newPassword),
        onSubmitEditing: submit,
      }}
    />

    <SecureInput
      label="Neues Passwort bestätigen:*"
      error={formErrors.newPasswordConfirm}
      inputProps={{
        value: formValues.newPasswordConfirm,
        onChangeText: (newPasswordConfirm: string) => setFormValue(formName, 'newPasswordConfirm', newPasswordConfirm),
        onSubmitEditing: submit,
      }}
    />

    <Text style={styles.pflicht}>
      *Pflichtfelder
    </Text>

  </View>

)

const styles = StyleSheet.create({

  container: {
    flex: 1,
    paddingHorizontal: 24,
  },

  pflicht: {
    fontSize: TEXT_13,
    fontFamily: MON_500,
    color: 'rgb(97, 100, 108)',
    marginTop: 16,
  },

})

export default ChangePasswordForm

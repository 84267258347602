import { call, put, select, take } from 'redux-saga/effects'

import http from '../../../axios'

import {
  ProductVariant,
} from '../ConfiguratorScreen.types'

import {
  storeLookFailure,
  storeLookSuccess,
} from '../ConfiguratorScreen.actions'

import {Look} from '@screens/Profile/ProfileScreen.types'

import {showAlertModal} from '@modals/Alert/AlertModal.actions';
import {showPromptModal} from '@modals/Prompt/PromptModal.actions';
import {showConfirmModal} from '@modals/Confirm/ConfirmModal.actions';

import {
  CONFIRM_MODAL_ACCEPTED,
  CONFIRM_MODAL_DISMISSED,
} from '@modals/Confirm/ConfirmModal.types';

import {
  PROMPT_MODAL_ACCEPTED,
  PROMPT_MODAL_DISMISSED,
} from '@modals/Prompt/PromptModal.types';

import {navigate} from "@navigation/root";

export default function* () {

  try {

    const {
      global: {
        loggedIn,
      },
      configuratorScreen: {
        selectedItems,
        look,
      },
    } = yield select(state => state)

    if (!loggedIn) {

      navigate('Login')

      yield put(showAlertModal('Bitte melde Dich an!', 'Ok'))

      return

    }

    if (!selectedItems.length || !selectedItems.reduce((a: ProductVariant, b: ProductVariant) => a || b)) {

      yield put(showAlertModal('Bitte wähle mindestens ein Produkt aus!', 'Ok'))

      return

    }

    let newLook: Look|null = null;

    if (!look) {

      newLook = yield call(createLook, selectedItems)

    }
    else {

      if (look.items.map((item: ProductVariant) => item && item.id).join('') !==
          selectedItems.map((item: ProductVariant) => item && item.id).join('')) {

        yield put(showConfirmModal('Möchtest Du den aktuellen Look ersetzen?', 'Ja', 'Nein'))

        const result = yield take([CONFIRM_MODAL_ACCEPTED, CONFIRM_MODAL_DISMISSED])

        if (result.type === CONFIRM_MODAL_ACCEPTED) {

          yield call(updateLook, look, selectedItems)

        }
        else {

          newLook = yield call(createLook, selectedItems)

        }

      }
      else {

        yield put(showConfirmModal('Möchtest Du einen neuen Look anlegen?', 'Ja', 'Nein'))

        const result = yield take([CONFIRM_MODAL_ACCEPTED, CONFIRM_MODAL_DISMISSED])

        if (result.type === CONFIRM_MODAL_ACCEPTED) {

          newLook = yield call(createLook, selectedItems)

        }

      }

    }

    return newLook

  }
  catch (err) {

    yield call(console.error, err)

    yield put(storeLookFailure(err))

  }

}

function* createLook(items: ProductVariant[]) {

  const lookCount: number = yield call(fetchLookCount)

  yield put(showPromptModal('Gib Deinem Look einen Namen:', 'Abbrechen', 'Ok', 'Look ' + (lookCount+1)))

  const result = yield take([PROMPT_MODAL_DISMISSED, PROMPT_MODAL_ACCEPTED])

  if (result.type === PROMPT_MODAL_DISMISSED || !result.input) {

    return

  }

  const { data } = yield call(http.post, '/look/create', {
    name: result.input,
    items: items.map((item: ProductVariant) => item && item.id),
  })

  const look = {
    id: data.lookId,
    name: result.input,
    items,
  }

  yield put(storeLookSuccess(look))

  return look

}

function* updateLook(look: Look, items: ProductVariant[]) {

  yield call(http.post, '/look/update', {
    id: look.id,
    items: items.map((item: ProductVariant) => item && item.id),
  })

  yield put(storeLookSuccess({
    ...look,
    items,
  }))

}

function* fetchLookCount() {

  const { data } = yield call(http.post, '/look', {
    includes: {
      bitit_lemoos_extension_look: [],
    },
  })

  return data.total

}

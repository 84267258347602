import { call, put, select } from 'redux-saga/effects'

import http from '../../../axios'

import {
  setFormErrors,
} from '@common/actions'

import {
  setError,
  changePasswordSuccess,
  changePasswordFailure,
} from '../PasswordRecoveryScreen.actions'

import validateForm from '../../../sagas/validateForm.saga'

import { navigate } from '@navigation/root'

import { showAlertModal } from '@modals/Alert/AlertModal.actions'

const constraints = {
  recoveryToken: {
    presence: {
      allowEmpty: false,
      message: 'Bitte angeben',
    },
  },
  newPassword: {
    presence: {
      allowEmpty: false,
      message: 'Bitte neues Passwort eingeben',
    },
    length: {
      minimum: 8,
      message: 'Bitte mindesten 8 Zeichen eingeben',
    },
  },
  newPasswordConfirm: {
    presence: {
      allowEmpty: false,
      message: 'Bitte neues Passwort bestätigen',
    },
  },
}

export default function* () {

  try {

    const {
      recoveryToken,
      newPassword,
      newPasswordConfirm,
    } = yield select(state => state.passwordRecoveryScreen.formValues)

    yield put(setError())

    yield call(validateFormEx, {
      recoveryToken,
      newPassword,
      newPasswordConfirm,
    })

    yield call(http.post, '/account/recovery-password-confirm', {
      hash: recoveryToken,
      newPassword,
      newPasswordConfirm,
    })

    yield put(changePasswordSuccess())

    yield call(navigate, 'Login')

    yield put(showAlertModal('Ihr Passwort wurde geändert!', 'Ok'))

  }
  catch (err) {

    yield call(handleError, err)

  }

}

function* validateFormEx(form) {

  yield call(validateForm, 'passwordRecovery', constraints, form)

  const { newPassword, newPasswordConfirm } = form;

  if (newPassword !== newPasswordConfirm) {

    yield put(setFormErrors('passwordRecovery', {
      newPasswordConfirm: 'Die Passwörter stimmen nicht überein',
    }))

    throw Error("Validation failed for 'changePassword' form")

  }

}

function* handleError(err) {

  yield call(console.error, err)

  yield put(changePasswordFailure(err))

  if (err.response && err.response.data && err.response.data.errors) {

    for (const error of err.response.data.errors) {

      if (error.status === '410') {

        if (error.code === 'CHECKOUT__CUSTOMER_RECOVERY_HASH_EXPIRED') {

          yield put(setError('Das Token ist abgelaufen!'))

        }
        else {

          // TODO display error properly

          yield put(setError(error.detail))

        }

        break

      }

    }

  }

}

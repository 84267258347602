import React, { useState } from 'react'

import {
  StyleSheet,
  LayoutChangeEvent,
  PressableStateCallbackType,
  View,
} from 'react-native'

import {
  COLOR_BLACK,
  COLOR_GREY_LIGHTER
} from '@common/constants'

import { ViewType } from '@screens/Configurator/ConfiguratorScreen.types'

import Button from '@components/DarkButton.component'
import IconButton from '@components/IconButton.component'

import Preview from '../Configurator/Configurator/Preview.component'
import Details from './Details.component'

const DetailsScreen = ({
  loggedIn,
  layers,
  selectedItems,
  price,
  detailsVisible,
  viewType,
  setViewType,
  setDetailsVisible,
  makeAppointment,
  shareLook,
}: any) => {

  const [ scale, setScale ] = useState(0)

  function onLayout(event: LayoutChangeEvent) {

    const layout = event.nativeEvent.layout

    setScale(layout.height * 0.00084)

  }

  return (

  <View
    style={styles.container}>

    <Preview
      style={styles.preview}
      layers={layers}
      selectedItems={selectedItems}
      viewType={viewType}
      scale={scale}
      onLayout={onLayout} />


    <IconButton
      style={{
        position: 'absolute',
        top: 16,
        left: 16,
      }}
      onPress={shareLook}
      source={require('@assets/icons/icon-share.svg' /*'@assets/icons/dark/share.png'*/)} />

    <View style={styles.detailContainer}>

      <IconButton
        style={({ pressed }: PressableStateCallbackType) => ([
          styles.switchBtn,
          pressed && styles.switchBtnPressed,
        ])}
        onPress={() => setViewType(viewType === ViewType.FRONT ? ViewType.BACK : ViewType.FRONT)}
        source={({ pressed }: PressableStateCallbackType) => pressed
          ? require('@assets/icons/dark/frontBackSwitch.png')
          : require('@assets/icons/white/frontBackSwitch.png')} />

      <Details
        showPrices={loggedIn}
        layers={layers}
        selectedItems={selectedItems}
        price={price}
        detailsVisible={detailsVisible}
        setDetailsVisible={() => setDetailsVisible(!detailsVisible)}/>

    </View>

    <Button
      onPress={makeAppointment}
      label="Termin zur Anprobe vereinbaren"
    />

  </View>

  )

}

const styles = StyleSheet.create({

  container: {
    flex: 1,
    padding: 24,
    justifyContent: 'flex-end',
    overflow: 'hidden',
  },

  preview: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 176,
  },

  switchBtn: {
    position: 'absolute',
    top: '-50px',
    left: 0,
    backgroundColor: COLOR_BLACK,
  },

  switchBtnPressed: {
    backgroundColor: COLOR_GREY_LIGHTER,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.15,
    shadowRadius: 20,
  },

  detailContainer: {
    position: 'relative'
  }

})

export default DetailsScreen

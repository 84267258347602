
import axios from 'axios'

const instance = axios.create()

export function initAxios(apiUrl: string, apiKey: string, contextToken: string) {

  Object.assign(instance.defaults, {
    baseURL: apiUrl,
    headers: {
      'sw-access-key'     : apiKey,
      'sw-context-token'  : contextToken,
    }
  })

}

export default instance

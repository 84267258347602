import React from 'react'

import { connect } from 'react-redux'

import { State } from '@common/types'

import {
  makeAppointment,
  pickDate,
  pickTime,
} from './AppointmentScreen.actions'

import Stack from '@navigation/stack'
import options from './AppointmentScreen.options'
import AppointmentScreen from './AppointmentScreen.component'

const mapStateToProps = ({
  appointmentScreen: {
    busy,
    formValues,
    formErrors,
  }
}: State) => ({
  busy,
  formValues,
  formErrors,
})

const mapDispatchToProps = {
  pickDate,
  pickTime,
  makeAppointment,
}

export default function() {

  return (
    <Stack.Screen
      name='Appointment'
      options={options}
      component={connect(mapStateToProps, mapDispatchToProps)(AppointmentScreen)}
    />
  )

}

import {call, put, select, take} from 'redux-saga/effects'

import http from '../../../axios'

import {
  DeleteLookAction,
} from '../ProfileScreen.types'

import {
  deleteLookSuccess,
  deleteLookFailure,
} from '../ProfileScreen.actions'

import {showConfirmModal} from "@modals/Confirm/ConfirmModal.actions";

import {
  CONFIRM_MODAL_ACCEPTED,
  CONFIRM_MODAL_DISMISSED,
} from "@modals/Confirm/ConfirmModal.types";

export default function* (action: DeleteLookAction) {

  try {

    const { look } = yield select(state => state.configuratorScreen)

    if (look && look.id === action.look.id) {

      return

    }
    yield put(showConfirmModal('Möchtest Du den Look wirklich löschen?', 'Ja', 'Nein'))

    const result = yield take([CONFIRM_MODAL_ACCEPTED, CONFIRM_MODAL_DISMISSED])

    if (result.type !== 'CONFIRM_MODAL_ACCEPTED') {

      return

    }

    yield call(http.post, '/look/delete', {
      id: action.look.id,
    })

    yield put(deleteLookSuccess(action.look.id))

  }
  catch (err) {

    yield call(console.error, err)

    yield put(deleteLookFailure(err))

  }

}

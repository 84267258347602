import React from 'react'

import moment from 'moment'

import {
  StyleSheet,
  View,
} from 'react-native'

import {
  TEXT_MD,
  COLOR_GREY,
  COLOR_GREY_LIGHT,
  COLOR_BLACK,
  COLOR_GREY_LIGHTER,
  MON_500
} from '@common/constants'

import { LocaleConfig, Calendar } from 'react-native-calendars'

import Text from '@components/Text.component'

LocaleConfig.locales['de'] = {
  monthNames: ['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'],
  monthNamesShort: ['Jan.','Feb.','Mär','Apr','Mai','Jun','Jul','Aug','Sep','Okt','Nov','Dez'],
  dayNames: ['Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag','Sonntag'],
  dayNamesShort: ['Mo','Di','Mi','Do','Fr','Sa','So'],
  today: 'Heute',
}

LocaleConfig.defaultLocale = 'de'

const DateSelect = ({
  selectedDate,
  setDate,
  ...props
}: any) => (

  <View>

    <View style={styles.greyLine}></View>

    <View style={styles.inner}>

      <Calendar

        onDayPress={setDate}

        renderArrow={(direction) => {
          if (direction == 'left') {
            return (<Text style={styles.text}>Zurück</Text>)
          } else {
            return (<Text style={styles.text}>Weiter</Text>)
          }
        }}

        minDate={moment().toISOString()}

        markedDates={selectedDate ? {

          [selectedDate]: {
            selected: true,
            selectedColor: '#333538',
            customTextStyle: {
              fontSize: TEXT_MD,
              fontFamily: MON_500,
              fontWeight: '500'
            }
          },

        } : null}

        theme={{
          textSectionTitleColor: '#77787B',
          calendarBackground: '#fff',
          textSectionTitleDisabledColor: 'gray',
          dayTextColor: COLOR_GREY,
          todayTextColor: COLOR_BLACK,
          todayBackgroundColor: COLOR_GREY_LIGHTER,
          selectedDayTextColor: 'white',
          selectedDayBackgroundColor: COLOR_BLACK,
          monthTextColor: COLOR_BLACK,
          weekTextColor: COLOR_BLACK,
          weekFontSize: TEXT_MD,
          indicatorColor: COLOR_BLACK,
          arrowColor:  COLOR_BLACK,
          textDayFontFamily: MON_500,
          textMonthFontFamily: MON_500,
          textDayHeaderFontFamily: MON_500,
          disabledArrowColor: '#d9e1e8',
          textDayFontWeight: '500',
          textDayHeaderFontWeight: '500',
          textMonthFontWeight: '500',
          textDayFontSize: TEXT_MD,
          textMonthFontSize: TEXT_MD,
          textDayHeaderFontSize: TEXT_MD,
          textWeekFontSize: TEXT_MD,
          margin: 0,
          base: {
            fontFamily: MON_500,
            fontSize: TEXT_MD,
          }

        }}

        { ...props }
      />

    </View>

  </View>

)

const styles = StyleSheet.create({

  greyLine: {
    borderRadius: 2,
    height: 3,
    width: 42,
    marginHorizontal: 'auto',
    top: -9,
    backgroundColor: COLOR_GREY_LIGHT,
    zIndex: 2,
  },

  inner: {
    overflow: 'hidden',
    borderRadius: 24,
  },

  text: {
    fontFamily: MON_500,
    color: COLOR_GREY,
    fontSize: TEXT_MD
  }

})

export default DateSelect


import {
  ChangePasswordScreenActionType as ActionType,
  RESET,
  SET_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
} from './ChangePasswordScreen.types'

export const reset = (): ActionType => ({
  type: RESET,
})

export const setError = (error?: string): ActionType => ({
  type: SET_ERROR,
  error,
})

export const changePassword = (): ActionType => ({
  type: CHANGE_PASSWORD,
})

export const changePasswordSuccess = (): ActionType => ({
  type: CHANGE_PASSWORD_SUCCESS,
})

export const changePasswordFailure = (error: any): ActionType => ({
  type: CHANGE_PASSWORD_FAILURE,
  error,
})

import { call, put, select } from 'redux-saga/effects'

import AsyncStorage from '@react-native-async-storage/async-storage'

import http from '../../../axios'

import {
  setFormErrors,
} from '@common/actions'

import {
  setError,
  changePasswordSuccess,
  changePasswordFailure,
} from '../ChangePasswordScreen.actions'

import constraints from '../ChangePasswordForm.constraints'

import validateForm from '../../../sagas/validateForm.saga'

import { goBack } from '@navigation/root'

import { showAlertModal } from '@modals/Alert/AlertModal.actions'

export default function* () {

  try {

    const {
      password,
      newPassword,
      newPasswordConfirm,
    } = yield select(state => state.changePasswordScreen.formValues)

    yield put(setError())

    yield call(validateFormEx, {
      password,
      newPassword,
      newPasswordConfirm,
    })

    const { data } = yield call(http.post, '/account/change-password', {
      password,
      newPassword,
      newPasswordConfirm,
    })

    http.defaults.headers['sw-context-token'] = data.contextToken

    yield call(AsyncStorage.setItem, 'login.contextToken', data.contextToken)

    yield put(changePasswordSuccess())

    yield call(goBack)

    yield put(showAlertModal('Ihr Passwort wurde geändert!', 'Ok'))

  }
  catch (err) {

    yield call(handleError, err)

  }

}

function* validateFormEx(form) {

  yield call(validateForm, 'changePassword', constraints, form)

  const { newPassword, newPasswordConfirm } = form;

  if (newPassword !== newPasswordConfirm) {

    yield put(setFormErrors('changePassword', {
      newPasswordConfirm: 'Die Passwörter stimmen nicht überein',
    }))

    throw Error("Validation failed for 'changePassword' form")

  }

}

function* handleError(err) {

  yield call(console.error, err)

  yield put(changePasswordFailure(err))

  if (err.response && err.response.data && err.response.data.errors) {

    for (const error of err.response.data.errors) {

      if (error.status === '400') {

        if (error.code === 'VIOLATION::CUSTOMER_EMAIL_NOT_UNIQUE') {

          yield put(setError('Die eMail-Adresse ist bereits vergeben!'))

        }
        else if (error.code === 'VIOLATION::CUSTOMER_PASSWORD_NOT_CORRECT') {

          yield put(setError('Das Passwort ist nicht korrekt!'))

        }
        else {

          // TODO display error properly

          yield put(setError(error.detail))

        }

        break

      }

    }

  }

}

import { connect } from 'react-redux'

import { State } from '@common/types'

import AlertModal from './AlertModal.component'

import {
  alertModalDismissed,
} from '@modals/Alert/AlertModal.actions';

const mapStateToProps = ({
  alertModal: {
    visible,
    message,
    dismissLabel,
  }
}: State) => ({
  visible,
  message,
  dismissLabel,
})

const mapDispatchToProps = {
  alertModalDismissed,
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertModal)

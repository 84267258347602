import React from 'react'

import {
  StyleSheet,
  Pressable,
  Platform,
  Image,
} from 'react-native'

import{
  MON_500,
} from '@common/constants'

const Button = ({
  source,
  size = 24,
  style,
  color,
  colorPressed,
  ...props
}: any) => (

  <Pressable
    style={(state) => ([
      styles.default,
      style,
      state.pressed && styles.pressed, {backgroundColor: colorPressed},
      Platform.OS === 'web' && { cursor: 'pointer' } ,
      typeof style === 'function' ? style(state) : style,
    ])}
    children={(state) => (
      <Image
        style={{ width: size, height: size }}
        source={ typeof source === 'function' ? source(state) : source }
      />
    )}
    { ...props }
  />

)

const styles = StyleSheet.create({

  default: {
    width: 40,
    height: 40,
    borderRadius: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  textDefault: {
    fontSize: 15,
    marginLeft: 16,
    color: '#61646C',
    fontFamily: MON_500,
    textAlign: 'left',
    padding: 0,
  },

  pressed: {
    backgroundColor: '#FFF0E8',
  },

})

export default Button

export default {
  password: {
    presence: {
      allowEmpty: false,
      message: 'Bitte aktuelles Passwort eingeben',
    },
  },
  newPassword: {
    presence: {
      allowEmpty: false,
      message: 'Bitte neues Passwort eingeben',
    },
    length: {
      minimum: 8,
      message: 'Bitte mindesten 8 Zeichen eingeben',
    },
  },
  newPasswordConfirm: {
    presence: {
      allowEmpty: false,
      message: 'Bitte neues Passwort bestätigen',
    },
  },
}


import { takeLatest } from 'redux-saga/effects'

import {
  INIT,
  FIND_STORES,
  APPLY_STORE,
} from './StoresScreen.types'

import init from './sagas/init.saga'

import findStores from './sagas/findStores.saga'

import applyStore from './sagas/applyStore.saga'

export default function* () {

  yield takeLatest(INIT, init)

  yield takeLatest(FIND_STORES, findStores)

  yield takeLatest(APPLY_STORE, applyStore)

}

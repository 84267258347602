import React from 'react'

import {
  StyleSheet,
  View,
} from 'react-native'

import TextInput from '@components/TextInput.component'
import SecureInput from '@components/SecureInput.component'

import{
  TEXT_13,
  MON_500,
} from '@common/constants'

const LoginForm = ({
  formName,
  formValues,
  formErrors,
  setFormValue,
  submit,
}: any) => (

  <View
    style={styles.container}>


      <TextInput
        label='E-Mail'
        style={styles.label}
        error={formErrors.username}
        inputProps={{
          value: formValues.username,
          onChangeText: (username: string) => setFormValue(formName, 'username', username),
          onSubmitEditing: submit,
        }}/>
      <SecureInput
        label='Passwort'
        style={styles.label}
        containerStyle={styles.containerStyle}
        error={formErrors.password}
        inputProps={{
          value: formValues.password,
          onChangeText: (password: string) => setFormValue(formName, 'password', password),
          onSubmitEditing: submit,
        }}/>

  </View>

)

const styles = StyleSheet.create({

  containerStyle: {
    marginBottom: undefined,
  },

  container: {
    flex: 1,
    paddingHorizontal: 24,
  },

  label: {
    fontSize: TEXT_13,
    fontFamily: MON_500,
    color: '#61646C'
  },

  input: {
    marginBottom: 8,
  },

})

export default LoginForm

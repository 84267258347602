import React from 'react'

import {
  StyleSheet,
  View,
} from 'react-native'

import Text from '@components/Text.component'
import Button from '@components/DarkButton.component'
import BusyScreen from '@components/BusyScreen.component'

import LookList from './LookList.component'

import { ListType } from '@screens/Profile/ProfileScreen.types'

import {
  TEXT_MD,
  COLOR_GREY,
  TEXT_XL,
  UNNA_REG,
  COLOR_BLACK,
  LINE_HEIGHT_XL,
  MON_500
} from '@common/constants'
import { navigate } from "@navigation/root";

const LooksScreen = ({
  busy,
  looks,
  selectedLooks,
  toggleLook,
  applyLooks,
}: any) => {

  return busy ?

    <BusyScreen message='Looks werden geladen'/>

    :

    looks.length > 0 ?

      <View
        style={styles.container}>

        <Text
          style={styles.headline}>
          Toll, dass Du etwas gefunden hast!
        </Text>

        <Text
          style={styles.text}>
          Wähle Deine favorisierten Looks aus, die Du in unserem Store anprobieren möchtest.
        </Text>

        <LookList
          style={styles.lookList}
          listType={ListType.COLUMNS}
          items={looks}
          selection={selectedLooks}
          onPress={toggleLook}/>

        <Button
          onPress={applyLooks}
          label="Store in der Nähe auswählen"
        />

      </View>

      :

      <View
        style={styles.container}>

        <Text
          style={styles.headline}>
          Du hast noch keine Looks gespeichert!
        </Text>

        <Text
          style={styles.text}>
          Gerne kannst Du das jederzeit nachholen und in deinem Profil speichern. Dies hilft uns auf deinen Termin besser vorzubereiten.
        </Text>
        <View style={styles.btnContainer}>

          <Button
            onPress={()=>navigate('Stores')}
            label="Store in der Nähe auswählen"
          />

        </View>

        <Button
          onPress={()=>navigate('Configurator')}
          label="Zurück in den Konfigurator"
        />

      </View>

}

const styles = StyleSheet.create({

  container: {
    flex: 1,
    paddingBottom: 24,
    paddingHorizontal: 24,
  },

  headline: {
    fontSize: TEXT_XL,
    fontFamily: UNNA_REG,
    lineHeight: LINE_HEIGHT_XL,
    color: COLOR_BLACK,
    fontWeight: '500',
    marginBottom: 25,
  },

  text: {
    fontSize: TEXT_MD,
    lineHeight: 23,
    fontFamily: MON_500,
    color: COLOR_GREY,
    marginBottom: 25,
  },

  lookList: {
    marginTop: 24,
    marginBottom: 16,
    flex: 1,
  },

  btnContainer: {
    marginBottom: 25,
  }

})

export default LooksScreen

import React from 'react'

import {
  StyleSheet,
  Image,
  Text,
  View
} from 'react-native'


import {
  TEXT_MD, COLOR_BLACK, COLOR_GREY, MON_600, MON_500,
} from '@common/constants'


import Modal from '@components/Modal.component';
import TextInput from '@components/TextInput.component';
import DarkButton from '@components/DarkButton.component';

const PromptModal = ({
  visible,
  message,
  acceptLabel,
  dismissLabel,
  inputValue,
  promptModalAccepted,
  promptModalDismissed,
  setInputValue,
}: any) => (

  <Modal
    visible={visible}
    onDismiss={promptModalDismissed}
    content={<>

      <View style={styles.modalInner}>
        <Image
          style={styles.image}
          source={require('@assets/icons/icon-info.svg' /*'@assets/icons/dark/info.png'*/)}
        />
        <Text style={styles.headline} >
          {message}
        </Text>
      </View>

      <TextInput
        inputProps={{
          value: inputValue,
          onChangeText: (input: string) => setInputValue(input),
          onSubmitEditing: () => promptModalAccepted(inputValue),
        }}
        style={styles.input}
      />

    </>}

    footer={<>
        <View style={styles.btnContainer}>
        <DarkButton
          style={styles.acceptButton}
          onPress={() => promptModalAccepted(inputValue)}
          label={acceptLabel}
        />
        <View style={{marginLeft: 6}}>
          <DarkButton
            style={styles.dismissButton}
            onPress={promptModalDismissed}
            label={dismissLabel}
          />
        </View>
      </View>
    </>}

  />

)

const styles = StyleSheet.create({

  modalInner: {
    width: 250,
    marginHorizontal: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 40,
    flex: 1,
    zIndex: 2,
  },

  headline: {
    fontSize: TEXT_MD,
    fontFamily: MON_600,
    width: '100%',
    color: COLOR_BLACK,
    marginLeft: 8,
    marginBottom: 8,
    pointerEvents: false,
  },

  image: {
    position: 'absolute',
    left: -40,
    width: 25,
    height: 25,
    marginBottom: 8,
  },

  input: {
    backgroundColor: '#EFF1F3',
    width: 250,
    marginHorizontal: 'auto',
    fontFamily: MON_500,
    marginTop: 20,
  },

  btnContainer: {
    marginTop: 0,
    flexDirection: 'row',
    paddingBottom: 40,
    width: 250,
  },

  dismissButton: {
    backgroundColor: 'transparent',
    color: COLOR_GREY,
    fontFamily: MON_500,
    paddingHorizontal: 4,
    width: 'auto',
    height: 44,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  acceptButton: {
    width: 130,
    height: 44,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

})

export default PromptModal

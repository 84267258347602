
export interface FinishScreenState {

}

export const NAVIGATE_TO_CONFIGURATOR = 'NAVIGATE_TO_CONFIGURATOR'

export interface NavigateToConfiguratorAction {
  type: typeof NAVIGATE_TO_CONFIGURATOR,
}

export type FinishScreenActionType =
  NavigateToConfiguratorAction

import {
  LoginScreenActionType as ActionType,
  RESET,
  SET_ERROR,
  INIT,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  PASSWORD_RECOVERY,
} from './LoginScreen.types'

export const reset = (): ActionType => ({
  type: RESET,
})

export const setError = (error?: string): ActionType => ({
  type: SET_ERROR,
  error,
})

export const init = (route: any): ActionType => ({
  type: INIT,
  route,
})

export const login = (): ActionType => ({
  type: LOGIN,
})

export const loginSuccess = (customer: any): ActionType => ({
  type: LOGIN_SUCCESS,
  customer,
})

export const loginFailure = (error: any): ActionType => ({
  type: LOGIN_FAILURE,
  error,
})

export const passwordRecovery = (): ActionType => ({
  type: PASSWORD_RECOVERY,
})

import React from 'react'

import {
  StyleSheet,
  FlatList,
} from 'react-native'

import {
  ListType,
  Look,
} from '@screens/Profile/ProfileScreen.types'

import LookListItem from './LookListItem.component'

const LookList = ({
  style,
  listType,
  items,
  selection,
  onPress,
  onEdit,
  onDelete,
}: any) => (

  <FlatList
    style={[
      styles.container,
      style,
    ]}
    showsVerticalScrollIndicator={false}
    key={listType}
    numColumns={listType === ListType.ROWS ? undefined : 2}
    data = {items}
    renderItem={({ item: look, index }) => (

      <LookListItem
        look={look}
        index={index}
        active={selection && selection.find((item: Look) => item.id === look.id)}
        onPress={onPress && (() => onPress(look))}
        onEdit={onEdit && (() => onEdit(look))}
        onDelete={onDelete && (() => onDelete(look))}
      />

    )}
    keyExtractor={(item) => item.id}
  />

)

const styles = StyleSheet.create({

  container: {
    height: 0,
  },
})

export default LookList


import { combineReducers } from 'redux'

import global from './global.reducer'
import profile from './profile.reducer'

import loginScreen from '@screens/Login/LoginScreen.reducer'
import registerScreen from '@screens/Register/RegisterScreen.reducer'
import passwordRecoveryScreen from '@screens/PasswordRecovery/PasswordRecoveryScreen.reducer'

import configuratorScreen from '@screens/Configurator/ConfiguratorScreen.reducer'
import detailsScreen from '@screens/Details/DetailsScreen.reducer'
import profileScreen from '@screens/Profile/ProfileScreen.reducer'
import changeEmailScreen from '@screens/ChangeEmail/ChangeEmailScreen.reducer'
import changePasswordScreen from '@screens/ChangePassword/ChangePasswordScreen.reducer'
import changeProfileScreen from '@screens/ChangeProfile/ChangeProfileScreen.reducer'
import storesScreen from '@screens/Stores/StoresScreen.reducer'
import looksScreen from '@screens/Looks/LooksScreen.reducer'
import appointmentScreen from '@screens/Appointment/AppointmentScreen.reducer'
import finishScreen from '@screens/Finish/FinishScreen.reducer'
import confirmModal from '@modals/Confirm/ConfirmModal.reducer'
import alertModal from '@modals/Alert/AlertModal.reducer'
import promptModal from '@modals/Prompt/PromptModal.reducer'
import dateModal from '@modals/Date/DateModal.reducer'
import timeModal from '@modals/Time/TimeModal.reducer'

export default combineReducers({
  global,
  profile,
  loginScreen,
  registerScreen,
  passwordRecoveryScreen,
  configuratorScreen,
  detailsScreen,
  profileScreen,
  changeEmailScreen,
  changePasswordScreen,
  changeProfileScreen,
  storesScreen,
  looksScreen,
  appointmentScreen,
  finishScreen,
  confirmModal,
  alertModal,
  promptModal,
  dateModal,
  timeModal,
})

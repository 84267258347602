import React from 'react'

import { connect } from 'react-redux'

import { State } from '@common/types'

import {
  setFormValue,
} from '@common/actions'

import {
  reset,
  init,
  login,
  passwordRecovery,
} from './LoginScreen.actions'

import Stack from '@navigation/stack'
import options from './LoginScreen.options'
import LoginScreen from './LoginScreen.component'

import store from '@state/store'

const mapStateToProps = ({
  loginScreen: {
    busy,
    formValues,
    formErrors,
    error,
  },
}: State) => ({
  busy,
  formValues,
  formErrors,
  error,
})

const mapDispatchToProps = {
  setFormValue,
  login,
  passwordRecovery,
}

export default function() {

  return (
    <Stack.Screen
      name='Login'
      options={options}
      component={connect(mapStateToProps, mapDispatchToProps)(LoginScreen)}
      listeners={({ route }) => ({
        focus: () => store.dispatch(init(route)),
        beforeRemove: () => store.dispatch(reset()),
      })}
    />
  )

}

import { call, put, select } from 'redux-saga/effects'

import AsyncStorage from '@react-native-async-storage/async-storage'

import http from '../../../axios'

import {
  setError,
  loginSuccess,
  loginFailure,
} from '../LoginScreen.actions'

import constraints from '../LoginForm.constraints'

import validateForm from '../../../sagas/validateForm.saga'

import { reset } from '@navigation/root'

export default function* () {

  try {

    const { username, password } = yield select(state => state.loginScreen.formValues)

    yield put(setError())

    yield call(validateForm, 'login', constraints, {
      username,
      password,
    })

    const { data: loginData } = yield call(http.post, '/account/login', {
      username,
      password,
    })

    http.defaults.headers['sw-context-token'] = loginData.contextToken

    yield call(AsyncStorage.setItem, 'login.contextToken', loginData.contextToken)

    const { data: customerData } = yield call(http.post, '/account/customer', {
      associations: {
        defaultBillingAddress: {},
      },
    })

    yield put(loginSuccess(customerData))

    yield call(reset, {
      index: 0,
      routes: [{ name: 'Configurator' }],
    })

  }
  catch (err) {

    yield call(console.error, err)

    yield call(handleError, err)

  }

}

function* handleError(err) {

  yield call(console.error, err)

  yield put(loginFailure(err))

  if (err.response && err.response.data && err.response.data.errors) {

    for (const error of err.response.data.errors) {

      if (error.status === '401') {

        if (error.code === 'CHECKOUT__CUSTOMER_IS_INACTIVE') {

          yield put(setError('Account wurde noch nicht aktiviert!'))

        }
        else {

          yield put(setError('Benutzer oder Passwort sind nicht korrekt!'))

        }

        break

      }

    }

  }

}

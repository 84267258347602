export default {
  date: {
    presence: {
      allowEmpty: false,
      message: 'Bitte Datum auswählen',
    },
  },
  time: {
    presence: {
      allowEmpty: false,
      message: 'Bitte Uhrzeit auswählen',
    },

  },
}

import {
  COLOR_GROUP_ID,
} from "./constants"

import {
  Product,
  ProductVariant,
} from "@screens/Configurator/ConfiguratorScreen.types"

function format(v, n, x, s, c) {
  var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
      num = v.toFixed(Math.max(0, ~~n))

  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','))
}

export const formatCurrency = (v) => format(v, 2, 3, '.', ',') + ' €'

export const mapProduct = (product: any): Product => {

  const mappedProduct = {
    id        : product.id,
    name      : product.name,
    price     : product.calculatedPrice.unitPrice,
    images    : product.media ? mapProductThumbnails(product.media) : [],
    shape     : product.customFields && (product.customFields.custom_product_shapes_skirt || product.customFields.custom_product_shapes_neckline),
    enabled   : true,
    variants  : [],
  }

  return {
    ...mappedProduct,
    variants : product.children ? sortProductVariants(mapProductVariants(product.children, mappedProduct)) : [],
  }

}

export const sortProductVariants = (variants: any[]) => variants.sort((a: ProductVariant, b: ProductVariant) => a.color.position < b.color.position ? -1 : (a.color.position > b.color.position ? 1 : 0))

export const mapProductVariants = (variants: any[], product: Product): ProductVariant[] => variants.map(variant => mapProductVariant(variant, product))

export const mapProductVariant = (variant: any, product: Product): ProductVariant => ({
  id      : variant.id,
  product : product,
  price   : variant.calculatedPrice.unitPrice,
  color   : findColorOption (variant.options, COLOR_GROUP_ID),
  images  : variant.media ? mapProductThumbnails(variant.media) : [],
  shape   : product.shape,
})

export const findColorOption = (options: any[], groupId: string) => options.find(item => item.groupId === groupId)

export const mapProductThumbnails = (media: any) => media.map(media => media.media.thumbnails.map(item => item.url))

export function mapValidationErrors(errors) {

  const res = {}

  for (const field in errors) {

    res[field] = errors[field][0]

  }

  return res

}

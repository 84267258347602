import React from 'react'

import {
  StyleSheet,
  Platform,
  Pressable,
  PressableStateCallbackType,
  View,
  Image,
} from 'react-native'

import Text from '@components/Text.component'

import {
  TEXT_SM,
  COLOR_GREY_LIGHTER,
  MON_600,
} from '@common/constants'

import { formatCurrency } from '@common/utils'

import ColorSwitch from './ColorSwitch.component'

const BrowserItem = ({
  active,
  product,
  selectedVariant,
  showPrice,
  onPress,
}: any) => (

  <Pressable
    onPress={() => onPress(product.variants[0], true)}
    style={({ pressed }: PressableStateCallbackType) => ([
      styles.container,
      pressed && styles.containerPressed,
      active && styles.containerActive,
      !product.enabled && styles.containerDisabled,
      Platform.OS === 'web' && { cursor: 'pointer' },
    ])}>

    {active &&
    <View style={styles.activeIconContainer}>
      <Image
        style={styles.activeIcon}
        source={require('@assets/icons/icon-check-white.svg' /*'@assets/icons/white/check.png'*/)}/>
    </View>
    }

    <View	style={styles.image}>
      <Image
        style={{
          width: '100%',
          height: '100%',
        }}
        source={{ uri: (selectedVariant && selectedVariant.images[2] && selectedVariant.images[2][0]) || (product.images[0] && product.images[0][0]) }} />
    </View>

    <View style={styles.detail}>

      <View
        style={styles.label}>
        <Text
          style={styles.labelText}>
          {product.name}
        </Text>
      </View>

      <ColorSwitch
        style={styles.colors}
        horizontal={true}
        product={product}
        selectedVariant={selectedVariant}
        onPress={onPress}/>

      <Text
        style={styles.price}>
        {showPrice &&
          <Text>
            {formatCurrency(selectedVariant ? selectedVariant.price : (product.variants[0] ? product.variants[0].price : 0))}
          </Text>
        }
      </Text>

    </View>

  </Pressable>

)

const styles = StyleSheet.create({

  container: {
    borderRadius: 16,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 8,
    paddingVertical: 4,
  },

  containerPressed: {
    backgroundColor: COLOR_GREY_LIGHTER,
    borderRadius: 0,
  },

  containerActive: {
    borderRadius: 0,
  },

  containerDisabled: {
    backgroundColor: '#EEEEEE'
  },

  label: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  labelText: {
    fontSize: TEXT_SM,
    fontFamily: MON_600,
    color: '#191919'
  },

  price: {
    fontSize: TEXT_SM,
    fontFamily: MON_600,
    fontWeight: '600',
    color: '#61646C',
    height: 14,
  },

  image: {
    width: 76,
    height: 76,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ba9688',
    borderRadius: 17,
    overflow: 'hidden',
    marginLeft: 8,
  },

  activeIconContainer: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    backgroundColor: '#000',
    width: 18,
    height: 18,
    top: 23,
    zIndex: 1,
  },

  activeIcon: {
    width: 10,
    height: 10,
  },

  detail: {
    flex: 1,
    marginVertical: 8,
    marginLeft: 12,
  },

  colors: {
    marginTop: 8,
    marginBottom: 15,
  },

})

export default BrowserItem

import React from 'react'

import {
  StyleSheet,
  View,
  Text,
} from 'react-native'

import {
  COLOR_GREY_LIGHT,
  TEXT_MD,
  COLOR_BLACK,COLOR_GREY,
} from "../../common/constants";

const TimeSelect = ({
  style,
  times,
  selectedTime,
  setTime,
}: any) => (

  <View
    style={[
      styles.container,
      style,
    ]}>

    <View style={styles.greyLine}></View>

    <Text style={styles.headline}>
        Wähle eine Uhrzeit
    </Text>

    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 8,
        marginHorizontal: 8,
      }}>

      {times.map((time: string) => (

        <View
          key={time}
          style={styles.item}
          >
          <Text
            onPress={() => setTime(time)}
            style={[
              styles.text,
              time === selectedTime && styles.textSelected,
            ]}>
            {time}
          </Text>
        </View>

      ))}

    </View>

  </View>

)

const styles = StyleSheet.create({

  container: {
    borderRadius: 24,
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    paddingHorizontal: 16,
    marginTop: 35,
    marginBottom: 0,
  },

  greyLine: {
    borderRadius: 2,
    height: 3,
    width: 42,
    marginHorizontal: 'auto',
    top: -9,
    backgroundColor: COLOR_GREY_LIGHT,
    zIndex: 2,
  },

  headline: {
    width: '100%',
    textAlign: 'center',
    fontSize: TEXT_MD,
    lineHeight: 23,
    color: COLOR_BLACK,
    marginBottom: 12,
    fontWeight: '500'
  },

  item: {
    width: '33.33%',
    padding: 8,
  },

  text: {
    fontWeight: '500',
    textAlign: 'center',
    padding: 8,
    borderColor: COLOR_GREY,
    fontSize: 15,
    lineHeight: 12,
    color: COLOR_BLACK,
    borderRadius: 13,
    borderWidth: 1,
  },

  textSelected: {
    fontWeight: '700',
    color: '#fff',
    borderColor: '#333538',
    backgroundColor: '#333538',
  },

})

export default TimeSelect

import {
  CommonActionType,
  INIT,
  INIT_SUCCESS,
  INIT_FAILURE,
  SET_FORM_VALUE,
  SET_FORM_ERRORS,
  MAKE_APPOINTMENT,
} from './types'

export const init = () => ({
  type: INIT,
})

export const initSuccess = (
  apiUrl: string,
  apiKey: string,
  storefrontUrl: string,
  customer?: any,
): CommonActionType => ({
  type: INIT_SUCCESS,
  apiUrl,
  apiKey,
  storefrontUrl,
  customer,
})

export const initFailure = (error: any) => ({
  type: INIT_FAILURE,
  error,
})

export const setFormValue = (form: string, key: string, val: any): CommonActionType => ({
  type: SET_FORM_VALUE,
  form,
  key,
  val,
})

export const setFormErrors = (form: string, errors: any): CommonActionType => ({
  type: SET_FORM_ERRORS,
  form,
  errors,
})

export const makeAppointment = (): CommonActionType => ({
  type: MAKE_APPOINTMENT,
})

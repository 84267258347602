import React from 'react'

import { Platform } from 'react-native'

import IconButton from '@components/IconButton.component'

export default ({ route, navigation }: any) => ({
  title: 'Dein Profil',
  headerRight: () => (

    <IconButton
      onPress={() => navigation.navigate('ProfileMenu')}
      style={{
        marginRight: Platform.OS === 'web' ? 8 : 0,
      }}
      source={require('@assets/icons/icon-menu.svg' /*'@assets/icons/dark/menu.png'*/)}/>

  ),
})

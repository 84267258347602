import { call, put } from 'redux-saga/effects'

import AsyncStorage from '@react-native-async-storage/async-storage'

import http from '../../../axios'

import {
  logoutSuccess,
  logoutFailure,
} from '../ProfileScreen.actions'

import { navigate } from '@navigation/root'

export default function* () {

  try {

    const { data } = yield call(http.post, '/account/logout')

    http.defaults.headers['sw-context-token'] = data.contextToken

    yield call(AsyncStorage.setItem, 'login.contextToken', data.contextToken)

    yield put(logoutSuccess())

    yield call(navigate, 'Configurator')

  }
  catch (err) {

    yield call(console.error, err)

    yield put(logoutFailure(err))

  }

}

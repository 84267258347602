import { call, put, select } from 'redux-saga/effects'

import http from '../../../axios'

import {
  setFormErrors,
} from '@common/actions'

import {
  setError,
  registerSuccess,
  registerFailure,
} from '../RegisterScreen.actions'

import constraints from '../RegisterForm.constraints'

import validateForm from '../../../sagas/validateForm.saga'

import { goBack } from '@navigation/root'

import { showAlertModal } from '@modals/Alert/AlertModal.actions'

export default function* () {

  try {

    const { storefrontUrl } = yield select(state => state.global)

    const {
      salutationId,
      firstName,
      lastName,
      email,
      password,
      passwordConfirm,
      street,
      zipcode,
      city,
      countryId,
      phoneNumber,
      dressSize,
      weddingDate,
      dataProtectionChecked,
    } = yield select(state => state.registerScreen.formValues)

    yield put(setError())

    yield call(validateFormEx, {
      firstName,
      lastName,
      email,
      password,
      passwordConfirm,
      street,
      zipcode,
      city,
      countryId,
      phoneNumber,
      dressSize,
      weddingDate,
      dataProtectionChecked,
    })

    yield call(http.post, '/account/register', {
      salutationId,
      firstName,
      lastName,
      email,
      password,
      storefrontUrl,
      billingAddress: {
        street,
        zipcode,
        city,
        countryId,
        phoneNumber,
      },
      customFields: {
        customer_info_dress_size: dressSize,
        customer_info_wedding_date: weddingDate,
      },
    })

    yield put(registerSuccess())

    yield call(goBack)

    yield put(showAlertModal('Bitte prüfe Deinen Posteingang!', 'Ok'))

  }
  catch (err) {

    yield call(handleError, err)

  }

}

function* validateFormEx(form) {

  yield call(validateForm, 'register', constraints, form)

  const { password, passwordConfirm, dataProtectionChecked } = form;

  if (password !== passwordConfirm) {

    yield put(setFormErrors('register', {
      passwordConfirm: 'Die Passwörter stimmen nicht überein',
    }))

    throw Error("Validation failed for 'register' form")

  }

  if (!dataProtectionChecked) {

    yield put(setFormErrors('register', {
      dataProtectionChecked: 'Sie müssen die Datenschutzerklärung bestätigen, um sich anzumelden',
    }))

    throw Error("Validation failed for 'register' form")
  }

}

function* handleError(err) {

  yield call(console.error, err)

  yield put(registerFailure(err))

  if (err.response && err.response.data && err.response.data.errors) {

    for (const error of err.response.data.errors) {

      if (error.status === '400') {

        if (error.code === 'VIOLATION::CUSTOMER_EMAIL_NOT_UNIQUE') {

          yield put(setError('Die E-Mail-Adresse ist bereits vergeben!'))

        }
        else {

          // TODO display error properly

          yield put(setError(error.detail))

        }

        break

      }

    }

  }

}

import React, { useEffect } from 'react'

import {
  FlatList,
} from 'react-native'

const ResetFlatList = (props) => {

  let listRef

  useEffect(() => {
      const hasData = props.data && props.data.length
      listRef && hasData && listRef.scrollToIndex({ index: 0, animated: false })
  }, [
    props.selectedLayer,
  ])

  return (
    <FlatList
      ref= {ref => listRef = ref}
      { ...props } />
  )

}

export default ResetFlatList


import { takeLatest } from 'redux-saga/effects'

import {
  NAVIGATE_TO_CONFIGURATOR,
} from './FinishScreen.types'

import navigateToConfigurator from './sagas/navigateToConfigurator.saga'

export default function* () {

  yield takeLatest(NAVIGATE_TO_CONFIGURATOR, navigateToConfigurator)

}

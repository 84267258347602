import React from 'react'

import {
  Pressable,
  StyleSheet,
  View,
} from 'react-native'

import { Picker as NativePicker } from '@react-native-picker/picker'

import Text from '@components/Text.component'
import Picker from '@components/Picker.component'
import TextInput from '@components/TextInput.component'
import SecureInput from '@components/SecureInput.component'
import Checkbox from '@components/Checkbox.component'

import {
  MON_500,
  TEXT_13,
} from '@common/constants'

const RegisterForm = ({
  formName,
  formValues,
  formErrors,
  salutations,
  countries,
  setFormValue,
  submit,
  pickDate,
  navigation,
}: any) => (

  <View
    style={styles.container}>

    <Picker
      label='Anrede*'
      selectedValue={formValues.salutationId}
      onValueChange={(itemValue) =>
        setFormValue(formName, 'salutationId', itemValue)
      }>
      {salutations.map((item: any) => <NativePicker.Item key={item.id} label={item.displayName} value={item.id} />)}
    </Picker>

    <TextInput
      label='Vorname*'
      error={formErrors.firstName}
      inputProps={{
        value: formValues.firstName,
        onChangeText: (firstName: string) => setFormValue(formName, 'firstName', firstName),
        onSubmitEditing: submit,
      }}/>

    <TextInput
      label='Nachname*'
      error={formErrors.lastName}
      inputProps={{
        value: formValues.lastName,
        onChangeText: (lastName: string) => setFormValue(formName, 'lastName', lastName),
        onSubmitEditing: submit,
      }}/>

    <TextInput
      label='E-Mail*'
      error={formErrors.email}
      inputProps={{
        value: formValues.email,
        onChangeText: (email: string) => setFormValue(formName, 'email', email),
        onSubmitEditing: submit,
      }}/>

    <TextInput
      label='Straße*'
      error={formErrors.street}
      inputProps={{
        value: formValues.street,
        onChangeText: (street: string) => setFormValue(formName, 'street', street),
        onSubmitEditing: submit,
      }}/>

    <TextInput
      label='PLZ*'
      error={formErrors.zipcode}
      inputProps={{
        value: formValues.zipcode,
        onChangeText: (zipcode: string) => setFormValue(formName, 'zipcode', zipcode),
        onSubmitEditing: submit,
      }}/>

    <TextInput
      label='Ort*'
      error={formErrors.city}
      inputProps={{
        value: formValues.city,
        onChangeText: (city: string) => setFormValue(formName, 'city', city),
        onSubmitEditing: submit,
      }}/>

    <Picker
      label='Land*'
      selectedValue={formValues.countryId}
      onValueChange={(itemValue) =>
        setFormValue(formName, 'countryId', itemValue)
      }>
      {countries.map((item: any) => <NativePicker.Item key={item.id} label={item.name} value={item.id} />)}
    </Picker>

    <TextInput
      label='Telefonnummer'
      error={formErrors.phoneNumber}
      inputProps={{
        value: formValues.phoneNumber,
        onChangeText: (phoneNumber: string) => setFormValue(formName, 'phoneNumber', phoneNumber),
        onSubmitEditing: submit,
      }}/>

    <SecureInput
      label='Passwort*'
      error={formErrors.password}
      inputProps={{
        value: formValues.password,
        onChangeText: (password: string) => setFormValue(formName, 'password', password),
        onSubmitEditing: submit,
      }}/>

    <SecureInput
      label='Passwort bestätigen*'
      error={formErrors.passwordConfirm}
      inputProps={{
        value: formValues.passwordConfirm,
        onChangeText: (passwordConfirm: string) => setFormValue(formName, 'passwordConfirm', passwordConfirm),
        onSubmitEditing: submit,
      }}/>

    <TextInput
      label='Kleidergröße*'
      error={formErrors.dressSize}
      inputProps={{
        value: formValues.dressSize,
        onChangeText: (dressSize: string) => setFormValue(formName, 'dressSize', dressSize),
        onSubmitEditing: submit,
      }}/>

    <Pressable
      onPress={pickDate}>
      <TextInput
        label='Tag der Hochzeit'
        containerStyle={{
          marginBottom: undefined,
        }}
        error={formErrors.weddingDate}
        inputProps={{
          value: formValues.weddingDate,
          editable: false,
        }}
        buttonProps={{
          onPress: pickDate,
          source: require('@assets/icons/icon-cal.svg' /*'@assets/icons/dark/cal.png'*/),
        }}/>
    </Pressable>

    <Text style={styles.pflicht}>
      Falls Du noch keinen Hochzeitstermin festgelegt hast, lass dieses Feld frei.
    </Text>

    <View
      style={styles.checkboxContainer}>

      <Checkbox
        error={formErrors.dataProtectionChecked}
        style={styles.checkbox}
        onChange={(checked: boolean) => setFormValue(formName, 'dataProtectionChecked', checked)}
        text1={'Hiermit stimme ich der '}
        linkText1={'Datenschutzerklärung'}
        text2={' und den '}
        linkText2={'AGB'}
        text3={' zu!*'}
        link1={() => navigation.navigate('PrivacyNotice')}
        link2={() => navigation.navigate('Terms')}/>

    </View>

    <Text style={styles.pflicht}>
      *Pflichtfelder
    </Text>


  </View>

)

const styles = StyleSheet.create({

  container: {
    flex: 1,
    paddingHorizontal: 24,
  },

  checkboxContainer: {
    flexDirection: "row",
  },

  checkbox: {
    alignSelf: "center",
    marginTop: 25,
  },

  label: {
    fontSize: TEXT_13,
    fontFamily: MON_500,
    color: 'rgb(97, 100, 108)',
  },

  pflicht: {
    fontSize: TEXT_13,
    fontFamily: MON_500,
    color: 'rgb(97, 100, 108)',
    marginTop: 16,
  },

})

export default RegisterForm

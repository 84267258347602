
export interface Store {
  id: string,
  name: string,
  street: string,
  zipcode: string,
  city: string,
  distance: number,
}

export interface StoresScreenFormValues {
  location: string,
}

export interface StoresScreenFormErrors {
}

export interface StoresScreenState {
  busy: boolean,
  formValues: StoresScreenFormValues,
  formErrors: StoresScreenFormErrors,
  stores: Store[],
  selectedStore: Store|null,
}

export const INIT = 'StoresScreen.init'
export const INIT_SUCCESS = 'StoresScreen.init.success'
export const INIT_FAILURE = 'StoresScreen.init.failure'

export const RESET = 'StoresScreen.reset'

export const FIND_STORES = 'StoresScreen.findStores'
export const FIND_STORES_SUCCESS = 'StoresScreen.findStores.success'
export const FIND_STORES_FAILURE = 'StoresScreen.findStores.failure'

export const SET_STORE = 'StoresScreen.setStore'

export const APPLY_STORE = 'StoresScreen.applyStore'

export interface InitAction {
  type: typeof INIT,
}

export interface InitSuccessAction {
  type: typeof INIT_SUCCESS,
  stores: Store[],
}

export interface InitFailureAction {
  type: typeof INIT_FAILURE,
  error: any,
}

export interface ResetAction {
  type: typeof RESET,
}

export interface FetchStoresAction {
  type: typeof FIND_STORES,
}

export interface FetchStoresSuccessAction {
  type: typeof FIND_STORES_SUCCESS,
  stores: Store[],
}

export interface FetchStoresFailureAction {
  type: typeof FIND_STORES_FAILURE,
  error?: string,
}

export interface SetStoreAction {
  type: typeof SET_STORE,
  store: Store,
}

export interface ApplyStoreAction {
  type: typeof APPLY_STORE,
}

export type StoresScreenActionType =
  InitAction |
  InitSuccessAction |
  InitFailureAction |
  ResetAction |
  FetchStoresAction |
  FetchStoresSuccessAction |
  FetchStoresFailureAction |
  SetStoreAction |
  ApplyStoreAction

import React from 'react'

import {
  StyleSheet,
  View,
  Image,
  PressableStateCallbackType,
  StyleProp,
  ViewStyle,
  Pressable,
} from 'react-native'

import Text from '@components/Text.component'
import Button from '@components/Button.component'
import IconButton from '@components/IconButton.component'

import {
  BOTTOM_BAR_HEIGHT, COLOR_GREY_LIGHTER, MON_600, MON_700
} from '@common/constants'

import { formatCurrency } from '@common/utils'

const BottomBar = ({
  look,
  price,
  showPrice,
  navigateToProfile,
  storeLook,
}: any) => (

  <View
    style={styles.container}>

    <Button
      onPress={navigateToProfile}
      style={({ pressed }: PressableStateCallbackType): StyleProp<ViewStyle> => ([
        pressed && styles.containerPressed,
        styles.btn
      ])}
      children={({ pressed }: PressableStateCallbackType) =>
        <>
          <Image
            style={{ width: 20, height: 20, marginRight: 8 }}
            source={pressed
              ? require('@assets/icons/icon-info.svg' /*'@assets/icons/dark/info.png'*/)
              : require('@assets/icons/icon-info-white.svg' /*'@assets/icons/white/info.png'*/)}/>
          <Text
            style={[
              styles.buttonText,
              pressed && styles.buttonTextPressed
            ]}>
            {look ? look.name : 'Dein Look'}
          </Text>
        </>
      } />

    <View
      style={styles.priceWrapper}>

      {showPrice?
        <View>

            <Text
                style={styles.price}>
              {formatCurrency(price)}
            </Text>

            <Text
                style={styles.tax}>
                inkl. 19% MwSt.
            </Text>

        </View>
        :
        <Pressable
        onPress={navigateToProfile}>

          <Text
            style={styles.price}>
            Preis
          </Text>

          <Text
            style={styles.tax}>
            nach Anmeldung
          </Text>

        </Pressable>
      }

    </View>

    <IconButton
      onPress={storeLook}
      source={!look ?
        require('@assets/icons/icon-heart.svg' /*'@assets/icons/dark/heart.png'*/) :
        require('@assets/icons/icon-heart-filled.svg' /*'@assets/icons/dark/heart_filled.png'*/)}/>

  </View>

)

const styles = StyleSheet.create({

  container: {
    height: BOTTOM_BAR_HEIGHT,
    paddingVertical: 24,
    paddingHorizontal: 32,
    borderTopLeftRadius: 37,
    borderTopRightRadius: 37,
    backgroundColor: '#FFFFFF',
    flexDirection: 'row',
    alignItems: 'center',
  },

  containerPressed: {
    backgroundColor: COLOR_GREY_LIGHTER,
  },

  buttonContainer: {
    flex: 1,
    marginHorizontal: 8,
  },

  btn: {
    borderRadius: 17,
    minWidth: 109,
    height: 44
  },

  button: {
    flex: 1,
    height: 54,
  },

  buttonText: {
    color: '#FFF0E8',
    fontFamily: MON_700,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 75,
    whiteSpace: 'nowrap'
  },

  buttonTextPressed: {
    color: '#000000',
  },

  priceWrapper: {
    flex: 1,
    marginHorizontal: 8,
    alignItems: 'center',
  },

  price: {
    fontSize: 15,
    lineHeight: 23,
    fontFamily: MON_700,
  },

  tax: {
    fontSize: 10,
    fontFamily: MON_600,
    color: '#61646C',
  },

})

export default BottomBar

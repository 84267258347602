import React from 'react'

import {
  StyleSheet,
  ScrollView,
} from 'react-native'

import Text from '@components/Text.component'

const TermsScreen = () => (

  <ScrollView
    style={styles.container}>

    <Text>

      <Text style={styles.headline}>
        Allgemeine Geschäftsbedingungen und Verbraucherinformationen
      </Text>

      <Text style={styles.strong}>§ 1 Grundlegende Bestimmungen</Text>

      (1)  Die nachstehenden Geschäftsbedingungen gelten für alle Verträge zwischen Helena Moos, Frauenberger Straße 119, 53879 Euskirchen, Deutschland - nachfolgend Anbieter genannt - und dem Kunden, welche über die Internetseite http://www.lemoos.de/ des Anbieters geschlossen werden. Soweit nicht anders vereinbart, wird der Einbeziehung von eigenen Bedingungen des Kunden widersprochen.
      (2)  Verbraucher im Sinne der nachstehenden Regelungen ist jede natürliche Person, die ein Rechtsgeschäft zu einem Zwecke abschließt, der weder ihrer gewerblichen noch selbständigen beruflichen Tätigkeit zugerechnet werden kann. Unternehmer ist jede natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer selbständigen beruflichen oder gewerblichen Tätigkeit handelt.
      (3)  Vertragssprache ist deutsch. Der vollständige Vertragstext wird beim Anbieter nicht gespeichert. Vor Absenden der Bestellung über das Online - Warenkorbsystem können die Vertragsdaten über die Druckfunktion des Browsers ausgedruckt oder elektronisch gesichert werden. Nach Zugang der Bestellung beim Anbieter werden die Bestelldaten, die gesetzlich vorgeschriebenen Informationen bei Fernabsatzverträgen und die Allgemeinen Geschäftsbedingungen dem Kunden nochmals per E-Mail übersandt.
      Bei Angebotsanfragen, die an den Anbieter übermittelt werden, erhält der Kunde alle Vertragsdaten im Rahmen eines verbindlichen Angebotes per Email übersandt, welche der Kunde ausdrucken oder elektronisch sichern kann.

      <Text style={styles.strong}> § 2 Vertragsgegenstand</Text>

      Gegenstand des Vertrages ist der Verkauf von Waren. Die Einzelheiten, insbesondere die wesentlichen Merkmale der Ware finden sich in der Artikelbeschreibung und den ergänzenden Angaben auf der Internetseite des Anbieters.

      <Text style={styles.strong}>§ 3 Preise, Versandkosten</Text>

      (1)  Die in den jeweiligen Angeboten angeführten Preise sowie die Versandkosten stellen Endpreise dar. Sie beinhalten alle Preisbestandteile einschließlich aller anfallenden Steuern.
      (2)  Die anfallenden Versandkosten sind nicht im Kaufpreis enthalten. Sie sind über die Seite „Zahlung und Versand“ aufrufbar, werden im Laufe des Bestellvorganges gesondert ausgewiesen und sind vom Kunden zusätzlich zu tragen, soweit nicht die versandkostenfreie Lieferung zugesagt ist.
      (3)  Der Kunde erhält eine Rechnung mit ausgewiesener Mehrwertsteuer.

      <Text style={styles.strong}>§ 4  Zahlungs- und Versandbedingungen</Text>

      (1)  Die Bedingungen für Zahlung und Versand finden sich unter der gleichnamigen Schaltfläche in der Navigation.
      (2)  Soweit bei den einzelnen Zahlungsarten nicht anders angegeben, sind die Zahlungsansprüche aus dem geschlossenen Vertrag sofort zur Zahlung fällig.

      <Text style={styles.strong}>§ 5 Zurückbehaltungsrecht</Text>

      Ein Zurückbehaltungsrecht kann der Kunde nur ausüben, soweit es sich um Forderungen aus demselben Vertragsverhältnis handelt.

      <Text style={styles.strong}>§ 6 Gewährleistung</Text>

      Es gelten die gesetzlichen Vorschriften.

      <Text style={styles.strong}>§ 7 Haftung </Text>

      (1)  Der Anbieter haftet jeweils uneingeschränkt für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit, in allen Fällen des Vorsatzes und grober Fahrlässigkeit, bei arglistigem Verschweigen eines Mangels, bei Übernahme der Garantie für die Beschaffenheit des Kaufgegenstandes, bei Schäden nach dem Produkthaftungsgesetz und in allen anderen gesetzlich geregelten Fällen.
      (2)  Sofern wesentliche Vertragspflichten betroffen sind, ist die Haftung des Anbieters bei leichter Fahrlässigkeit auf den vertragstypischen, vorhersehbaren Schaden beschränkt. Wesentliche Vertragspflichten sind wesentliche Pflichten, die sich aus der Natur des Vertrages ergeben und deren Verletzung die Erreichung des Vertragszweckes gefährden würde sowie Pflichten, die der Vertrag dem Anbieter nach seinem Inhalt zur Erreichung des Vertragszwecks auferlegt, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst möglich machen und auf deren Einhaltung der Kunde regelmäßig vertrauen darf.
      (3)  Bei der Verletzung unwesentlicher Vertragspflichten ist die Haftung bei leicht fahrlässigen Pflichtverletzungen ausgeschlossen.
      (4)  Die Datenkommunikation über das Internet kann nach dem derzeitigen Stand der Technik nicht fehlerfrei und/oder jederzeit verfügbar gewährleistet werden. Der Anbieter haftet insoweit weder für die ständige noch ununterbrochene Verfügbarkeit der Webseite und der dort angebotenen Dienstleistung.

      <Text style={styles.strong}>§ 8 Rechtswahl, Erfüllungsort, Gerichtsstand </Text>

      (1)  Es gilt deutsches Recht. Bei Verbrauchern gilt diese Rechtswahl nur, soweit hierdurch der durch zwingende Bestimmungen des Rechts des Staates des gewöhnlichen Aufenthaltes des Verbrauchers gewährte Schutz nicht entzogen wird (Günstigkeitsprinzip).
      (2)  Erfüllungsort für alle Leistungen aus den mit dem Anbieter bestehenden Geschäftsbeziehungen sowie Gerichtsstand  ist Sitz des Anbieters, soweit der Kunde nicht Verbraucher, sondern Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen ist. Dasselbe gilt, wenn der Kunde keinen allgemeinen Gerichtsstand in Deutschland oder der EU hat oder der Wohnsitz oder gewöhnliche Aufenthalt im Zeitpunkt der Klageerhebung nicht bekannt ist. Die Befugnis, auch das Gericht an einem anderen gesetzlichen Gerichtsstand anzurufen, bleibt hiervon unberührt.
      (3)  Die Bestimmungen des UN-Kaufrechts finden ausdrücklich keine Anwendung.

      Diese AGB wurden von den auf IT-Recht spezialisierten Juristen des Händlerbundes erstellt und werden permanent auf Rechtskonformität geprüft. Die Händlerbund Management AG garantiert für die Rechtssicherheit der Texte und haftet im Falle von Abmahnungen. Nähere Informationen dazu finden Sie unter: http://www.haendlerbund.de/agb-service.

    </Text>

  </ScrollView>

)

export default TermsScreen

const styles = StyleSheet.create({

  container: {
    flex: 1,
    marginBottom: 24,
    paddingHorizontal: 24,
    fontSize: 13,
  },

  headline: {
    fontSize: 15,
    fontWeight: '700',
    marginBottom: 8,
  },

  strong: {
    display: 'flex',
    marginVertical: 8,
    fontWeight: '700',
    width: '100%',
  },

})

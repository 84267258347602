import React from 'react'

import {
  StyleSheet,
  Pressable,
  Image,
  View,
} from 'react-native'
import Text from "@components/Text.component";
import {COLOR_GREY, TEXT_SM, MON_500, TEXT_MD, TEXT_13} from "@common/constants";

const Checkbox = ({
  onChange,
  error,
  text1,
  text2,
  text3,
  linkText1,
  linkText2,
  link1,
  link2,
  style
  }: any) => {

  const [checked, setChecked] = React.useState(false)

  return (
    <View style={style}>
      <Pressable
        style={styles.container}
        onPress={() => {
          const v = !checked
          setChecked(v)
          onChange(v)
        }}>
        <View
          style={styles.checkbox}>
          {
            checked &&
            <Image
              style={styles.icon}
              source={require('@assets/icons/icon-check-white.svg' /*'@assets/icons/white/check.png'*/)}
            />
          }
        </View>

        <Text style={styles.label}>
          {text1}
          <Text
            onPress={link1}
            style={styles.labelLink}>
            {linkText1}
          </Text>
            {text2}
          <Text
            onPress={link2}
            style={styles.labelLink}>
            {linkText2}
          </Text>
            {text3}
        </Text>
      </Pressable>

      {!!error &&
        <Text
            style={[
              styles.error,
            ]}>
          {error}
        </Text>
      }
    </View>
  )
}

const styles = StyleSheet.create({

  checkbox: {
    marginBottom: 25,
    height: 18,
    width: 18,
    backgroundColor: 'black',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    flexDirection: 'row'
  },

  icon: {
    width: 10,
    height: 10,
  },

  error: {
    paddingLeft: 10,
    fontFamily: MON_500,
    color: COLOR_GREY,
    fontSize: TEXT_SM,
    marginTop: 2,
  },

  labelLink: {
    textDecorationLine: 'underline',
    fontSize: TEXT_13,
    fontFamily: MON_500,
    color: COLOR_GREY,
  },
  label: {
    fontSize: TEXT_13,
    fontFamily: MON_500,
    color: COLOR_GREY,
    marginLeft: 6,
    marginTop: '-4px',
    flex: 1,
    width: 320,
    flexWrap: 'wrap'
  },

})

export default Checkbox

import React from 'react'

import { Platform } from 'react-native'

import Stack from '@navigation/stack'

import IconButton from '@components/IconButton.component'

import loginScreen from '@screens/Login/LoginScreen.container'
import registerScreen from '@screens/Register/RegisterScreen.container'
import passwordRecoveryScreen from '@screens/PasswordRecovery/PasswordRecoveryScreen.container'
import configuratorScreen from '@screens/Configurator/ConfiguratorScreen.container'
import detailsScreen from '@screens/Details/DetailsScreen.container'
import profileScreen from '@screens/Profile/ProfileScreen.container'
import profileMenuScreen from '@screens/Profile/Menu/MenuScreen.container'
import changeEmailScreen from '@screens/ChangeEmail/ChangeEmailScreen.container'
import changePasswordScreen from '@screens/ChangePassword/ChangePasswordScreen.container'
import changeProfileScreen from '@screens/ChangeProfile/ChangeProfileScreen.container'
import legalNoticeScreen from '@screens/Profile/Info/LegalNoticeScreen.container'
import privacyNoticeScreen from '@screens/Profile/Info/PrivacyNoticeScreen.container'
import termsScreen from '@screens/Profile/Info/TermsScreen.container'
import storesScreen from '@screens/Stores/StoresScreen.container'
import looksScreen from '@screens/Looks/LooksScreen.container'
import appointmentScreen from '@screens/Appointment/AppointmentScreen.container'
import finishScreen from '@screens/Finish/FinishScreen.container'

import{
  TEXT_MD,
  MON_500,
} from '@common/constants'

import store from '@state/store'

import { alertModalDismissed } from '@modals/Alert/AlertModal.actions'
import { promptModalDismissed } from '@modals/Prompt/PromptModal.actions'
import { confirmModalDismissed } from '@modals/Confirm/ConfirmModal.actions'
import { dateModalDismissed } from '@modals/Date/DateModal.actions'
import { timeModalDismissed } from '@modals/Time/TimeModal.actions'

const MainNavigator = () => (

  <Stack.Navigator
    initialRouteName='Configurator'
    screenListeners={{
      beforeRemove: () => {
        store.dispatch(alertModalDismissed())
        store.dispatch(promptModalDismissed())
        store.dispatch(confirmModalDismissed())
        store.dispatch(dateModalDismissed())
        store.dispatch(timeModalDismissed())
      }
    }}
    screenOptions={({ navigation }) => ({
      headerStyle: {
        height: 72,
      },
      headerTitleStyle: {
        fontFamily: MON_500,
        fontSize: TEXT_MD,
        color: '#333538',
      },
      headerTitleAlign: 'center',
      headerShadowVisible: false,
      headerLeft: () => (

        navigation.canGoBack() && <IconButton
          onPress={() => navigation.goBack()}
          style={{
            marginLeft: Platform.OS === 'web' ? 8 : 0,
          }}
          size={16}
          source={require('@assets/icons/icon-chevron-left.svg' /*'@assets/icons/dark/arrow_left.png'*/)}/>

      )
    })}>

    { loginScreen() }
    { registerScreen() }
    { passwordRecoveryScreen() }
    { configuratorScreen() }
    { detailsScreen() }
    { profileScreen() }
    { profileMenuScreen() }
    { changeEmailScreen() }
    { changePasswordScreen() }
    { changeProfileScreen() }
    { legalNoticeScreen() }
    { privacyNoticeScreen() }
    { termsScreen() }
    { storesScreen() }
    { looksScreen() }
    { appointmentScreen() }
    { finishScreen() }

  </Stack.Navigator>

)

export default MainNavigator

import { call } from 'redux-saga/effects'

import { reset } from '@navigation/root'

export default function* () {

  try {

    yield call(reset, {
      index: 0,
      routes: [{ name: 'Configurator' }],
    })

  }
  catch (err) {

    yield call(console.error, err)

  }

}

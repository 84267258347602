import React from 'react'

import {
  StyleSheet,
  Pressable,
  Platform,
} from 'react-native'

import {
  COLOR_GREY_LIGHTER,
  COLOR_BLACK,
} from '@common/constants'

const Button = ({
  style,
  stylePressed,
  ...props
}: any) => (

  <Pressable
    style={(state) => ([
      styles.default,
      {style},
      state.pressed && styles.pressed,
      state.pressed && {backgroundColor: stylePressed},
      Platform.OS === 'web' && { cursor: 'pointer' } ,
      typeof style === 'function' ? style(state) : style,
    ])}
    { ...props } />

)

const styles = StyleSheet.create({

  default: {
    padding: 16,
    borderRadius: 22,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLOR_BLACK,
  },

  pressed: {
    backgroundColor: COLOR_GREY_LIGHTER,
  },

})

export default Button

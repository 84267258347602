import React from 'react'

import { connect } from 'react-redux'

import { State } from '@common/types'

import {
  setViewType,
  setDetailsVisible,
} from './DetailsScreen.actions'

import {
  makeAppointment,
} from '@common/actions'

import Stack from '@navigation/stack'
import options from './DetailsScreen.options'
import DetailsScreen from './DetailsScreen.component'
import {shareLook} from "@screens/Configurator/ConfiguratorScreen.actions";

const mapStateToProps = ({
  global: {
    loggedIn,
  },
  configuratorScreen: {
    layers,
    selectedItems,
    price,
  },
  detailsScreen: {
    detailsVisible,
    viewType,
  },
}: State) => ({
  loggedIn,
  layers,
  selectedItems,
  price,
  detailsVisible,
  viewType,
})

const mapDispatchToProps = {
  setViewType,
  setDetailsVisible,
  makeAppointment,
  shareLook,
}

export default function() {

  return (
    <Stack.Screen
      name='Details'
      options={options}
      component={connect(mapStateToProps, mapDispatchToProps)(DetailsScreen)}
    />
  )

}

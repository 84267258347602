import produce from 'immer'

import {
  ProfileScreenState,
  ProfileScreenActionType,
  Look,
  ListType,
  INIT_SUCCESS,
  SET_LIST_TYPE,
  CHANGE_DRESS_SIZE_SUCCESS,
  DELETE_LOOK_SUCCESS,
  CHANGE_LOOK_NAME_SUCCESS,
} from './ProfileScreen.types'

const INITIAL_STATE: ProfileScreenState = {
  listType: ListType.ROWS,
  looks: [],
}

function adjustPrices(draft: ProfileScreenState, dressSize: number) {

  let factor = 1

  if (dressSize >= 50) factor = 1.2
  else
  if (dressSize >= 46) factor = 1.1

  draft.looks.forEach(look => look.items.forEach(item => {

    item.price = item.product.price * factor

  }))

}

export default produce((
  draft: ProfileScreenState,
  action: ProfileScreenActionType
) => {

  switch (action.type) {

    case INIT_SUCCESS:
      draft.looks = action.looks
      adjustPrices(draft, Number(action.dressSize))
      break

    case SET_LIST_TYPE:
      draft.listType = action.listType
      break

    case CHANGE_DRESS_SIZE_SUCCESS:
      adjustPrices(draft, Number(action.dressSize))
      break

    case DELETE_LOOK_SUCCESS:
      draft.looks = draft.looks.filter(look => look.id !== action.lookId)
      break

    case CHANGE_LOOK_NAME_SUCCESS: {
      const index = draft.looks.findIndex(look => look.id == action.lookId)
      if (index !== -1) {
        draft.looks[index].name = action.name
      }
      break
    }

  }

}, INITIAL_STATE)


import {
  PasswordRecoveryScreenActionType as ActionType,
  RESET,
  SET_ERROR,
  REQUEST_PASSWORD_RECOVERY_EMAIL,
  REDEEM_PASSWORD_RECOVERY_TOKEN,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  REQUEST_PASSWORD_RECOVERY_EMAIL_SUCCESS,
  REQUEST_PASSWORD_RECOVERY_EMAIL_FAILURE,
} from './PasswordRecoveryScreen.types'

export const reset = (route: any): ActionType => ({
  type: RESET,
  route,
})

export const setError = (error?: string): ActionType => ({
  type: SET_ERROR,
  error,
})

export const requestPasswordRecoveryEmail = (): ActionType => ({
  type: REQUEST_PASSWORD_RECOVERY_EMAIL,
})

export const requestPasswordRecoveryEmailSuccess = (): ActionType => ({
  type: REQUEST_PASSWORD_RECOVERY_EMAIL_SUCCESS,
})

export const requestPasswordRecoveryEmailFailure = (error: any): ActionType => ({
  type: REQUEST_PASSWORD_RECOVERY_EMAIL_FAILURE,
  error,
})

export const redeemPasswordRecoveryToken = (): ActionType => ({
  type: REDEEM_PASSWORD_RECOVERY_TOKEN,
})

export const changePassword = (): ActionType => ({
  type: CHANGE_PASSWORD,
})

export const changePasswordSuccess = (): ActionType => ({
  type: CHANGE_PASSWORD_SUCCESS,
})

export const changePasswordFailure = (error: any): ActionType => ({
  type: CHANGE_PASSWORD_FAILURE,
  error,
})


import { takeLatest } from 'redux-saga/effects'

import {
  INIT,
  LOGIN,
  PASSWORD_RECOVERY,
} from './LoginScreen.types'

import init from './sagas/init.saga'
import login from './sagas/login.saga'
import passwordRecovery from './sagas/passwordRecovery.saga'

export default function* () {

  yield takeLatest(INIT, init)

  yield takeLatest(LOGIN, login)

  yield takeLatest(PASSWORD_RECOVERY, passwordRecovery)

}

import { call, put, select } from 'redux-saga/effects'

import { setFormValue } from '@common/actions'

import { reset as resetStores } from '@screens/Stores/StoresScreen.actions'
import { reset as resetLooks } from '@screens/Looks/LooksScreen.actions'
import { reset as resetAppointment } from '@screens/Appointment/AppointmentScreen.actions'

import { navigate } from '@navigation/root'

import { showAlertModal } from "@modals/Alert/AlertModal.actions";

export default function* () {

  try {

    const {
      global: {
        loggedIn,
      },
      profile: {
        street,
        zipcode,
        city,
      },
    } = yield select(state => state)

    if (!loggedIn) {

      navigate('Login')

      yield put(showAlertModal('Bitte melde Dich an!', 'Ok'))

      return

    }

    yield put(resetLooks())
    yield put(resetStores())
    yield put(resetAppointment())

    yield put(setFormValue('stores', 'location', `${street}, ${zipcode} ${city}`))

    yield call(navigate, 'Looks')

  }
  catch (err) {

    yield call(console.error, err)

  }

}

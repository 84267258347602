import { call, put, select } from 'redux-saga/effects'

import {
  initSuccess,
  initFailure,
} from '../LooksScreen.actions'

import fetchLooks from '@screens/Profile/sagas/fetchLooks.saga'

export default function* (): any {

  try {

    const {
      looks,
    } = yield select(state => state.looksScreen)

    if (!looks.length) {

      const fetchedLooks = yield call(fetchLooks)

      yield put(initSuccess(fetchedLooks))

    }
    else {

      yield put(initSuccess(looks))

    }

  }
  catch (err) {

    yield call(console.error, err)

    yield put(initFailure(err))

  }

}

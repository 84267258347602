
import { connect } from 'react-redux'

import { State } from '@common/types'

import TimeModal from './TimeModal.component'

import {
  timeModalAccepted,
  timeModalDismissed,
} from '@modals/Time/TimeModal.actions';

const mapStateToProps = ({
  timeModal: {
    visible,
    times,
    selectedTime,
  }
}: State) => ({
  visible,
  times,
  selectedTime,
})

const mapDispatchToProps = {
  timeModalAccepted,
  timeModalDismissed,
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeModal)

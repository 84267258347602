import React from 'react'

import {
  StyleSheet,
  Platform,
  ScrollView,
  Pressable,
  Image,
} from 'react-native'

import {
  FALLBACK_PRODUCT_COLOR,
} from '@common/constants'

import { ProductVariant } from '../ConfiguratorScreen.types'

const ColorBar = ({
  style,
  horizontal,
  itemSize = 25,
  spacing = 8,
  product,
  selectedVariant,
  onPress,
}: any) => (

  <ScrollView
    style={style}
    horizontal={horizontal}
    showsHorizontalScrollIndicator={false}>

    {product && product.variants.map((variant: ProductVariant, variantIndex: number) => {

      const variantActive = selectedVariant && variant.id === selectedVariant.id;

      return (

        <Pressable
          key={variant.id}
          onPress={() => onPress(variant)}
          style={[
            styles.colorItem,
            variantActive && styles.activeColor,
            horizontal ? {
              marginRight: variantIndex < product.variants.length - 1 ? spacing : 0,
            } : {
              marginBottom: variantIndex < product.variants.length - 1 ? spacing : 0,
            }, {
              width: itemSize,
              height: itemSize,
              borderColor: '#E6E6E6',
              backgroundColor: variant.color ? variant.color.colorHexCode : FALLBACK_PRODUCT_COLOR,
            },
            Platform.OS === 'web' && { cursor: 'pointer' } ,
          ]}>

          {variantActive &&
          <Image
            style={styles.activeColorIcon}
            source={require('@assets/icons/icon-heart-filled-white.svg' /*'@assets/icons/white/heart_filled.png'*/)}
          />
          }

        </Pressable>

      )

    })}

  </ScrollView>

)

const styles = StyleSheet.create({

  colorItem: {
    borderWidth: 1,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },

  activeColor: {
    color: '#EFEFEF',
    width: 25,
    height: 25,
  },

  activeColorIcon: {
    width: 15,
    height: 15,
  },

})

export default ColorBar

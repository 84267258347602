
import { takeLatest } from 'redux-saga/effects'

import {
  CHANGE_EMAIL,
} from './ChangeEmailScreen.types'

import changeEmail from './sagas/changeEmail.saga'

export default function* () {

  yield takeLatest(CHANGE_EMAIL, changeEmail)

}

import React from 'react'

import {
  StyleSheet,
} from 'react-native'

import Modal from '@components/Modal.component';
import DateSelect from '@modals/Date/DateSelect.component';

const DateModal = ({
  visible,
  defaultDate,
  dateModalAccepted,
  dateModalDismissed,
}: any) => (

  <Modal
    visible={visible}
    style={styles.modal}
    onDismiss={dateModalDismissed}
    content={

      <DateSelect
        selectedDate={defaultDate}
        setDate={(date: any) => dateModalAccepted(date)}/>

    }

  />

)

const styles = StyleSheet.create({

  // TODO klären
  modal: {
    paddingVertical: 35,
    paddingHorizontal: 25,
  },

})

export default DateModal

import produce from 'immer'

import {
  DateModalState,
  DateModalActionType,
  SHOW_DATE_MODAL,
  DATE_MODAL_ACCEPTED,
  DATE_MODAL_DISMISSED,
} from './DateModal.types'
import moment from "moment";

const INITIAL_STATE: DateModalState = {
  visible: false,
  message: '',
  defaultDate: ''
}

export default produce((draft: DateModalState, action: DateModalActionType) => {

  switch (action.type) {

    case DATE_MODAL_ACCEPTED:
      draft.visible = false
      draft.defaultDate = action.date.dateString
      break

    case DATE_MODAL_DISMISSED:
      draft.visible = false
      break

    case SHOW_DATE_MODAL:
      draft.visible = true
      draft.message = action.message
      draft.defaultDate = moment(action.defaultDate, "DD.MM.YYYY").format("YYYY-MM-DD")
      break
  }

}, INITIAL_STATE)

import { call, put, select } from 'redux-saga/effects'

import http from '../../../axios'

import {
  setError,
  requestPasswordRecoveryEmailSuccess,
  requestPasswordRecoveryEmailFailure,
} from '../PasswordRecoveryScreen.actions'

import validateForm from '../../../sagas/validateForm.saga'

import { showAlertModal } from '@modals/Alert/AlertModal.actions'

const constraints = {
  email: {
    presence: {
      allowEmpty: false,
      message: 'Bitte eMail eingeben',
    },
    email: {
      message: 'Bitte korrekte eMail eingeben',
    },
  },
}

export default function* () {

  try {

    const {
      global: {
        storefrontUrl,
      },
      passwordRecoveryScreen: {
        formValues: {
          email,
        },
      },
    } = yield select(state => state)

    yield put(setError())

    yield call(validateForm, 'passwordRecovery', constraints, {
      email,
    })

    yield call(http.post, '/account/recovery-password', {
      email,
      storefrontUrl,
    })

    yield put(requestPasswordRecoveryEmailSuccess())

    yield put(showAlertModal('Bitte prüfe Deinen Posteingang!', 'Ok'))

  }
  catch (err) {

    yield call(console.error, err)

    yield put(requestPasswordRecoveryEmailFailure(err))

  }

}

import React from 'react'

import {
  StyleSheet,
  View,
} from 'react-native'

import {
  RADIUS_17,
  COLOR_GREY,
  COLOR_BLACK,
  UNNA_REG, MON_500,
} from '@common/constants'

import IconButton from '@components/IconButton.component'
import Text from '@components/Text.component'

const InfoBoxItem = ({
  style,
  onChange,
  label,
  value,
}: any) => (

  <View
    style={[
      styles.item,
      style,
    ]}>

    <Text
      style={styles.infoLabel}>
      {label}
    </Text>

    <Text
      style={styles.infoValue}>
      {value}
    </Text>

    <IconButton
      onPress={onChange}
      style={styles.editButton}
      size={14}
      source={require('@assets/icons/icon-edit.svg' /*'@assets/icons/dark/edit.png'*/)}/>

  </View>

)

const InfoBox = ({
  style,
  onChangeDressSize,
  onChangeWeddingDate,
  dressSize,
  weddingDate,
}: any) => (

  <View
    style={[
      styles.container,
      style,
    ]}>

    <InfoBoxItem
      style={{ marginRight: 8 }}
      onChange={onChangeDressSize}
      label='Konfektionsgröße'
      value={dressSize}/>

    <InfoBoxItem
      style={{ marginLeft: 8 }}
      onChange={onChangeWeddingDate}
      label='Hochzeit'
      value={weddingDate}/>

  </View>

)

const styles = StyleSheet.create({

  container: {
    flexDirection: 'row',
  },

  item: {
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 23,
    borderRadius: RADIUS_17,
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
  },

  infoLabel: {
    marginBottom: 4,
    fontSize: 10,
    color: COLOR_GREY,
    fontFamily: MON_500,
  },

  infoValue: {
    fontSize: 24,
    color: COLOR_BLACK,
    fontFamily: UNNA_REG,
    fontWeight: '500',
  },

  editButton: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },

})

export default InfoBox

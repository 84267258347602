
import { takeLatest } from 'redux-saga/effects'

import {
  MAKE_APPOINTMENT,
  PICK_DATE,
  PICK_TIME,
} from './AppointmentScreen.types'

import makeAppointment from './sagas/makeAppointment.saga'
import pickDate from './sagas/pickDate.saga'
import pickTime from './sagas/pickTime.saga'

export default function* () {

  yield takeLatest(MAKE_APPOINTMENT, makeAppointment)

  yield takeLatest(PICK_DATE, pickDate)

  yield takeLatest(PICK_TIME, pickTime)

}

import React from 'react'

import {
  StyleSheet,
  View,
  ActivityIndicator,
} from 'react-native'

import Text from '@components/Text.component'

const BusyScreen = ({
  message,
}: any) => (

  <View
    style={styles.container}>

    <ActivityIndicator
      size="large"
      color="#333538" />

    <Text
      style={styles.message}>
      {message}
    </Text>

  </View>

)

const styles = StyleSheet.create({

  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  message: {
    marginTop: 16,
  },

})

export default BusyScreen

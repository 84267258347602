import React from 'react'

import {
  StyleSheet,
  View,
  ScrollView,
  PressableStateCallbackType,
} from 'react-native'

import Text from '@components/Text.component'
import Button from '@components/Button.component'

import {
  LAYER_SWITCH_BUTTON_SPACING,
  TEXT_SM,
  COLOR_GREY_LIGHTER,
  COLOR_PILE,
  COLOR_BLACK,
  MON_500,
  MON_700
} from '@common/constants'

const LayerSwitch = ({
   layers,
   selectedLayer,
   switchLayer,
 }: any) => (

  <View
    style={styles.container}>

    <ScrollView
      contentContainerStyle={styles.contentContainer}
      horizontal={true}
      showsHorizontalScrollIndicator={false}>

      {layers.map((layer: string, index: number) =>

        <Button
          key={layer}
          onPress={() => switchLayer(index)}
          style={({ pressed }: PressableStateCallbackType) => ([
            styles.button,
            pressed && styles.buttonPressed,
            selectedLayer === index && styles.buttonActive,
            { marginLeft: index > 0 ? LAYER_SWITCH_BUTTON_SPACING : 0 },
          ])}
          children={() =>

            <Text
              style={[
                styles.label,
                selectedLayer === index && styles.labelActive,
              ]}>
              {layer}
            </Text>

          } />

      )}

    </ScrollView>

  </View>

)

const styles = StyleSheet.create({

  container: {
    height: 48,
  },

  contentContainer: {
    paddingHorizontal: 16,
    alignItems: 'center',
  },

  button: {
    paddingVertical: 8,
    height: 26,
    justifyContent: 'center',
    borderRadius: 10,
    backgroundColor: 'white',
  },

  buttonPressed: {
    backgroundColor: COLOR_GREY_LIGHTER,
  },

  buttonActive: {
    backgroundColor: COLOR_BLACK,
    color: COLOR_PILE,
    fontFamily: MON_700,
    fontWeight: '700',
  },
  label: {
    fontSize: TEXT_SM,
    fontFamily: MON_500,
    fontWeight: '500',
  },

  labelActive: {
    color: COLOR_PILE,
    fontFmaily: MON_700,
    fontWeight: '700',
  }

})

export default LayerSwitch

import React from 'react'

import {
  StyleSheet,
  ScrollView,
  View,
} from 'react-native'

import Button from '@components/DarkButton.component'
import Message from '@components/Message.component'
import BusyScreen from '@components/BusyScreen.component'

import ChangePasswordForm from './ChangePasswordForm.component'

const ChangePasswordScreen = ({
  busy,
  formValues,
  formErrors,
  error,
  setFormValue,
  changePassword,
}: any) => (

  busy ?

  <BusyScreen message='Bitte warten'/>

  :

  <ScrollView
    style={styles.container}>

    {!!error &&
    <Message message={error} />
    }

    <ChangePasswordForm
      formName="changePassword"
      formValues={formValues}
      formErrors={formErrors}
      setFormValue={setFormValue}
      submit={changePassword}/>

    <View
      style={styles.bottomContainer}>

      <Button
        onPress={changePassword}
        label="Übernehmen" />

    </View>

  </ScrollView>

)

const styles = StyleSheet.create({

  container: {
    flex: 1,
  },

  bottomContainer: {
    padding: 24,
  },

})

export default ChangePasswordScreen

import { call, put, select, take } from 'redux-saga/effects'

import { navigate } from '@navigation/root'
import { showAlertModal } from "@modals/Alert/AlertModal.actions";
import { showConfirmModal } from '@modals/Confirm/ConfirmModal.actions';

import {
  CONFIRM_MODAL_ACCEPTED,
  CONFIRM_MODAL_DISMISSED,
} from "@modals/Confirm/ConfirmModal.types";

export default function* () {

  try {

    const { selectedLooks } = yield select(state => state.looksScreen)

    if( selectedLooks.length > 10 ){

      yield put(showAlertModal('Bitte wähle nur 10 oder weniger Looks! ', 'Ok'))

      return

    } else if ( selectedLooks.length == 0 ) {

      yield put(showConfirmModal('Möchtest Du wirklich keine Looks für deinen Wunschtermin vorauswählen? ', 'Ja', 'Nein'))

      const result = yield take([CONFIRM_MODAL_ACCEPTED, CONFIRM_MODAL_DISMISSED])

      if (result.type !== 'CONFIRM_MODAL_ACCEPTED') {

        return
  
      }
    } 

    yield call(navigate, 'Stores')
  
  }
  
  catch (err) {

    yield call(console.error, err)

  }

}

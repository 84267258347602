import React from 'react'

import { connect } from 'react-redux'

import { State } from '@common/types'

import Stack from '@navigation/stack'

import PrivacyNoticeScreen from "@screens/Profile/Info/PrivacyNoticeScreen.component";

const mapStateToProps = ({
}: State) => ({
})

const mapDispatchToProps = {
}

export default function() {

  return (
    <Stack.Screen
      name='PrivacyNotice'
      component={connect(mapStateToProps, mapDispatchToProps)(PrivacyNoticeScreen)}
      options={{
        title: 'Datenschutzerklärung',
      }}
    />
  )

}



import {
  DetailsScreenActionType as ActionType,
  SET_VIEW_TYPE,
  SET_DETAILS_VISIBLE,
} from './DetailsScreen.types'

import { ViewType } from '@screens/Configurator/ConfiguratorScreen.types'

export const setViewType = (viewType: ViewType): ActionType => ({
  type: SET_VIEW_TYPE,
  viewType,
})

export const setDetailsVisible = (visible: boolean): ActionType => ({
  type: SET_DETAILS_VISIBLE,
  visible,
})


import {
  ChangeEmailScreenActionType as ActionType,
  RESET,
  SET_ERROR,
  CHANGE_EMAIL,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_EMAIL_FAILURE,
} from './ChangeEmailScreen.types'

export const reset = (): ActionType => ({
  type: RESET,
})

export const setError = (error?: string): ActionType => ({
  type: SET_ERROR,
  error,
})

export const changeEmail = (): ActionType => ({
  type: CHANGE_EMAIL,
})

export const changeEmailSuccess = (): ActionType => ({
  type: CHANGE_EMAIL_SUCCESS,
})

export const changeEmailFailure = (error: any): ActionType => ({
  type: CHANGE_EMAIL_FAILURE,
  error,
})

import {call, put, select} from 'redux-saga/effects'

import * as Sharing from 'expo-sharing';

import http from '../../../axios'

import {
  shareLookSuccess,
  shareLookFailure,
} from '../ConfiguratorScreen.actions'

import { showAlertModal } from '@modals/Alert/AlertModal.actions';
import { showPromptModal } from '@modals/Prompt/PromptModal.actions';

import { Look } from '@screens/Profile/ProfileScreen.types';

import storeLook from './storeLook.saga';

import { navigate } from "@navigation/root";

export default function* () {

  try {

    const {
      global: {
        loggedIn,
      },
      configuratorScreen: {
        look,
      },
    } = yield select(state => state)

    if (!loggedIn) {

      navigate('Login')

      yield put(showAlertModal('Bitte melde Dich an!', 'Ok'))

      return

    }

    if (!look) {

      const newLook: Look = yield call(storeLook)

      if (newLook) {

        const { data } = yield call(http.post, '/look/share', {
          id: newLook.id,
        })

        yield call(share, data.url)

      }

    }
    else {

      const { data } = yield call(http.post, '/look/share', {
        id: look.id,
      })

      yield call(share, data.url)

    }

    yield put(shareLookSuccess())

  }
  catch (err) {

    yield call(console.error, err)

    yield put(shareLookFailure(err))

  }

}

function* share(url: string) {

  const sharingAvailable: boolean = yield call(Sharing.isAvailableAsync)

  if (sharingAvailable) {

    yield call(Sharing.shareAsync, url)

  }
  else {

    yield put(showPromptModal('URL zum Teilen:', 'Abbrechen', 'Ok', url))

  }

}

import produce from 'immer'

import {
  DetailsScreenState,
  DetailsScreenActionType,
  SET_VIEW_TYPE,
  SET_DETAILS_VISIBLE,
} from './DetailsScreen.types'

import { ViewType } from '@screens/Configurator/ConfiguratorScreen.types'

const INITIAL_STATE: DetailsScreenState = {
  viewType: ViewType.FRONT,
  detailsVisible: true,
}

export default produce((
  draft: DetailsScreenState,
  action: DetailsScreenActionType
) => {

  switch (action.type) {

    case SET_VIEW_TYPE:
      draft.viewType = action.viewType
      break

    case SET_DETAILS_VISIBLE:
      draft.detailsVisible = action.visible
      break

  }

}, INITIAL_STATE)

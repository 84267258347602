import produce from 'immer'

import moment from 'moment'

import {
  CommonActionType,
  SET_FORM_VALUE,
  SET_FORM_ERRORS,
} from '@common/types'

import {
  AppointmentScreenState,
  AppointmentScreenActionType,
  MAKE_APPOINTMENT,
  MAKE_APPOINTMENT_SUCCESS,
  MAKE_APPOINTMENT_FAILURE,
  RESET,
} from './AppointmentScreen.types'

const INITIAL_STATE: AppointmentScreenState = {
  busy: false,
  formValues: {
    date: '',
    time: '',
  },
  formErrors: {
  },
}

export default produce((
  draft: AppointmentScreenState,
  action: CommonActionType|AppointmentScreenActionType
) => {

  switch (action.type) {

    case RESET:
      return INITIAL_STATE

    case SET_FORM_VALUE:
      if (action.form === 'appointment') {
        if (action.key === 'date') {
          draft.formValues['date'] = moment(action.val.dateString).format('DD.MM.YYYY')
        } else
        if (action.key === 'time') {
          draft.formValues['time'] = action.val
        } else {
          draft.formValues[action.key] = action.val
        }
      }
      break

    case SET_FORM_ERRORS:
      if (action.form === 'appointment') {
        draft.formErrors = action.errors
      }
      break

    case MAKE_APPOINTMENT:
      draft.busy = true
      break

    case MAKE_APPOINTMENT_SUCCESS:
      draft.busy = false
      break

    case MAKE_APPOINTMENT_FAILURE:
      draft.busy = false
      break

  }

}, INITIAL_STATE)

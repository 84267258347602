import React from 'react'

import {
  StyleSheet,
  View,
} from 'react-native'

import IconButton from '@components/IconButton.component'
import Text from '@components/Text.component'


import {
  MON_500,
  TEXT_MD,
  MON_700
} from '@common/constants'

import {
  ListType,
} from './ProfileScreen.types'

const LookListHeader = ({
  setListType,
}: any) => (

  <View
    style={styles.container}>

    <Text style={styles.headline} >
      Deine favorisierten Looks
    </Text>

    <IconButton
      onPress={() => setListType(ListType.ROWS)}
      size={16}
      style={styles.icon}
      source={require('@assets/icons/icon-list.svg' /*'@assets/icons/dark/list.png'*/)}/>

    <IconButton
      onPress={() => setListType(ListType.COLUMNS)}
      size={16}
      style={styles.icon}
      source={require('@assets/icons/icon-tiles.svg' /*'@assets/icons/dark/tiles.png'*/)}/>

  </View>

)

const styles = StyleSheet.create({

  container: {
    marginBottom: 25,
    flexDirection: 'row',
    alignItems: 'center',
  },

  headline: {
    flex: 1,
    fontFamily: MON_500,
    fontSize: TEXT_MD,
    lineHeight: 23,
  },

  icon: {
    width: 32,
  },

})

export default LookListHeader

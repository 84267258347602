import React from 'react'

import {
  Platform,
  Linking,
  Pressable,
  Image,
} from 'react-native'

import {
  MON_700,
} from '@common/constants'

import Text from '@components/Text.component'
import IconButton from '@components/IconButton.component'

import { makeAppointment } from '@common/actions'

import { navigateToProfile } from './ConfiguratorScreen.actions'

import store from '@state/store'

export default () => ({
  title: 'Konfigurator',
  headerLeft: () => (

    <Text
      onPress={() => store.dispatch(makeAppointment())}
      style={{
        fontSize: 14,
        fontFamily: MON_700,
        marginLeft: 16,
      }}>
      Termin buchen
    </Text>

  ),
  headerRight: () => (

    <IconButton
      onPress={() => store.dispatch(navigateToProfile()) }
      style={{
        marginRight: Platform.OS === 'web' ? 8 : 0,
      }}
      source={require('@assets/icons/icon-profile.svg' /*'@assets/icons/dark/profile.png'*/)}/>

  ),
  headerTitle: () => (

    <Pressable
      onPress={() => Linking.openURL('https://lemoos.de')}>
      <Image
        style={{ width: 48, height: 48 }}
        source={require('@assets/icons/logo.svg' /*'@assets/logo.png'*/)}
      />
    </Pressable>

  )
})

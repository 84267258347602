import { call, put, select } from 'redux-saga/effects'

import { navigate } from '@navigation/root'

import { showAlertModal } from "@modals/Alert/AlertModal.actions";

export default function* () {

  try {

    const { loggedIn } = yield select(state => state.global)

    if(loggedIn) {

      yield call(navigate, 'Profile')

    } else {

      yield call(navigate, 'Login')

      yield put(showAlertModal('Bitte melde Dich an!', 'Ok'))

    }

  }
  catch (err) {

    yield call(console.error, err)

  }

}

import React from 'react'

import {
  StyleSheet,
  Image,
  Text,
  View,
} from 'react-native'

import {
  TEXT_MD,
  COLOR_BLACK,
  COLOR_PILE,
  COLOR_GREY,
  MON_600
} from '@common/constants'

import Modal from '@components/Modal.component';
import DarkButton from '@components/DarkButton.component';

const AlertModal = ({
  visible,
  message,
  dismissLabel,
  alertModalDismissed,
}: any) => (

  <Modal
    visible={visible}
    onDismiss={alertModalDismissed}
    content={<>

      <View style={styles.modalInner}>
          <Image
            style={styles.image}
            source={require('@assets/icons/icon-info.svg' /*'@assets/icons/dark/info.png'*/)}
          />
        <Text
          style={styles.headline}>
          {message}
        </Text>
      </View>

    </>}

    footer={<>
      <View style={styles.btnContainer}>
        <DarkButton
          style={styles.dismissButton}
          onPress={alertModalDismissed}
          label={dismissLabel}
          stylePressed={styles.pressed}
        />
        </View>
    </>}

  />

)

const styles = StyleSheet.create({

  modalInner: {
    paddingTop: 40,
    width: 250,
    marginHorizontal: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    zIndex: 2,
  },

  image: {
    position: 'absolute',
    left: -40,
    width: 25,
    height: 25,
  },

  headline: {
    fontSize: TEXT_MD,
    lineHeight: 23,
    fontFamily: MON_600,
    width: '100%',
    color: COLOR_BLACK,
    marginLeft: 8,
  },

  btnContainer: {
    marginTop: 25,
    marginBottom: 40,
    width: 230,
    justifyContent: 'flex-start'
  },

  dismissButton: {
    backgroundColor: COLOR_BLACK,
    color: COLOR_PILE,
    width: 130,
    height: 44,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  pressed: {
    color: '#fff',
    backgroundColor: COLOR_GREY,
  },

})

export default AlertModal


import { LoginScreenState } from '@screens/Login/LoginScreen.types'
import { RegisterScreenState } from '@screens/Register/RegisterScreen.types'
import { PasswordRecoveryScreenState } from '@screens/PasswordRecovery/PasswordRecoveryScreen.types'
import { ConfiguratorScreenState } from '@screens/Configurator/ConfiguratorScreen.types'
import { DetailsScreenState } from '@screens/Details/DetailsScreen.types'
import { ProfileScreenState } from '@screens/Profile/ProfileScreen.types'
import { ChangeEmailScreenState } from '@screens/ChangeEmail/ChangeEmailScreen.types'
import { ChangePasswordScreenState } from '@screens/ChangePassword/ChangePasswordScreen.types'
import { ChangeProfileScreenState } from '@screens/ChangeProfile/ChangeProfileScreen.types'
import { StoresScreenState } from '@screens/Stores/StoresScreen.types'
import { LooksScreenState } from '@screens/Looks/LooksScreen.types'
import { AppointmentScreenState } from '@screens/Appointment/AppointmentScreen.types'
import { FinishScreenState } from '@screens/Finish/FinishScreen.types'
import { AlertModalState } from '@modals/Alert/AlertModal.types'
import { PromptModalState } from '@modals/Prompt/PromptModal.types'
import { ConfirmModalState } from '@modals/Confirm/ConfirmModal.types'
import { DateModalState } from '@modals/Date/DateModal.types'
import { TimeModalState } from '@modals/Time/TimeModal.types'

export interface GlobalState {
  apiUrl: string,
  apiKey: string,
  storefrontUrl: string,
  loggedIn?: boolean,
}

export interface ProfileState {
  salutationId: string,
  firstName: string,
  lastName: string,
  email: string,
  defaultBillingAddressId: string,
  street: string,
  zipcode: string,
  city: string,
  countryId: string,
  phoneNumber: string,
  dressSize: string,
  weddingDate: string,
}

export interface State {
  global: GlobalState,
  profile: ProfileState,
  loginScreen: LoginScreenState,
  registerScreen: RegisterScreenState,
  passwordRecoveryScreen: PasswordRecoveryScreenState,
  configuratorScreen: ConfiguratorScreenState,
  detailsScreen: DetailsScreenState,
  profileScreen: ProfileScreenState,
  changeEmailScreen: ChangeEmailScreenState,
  changePasswordScreen: ChangePasswordScreenState,
  changeProfileScreen: ChangeProfileScreenState,
  storesScreen: StoresScreenState,
  looksScreen: LooksScreenState,
  appointmentScreen: AppointmentScreenState,
  finishScreen: FinishScreenState,
  alertModal: AlertModalState,
  promptModal: PromptModalState,
  confirmModal: ConfirmModalState,
  dateModal: DateModalState,
  timeModal: TimeModalState,
}

export const INIT = 'INIT'
export const INIT_SUCCESS = 'INIT_SUCCESS'
export const INIT_FAILURE = 'INIT_FAILURE'

export const SET_FORM_VALUE = 'SET_FORM_VALUE'

export const SET_FORM_ERRORS = 'SET_FORM_ERRORS'

export const MAKE_APPOINTMENT = 'MAKE_APPOINTMENT'

export interface InitAction {
  type: typeof INIT,
}

export interface InitSuccessAction {
  type: typeof INIT_SUCCESS,
  apiUrl: string,
  apiKey: string,
  storefrontUrl: string,
  customer?: any,
}

export interface InitFailureAction {
  type: typeof INIT_FAILURE,
  error: any,
}

export interface SetFormValueAction {
  type: typeof SET_FORM_VALUE,
  form: string,
  key: string,
  val: any,
}

export interface SetFormErrorsAction {
  type: typeof SET_FORM_ERRORS,
  form: string,
  errors:any,
}

export interface MakeAppointmentAction {
  type: typeof MAKE_APPOINTMENT,
}

export type CommonActionType =
  InitAction |
  InitSuccessAction |
  InitFailureAction |
  SetFormValueAction |
  SetFormErrorsAction |
  MakeAppointmentAction

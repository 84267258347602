
export interface ChangePasswordScreenFormValues {
  password: string,
  newPassword: string,
  newPasswordConfirm: string,
}

export interface ChangePasswordScreenFormErrors {

}

export interface ChangePasswordScreenState {
  busy: boolean,
  formValues: ChangePasswordScreenFormValues,
  formErrors: ChangePasswordScreenFormErrors,
  error?: string,
}

export const RESET = 'ChangePasswordScreen.reset'

export const SET_ERROR = 'ChangePasswordScreen.setError'

export const CHANGE_PASSWORD = 'ChangePasswordScreen.changePassword'
export const CHANGE_PASSWORD_SUCCESS = 'ChangePasswordScreen.changePassword.success'
export const CHANGE_PASSWORD_FAILURE = 'ChangePasswordScreen.changePassword.failure'

export interface ResetAction {
  type: typeof RESET,
}

export interface SetErrorAction {
  type: typeof SET_ERROR,
  error?: string,
}

export interface ChangePasswordAction {
  type: typeof CHANGE_PASSWORD,
}

export interface ChangePasswordSuccessAction {
  type: typeof CHANGE_PASSWORD_SUCCESS,
}

export interface ChangePasswordFailureAction {
  type: typeof CHANGE_PASSWORD_FAILURE,
  error: any,
}

export type ChangePasswordScreenActionType =
  ResetAction |
  SetErrorAction |
  ChangePasswordAction |
  ChangePasswordSuccessAction |
  ChangePasswordFailureAction

import produce from 'immer'

import {
  LooksScreenState,
  LooksScreenActionType,
  INIT,
  INIT_SUCCESS,
  INIT_FAILURE,
  TOGGLE_LOOK,
  RESET,
} from './LooksScreen.types'

const INITIAL_STATE: LooksScreenState = {
  busy: true,
  looks: [],
  selectedLooks: [],
}

export default produce((
  draft: LooksScreenState,
  action: LooksScreenActionType
) => {

  switch (action.type) {

    case RESET:
      return INITIAL_STATE

    case INIT:
      draft.busy = true
      break

    case INIT_SUCCESS:
      draft.busy = false
      draft.looks = action.looks
      break

    case INIT_FAILURE:
      draft.busy = false
      break

    case TOGGLE_LOOK: {
      const index = draft.selectedLooks.findIndex(item => item.id === action.look.id)
      if (index === -1)
        draft.selectedLooks.push(action.look)
      else
        draft.selectedLooks = draft.selectedLooks.filter(item => item.id !== action.look.id)

      // create new items array in order to refresh list component
      draft.looks = [ ...draft.looks ]
      break
    }

  }

}, INITIAL_STATE)


import { takeLatest } from 'redux-saga/effects'

import {
  INIT,
  APPLY_LOOKS,
} from './LooksScreen.types'

import init from './sagas/init.saga'

import applyLooks from './sagas/applyLooks.saga'

export default function* () {

  yield takeLatest(INIT, init)

  yield takeLatest(APPLY_LOOKS, applyLooks)

}

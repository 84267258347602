
import { DateObject } from 'react-native-calendars';

import {
  DateModalActionType as ActionType,
  SHOW_DATE_MODAL,
  DATE_MODAL_ACCEPTED,
  DATE_MODAL_DISMISSED,
} from './DateModal.types';

export const showDateModal = (message: string, defaultDate: string): ActionType => ({
  type: SHOW_DATE_MODAL,
  message,
  defaultDate,
})

export const dateModalAccepted = (date: DateObject): ActionType => ({
  type: DATE_MODAL_ACCEPTED,
  date,
})

export const dateModalDismissed = (): ActionType => ({
  type: DATE_MODAL_DISMISSED,
})

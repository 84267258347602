import React from 'react'

import {
  StyleSheet,
  View,
  Image,
} from 'react-native'

import {
  PREVIEW_IMAGE_WIDTH,
  PREVIEW_IMAGE_HEIGHT,
} from '@common/constants'

import {
  ProductVariant,
  ViewType,
} from '../ConfiguratorScreen.types'

const Layer = ({
  source,
  width,
  height,
  scale = 1,
  offset = { x: 0, y: 0 },
}: any) => (

  <View
    style={styles.layer} >

    <Image
      style={{
        top: offset.y,
        left: offset.x,
        width: (width || PREVIEW_IMAGE_WIDTH) * scale,
        height: (height || PREVIEW_IMAGE_HEIGHT) * scale,
      }}
      source={source} />

  </View>

)

const Preview = ({
  style,
  layers,
  selectedItems,
  viewType,
  scale,
  offset,
  onLayout,
}: any) => {

  // reorder images for correct rendering

  const variants = [
    selectedItems[0], // basic top
    selectedItems[1], // basic rock
    selectedItems[3], // make up rock
    selectedItems[2], // make up top
  ]

  const getImage = (variant: ProductVariant, slot: number, index: number) => variant.images[slot] && variant.images[slot][index]

  return (

    <View
      style={style}
      onLayout={onLayout}>

      <Layer
        width={1280}
        height={1280}
        scale={scale}
        offset={offset}
        source={viewType === ViewType.FRONT ?
          require('@assets/images/model_front.png') :
          require('@assets/images/model_back.png')} />

      {layers.map((layer: string, index: number) => (

        variants[index] &&
        <Layer
          key={layer}
          scale={scale}
          offset={offset}
          source={{ uri: getImage(variants[index], viewType === ViewType.FRONT ? 0 : 1, 2) }}/>

      ))}

    </View>

  )

}

const styles = StyleSheet.create({

  layer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

})

export default Preview

import { GestureHandlerRootView } from 'react-native-gesture-handler'

import React, { useEffect } from 'react'

import { View, Image } from 'react-native'

import { NavigationContainer } from '@react-navigation/native'

import { Provider, connect } from 'react-redux'

import { SafeAreaProvider } from 'react-native-safe-area-context'

import AppLoading from 'expo-app-loading'

import { useFonts } from 'expo-font'

import {
  Unna_400Regular,
} from '@expo-google-fonts/unna';

import {
  Montserrat_300Light,
  Montserrat_400Regular,
  Montserrat_500Medium,
  Montserrat_600SemiBold,
  Montserrat_700Bold,
} from '@expo-google-fonts/montserrat';

import store from '@state/store'

import { navigationRef } from '@navigation/root'
import MainNavigator from '@navigation/main'

import { init } from '@common/actions'
import { State } from '@common/types'

import AlertModal from "@modals/Alert/AlertModal.container";
import PromptModal from "@modals/Prompt/PromptModal.container";
import ConfirmModal from "./src/modals/Confirm/ConfirmModal.container";
import DateModal from "@modals/Date/DateModal.container";
import TimeModal from "@modals/Time/TimeModal.container";

import theme from './src/theme'

const linking = {
  prefixes: [],
  config: {
    screens: {
      Login: 'Login/:action?',
      PrivacyNotice: 'PrivacyNotice',
    },
  },
}

const App = ({
  loggedIn,
}: any) => {

  useEffect(() => { store.dispatch(init()) }, [])

  let [ fontsLoaded ] = useFonts({
    Unna_400Regular,
    Montserrat_300Light,
    Montserrat_400Regular,
    Montserrat_500Medium,
    Montserrat_600SemiBold,
    Montserrat_700Bold,
  })

  return loggedIn === undefined || !fontsLoaded ?

  <AppLoading/>

  :

  <SafeAreaProvider>

    <GestureHandlerRootView
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: '#FFF5EF',
      }}>

      <View
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }}>

        <Image
          style={{
            width: 463,
            height: 384,
            marginLeft: 112,
          }}
          source={require('@assets/blob.png')} />

      </View>

      <NavigationContainer
        theme={theme}
        linking={linking}
        ref={navigationRef}>

        <MainNavigator/>

      </NavigationContainer>

      <AlertModal/>
      <PromptModal/>
      <ConfirmModal/>
      <DateModal/>
      <TimeModal/>

    </GestureHandlerRootView>

  </SafeAreaProvider>

}

const mapStateToProps = ({
  global: {
    loggedIn,
  },
}: State) => ({
  loggedIn,
})

const ConnectedApp = connect(mapStateToProps)(App)

const Wrapper = () => (

  <Provider store={store}>

    <ConnectedApp />

  </Provider>

)

export default Wrapper

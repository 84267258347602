import React from 'react'

import {
  StyleSheet,
  FlatList,
} from 'react-native'

import {
  ListType,
  Look,
} from './ProfileScreen.types'

import LookListItemRow from './LookListItemRow.component'
import LookListItemTile from './LookListItemTile.component'

const LookList = ({
  style,
  listType,
  items,
  selection,
  onPress,
  onEdit,
  onDelete,
}: any) => (

  <FlatList
    style={[
      styles.container,
      style,
    ]}
    showsVerticalScrollIndicator={false}
    key={listType}
    numColumns={listType === ListType.ROWS ? undefined : 2}
    data = {items}
    renderItem={({ item: look, index }) => (

      listType === ListType.ROWS ?

        <LookListItemRow
          look={look}
          active={selection && selection.find((item: Look) => item.id === look.id)}
          onPress={onPress && (() => onPress(look))}
          onEdit={onEdit && (() => onEdit(look))}
          onDelete={onDelete && (() => onDelete(look))}/>

      :

        <LookListItemTile
          look={look}
          index={index}
          active={selection && selection.find((item: Look) => item.id === look.id)}
          onPress={onPress && (() => onPress(look))}
          onEdit={onEdit && (() => onEdit(look))}
          onDelete={onDelete && (() => onDelete(look))}/>

    )}
    keyExtractor={(item) => item.id}
  />

)

const styles = StyleSheet.create({

  container: {
    height: 0, // fix for overflow problem on web
    marginBottom: 16,
  },

})

export default LookList

import React from 'react'

import {
  StyleSheet,
  Platform,
  Pressable,
  PressableStateCallbackType,
  View, Image,
} from 'react-native'

import {
  BROWSER_ITEM_SPACING,
} from '@common/constants'

import {
  ViewType,
} from '@screens/Configurator/ConfiguratorScreen.types'

import Preview from '@screens/Configurator/Configurator/Preview.component'

const LookListItem = ({
  look,
  index,
  active,
  onPress,
}: any) => (

  <Pressable
    onPress={onPress}
    style={({ pressed }: PressableStateCallbackType) => ([
      styles.container,
      pressed && styles.containerPressed,
      Platform.OS === 'web' && { cursor: 'pointer' } ,
      {
        marginTop: index > 1 ? BROWSER_ITEM_SPACING : 0,
        marginLeft: index % 2 ? BROWSER_ITEM_SPACING / 2 : 0,
        marginRight: !(index % 2) ? BROWSER_ITEM_SPACING / 2 : 0,
      }

    ])}>

    {active ?
      <View style={styles.activeIconContainer}>
        <Image
            style={styles.activeIcon}
            source={require('@assets/icons/icon-check-white.svg' /*'@assets/icons/white/check.png'*/)}
        />
      </View>
      :
      <View style={[styles.activeIconContainer, styles.active]}>
      </View>
    }

    <Preview
      style={styles.image}
      layers={[1,2,3,4]}
      selectedItems={look.items}
      scale={0.1}
      viewType={ViewType.FRONT}/>

  </Pressable>

)

const styles = StyleSheet.create({

  container: {
    flex: 1/2,
    height: 160,
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
  },

  containerPressed: {
    backgroundColor: '#EFF1F3',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.15,
    shadowRadius: 20,
  },

  containerActive: {
    backgroundColor: '#FFF0E8',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.15,
    shadowRadius: 20,
  },

  image: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: 16,
  },

  activeIconContainer: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    width: 22,
    height: 22,
    bottom: 10,
    right: 10,
    zIndex: 1,
    borderRadius: 8,
    backgroundColor: '#000',
  },

  active: {
    backgroundColor: '#9A9A9A'
  },

  activeIcon: {
    width: 10,
    height: 10,
  }

})

export default LookListItem

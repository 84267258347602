
import produce from 'immer'

import {
  CommonActionType,
  SET_FORM_VALUE,
  SET_FORM_ERRORS,
} from '@common/types'

import {
  ChangeProfileScreenState,
  ChangeProfileScreenActionType,
  SET_ERROR,
  INIT,
  INIT_SUCCESS,
  INIT_FAILURE,
  CHANGE_PROFILE,
  CHANGE_PROFILE_SUCCESS,
  CHANGE_PROFILE_FAILURE,
  RESET,
} from './ChangeProfileScreen.types'

const INITIAL_STATE: ChangeProfileScreenState = {
  busy: true,
  salutations: [],
  countries: [],
  formValues: {
    salutationId  : '',
    firstName     : '',
    lastName      : '',
    street        : '',
    zipcode       : '',
    city          : '',
    countryId     : '',
    phoneNumber         : '',
    //dressSize: 32,
    //weddingDate: '30.09.21',
  },
  formErrors: {
  },
}

export default produce((
  draft: ChangeProfileScreenState,
  action: CommonActionType|ChangeProfileScreenActionType
) => {

  switch (action.type) {

    case RESET:
      return INITIAL_STATE

    case INIT:
      draft.busy = true
      break

    case INIT_SUCCESS:
      draft.busy = false
      draft.salutations = action.salutations
      draft.countries = action.countries
      draft.formValues = action.profile
      break

    case INIT_FAILURE:
      draft.busy = false
      break

    case SET_FORM_VALUE:
      if (action.form === 'changeProfile') {
        draft.formValues[action.key] = action.val
      }
      break

    case SET_FORM_ERRORS:
      if (action.form === 'changeProfile') {
        draft.formErrors = action.errors
      }
      break

    case SET_ERROR:
      draft.error = action.error
      break

    case CHANGE_PROFILE:
      draft.busy = true
      break

    case CHANGE_PROFILE_SUCCESS:
      draft.busy = false
      break

    case CHANGE_PROFILE_FAILURE:
      draft.busy = false
      break

  }

}, INITIAL_STATE)

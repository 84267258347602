
import { takeLatest } from 'redux-saga/effects'

import {
  INIT,
  CHANGE_PROFILE,
} from './ChangeProfileScreen.types'

import init from './sagas/init.saga'
import changeProfile from './sagas/changeProfile.saga'

export default function* () {

  yield takeLatest(INIT, init)

  yield takeLatest(CHANGE_PROFILE, changeProfile)

}

import React from 'react'

import {
  StyleSheet,
  View,
} from 'react-native'

import TextInput from '@components/TextInput.component'
import SecureInput from '@components/SecureInput.component'

const PasswordRecoveryForm = ({
  formName,
  formValues,
  formErrors,
  step,
  setFormValue,
  submit,
}: any) => (

  <View
    style={styles.container}>

    {step === 1 && <>

    <TextInput
      label="E-Mail:"
      error={formErrors.email}
      inputProps={{
        value: formValues.email,
        onChangeText: (email: string) => setFormValue(formName, 'email', email),
        onSubmitEditing: submit,
      }}/>

    </>}

    {step === 2 && <>

    <TextInput
      label="Code:"
      error={formErrors.recoveryToken}
      inputProps={{
        value: formValues.recoveryToken,
        onChangeText: (recoveryToken: string) => setFormValue(formName, 'recoveryToken', recoveryToken),
        onSubmitEditing: submit,
      }}/>

    <SecureInput
      label="Neues Passwort:"
      error={formErrors.newPassword}
      inputProps={{
        value: formValues.newPassword,
        onChangeText: (newPassword: string) => setFormValue(formName, 'newPassword', newPassword),
        onSubmitEditing: submit,
      }}/>

    <SecureInput
      label="Neues Passwort bestätigen:"
      error={formErrors.newPasswordConfirm}
      inputProps={{
        value: formValues.newPasswordConfirm,
        onChangeText: (newPasswordConfirm: string) => setFormValue(formName, 'newPasswordConfirm', newPasswordConfirm),
        onSubmitEditing: submit,
      }}/>

    </>}

  </View>

)

const styles = StyleSheet.create({

  container: {
    flex: 1,
    paddingHorizontal: 24,
  },

})

export default PasswordRecoveryForm

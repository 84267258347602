import React from 'react'

import {
  StyleSheet,
  View,
} from 'react-native'

import Text from '@components/Text.component'
import Button from '@components/DarkButton.component'
import BusyScreen from '@components/BusyScreen.component'

import AppointmentForm from './AppointmentForm.component'
import {COLOR_BLACK, TEXT_XL, UNNA_REG, LINE_HEIGHT_XL, MON_500} from "../../common/constants";

const AppointmentScreen = ({
  busy,
  formValues,
  formErrors,
  pickDate,
  pickTime,
  makeAppointment,
}: any) => (

  busy ?

  <BusyScreen message='Bitte warten'/>

  :

  <View style={styles.container}>
    <View style={{flex: 1}}>

      <Text
        style={styles.headline}>
        Dein Wunschtermin zur Anprobe
      </Text>

      <View style={styles.appointmentForm}>
        <AppointmentForm
          formValues={formValues}
          formErrors={formErrors}
          pickDate={pickDate}
          pickTime={pickTime}
        />
      </View>

    </View>

    <View style={styles.bottomContainer}>

      <Button
        style={styles.btn}
        onPress={makeAppointment}
        label="Anprobe vereinbaren" />

    </View>


  </View>

)

const styles = StyleSheet.create({

  container: {
    flex: 1,
  },

  headline: {
    fontSize: TEXT_XL,
    fontFamily: UNNA_REG,
    lineHeight: LINE_HEIGHT_XL,
    color: COLOR_BLACK,
    fontWeight: '500',
    marginBottom: 25,
    paddingHorizontal: 24,
  },

  bottomContainer: {
    padding: 24,
  },

})

export default AppointmentScreen

import React from 'react'

import {
  StyleSheet,
  View,
} from 'react-native'

import {
  TEXT_XL,
  UNNA_REG,
  LINE_HEIGHT_XL,
} from '@common/constants'

import Button from '@components/DarkButton.component'

import InfoBox from './InfoBox.component'
import LookList from './LookList.component'
import LookListHeader from './LookListHeader.component'

const ProfileScreen = ({
  changeDressSize,
  changeWeddingDate,
  listType,
  activeLook,
  looks,
  dressSize,
  weddingDate,
  setListType,
  loadLook,
  deleteLook,
  changeLookName,
  makeAppointment,
}: any) => {

  return (

  <View style={styles.container} >

    <InfoBox
      style={styles.infoBox}
      dressSize={dressSize}
      weddingDate={weddingDate}
      onChangeDressSize={changeDressSize}
      onChangeWeddingDate={changeWeddingDate} />

    <LookListHeader
      setListType={setListType} />

    <LookList
      listType={listType}
      items={looks}
      selection={activeLook && [activeLook]}
      onPress={loadLook}
      onEdit={changeLookName}
      onDelete={deleteLook} />

    <Button
      onPress={makeAppointment}
      label="Termin zur Anprobe vereinbaren" />

  </View>

  )

}

const styles = StyleSheet.create({

  container: {
    flex: 1,
    paddingBottom: 24,
    paddingHorizontal: 24,
  },

  header: {
    fontSize: TEXT_XL,
    fontFamily: UNNA_REG,
    lineHEight: LINE_HEIGHT_XL,
    marginBottom: 25,
  },

  infoBox: {
    marginBottom: 25,
  },

})

export default ProfileScreen

import React from 'react'

import { connect } from 'react-redux'

import { State } from '@common/types'

import {
  setFormValue,
} from '@common/actions'

import {
  init,
  reset,
  register,
  pickDate,
} from './RegisterScreen.actions'

import Stack from '@navigation/stack'
import options from './RegisterScreen.options'
import RegisterScreen from './RegisterScreen.component'

import store from '@state/store'

const mapStateToProps = ({
  registerScreen: {
    busy,
    salutations,
    countries,
    formValues,
    formErrors,
    error,
  },
}: State) => ({
  busy,
  salutations,
  countries,
  formValues,
  formErrors,
  error,
})

const mapDispatchToProps = {
  setFormValue,
  register,
  pickDate,
}

export default function() {

  return (
    <Stack.Screen
      name='Register'
      options={options}
      component={connect(mapStateToProps, mapDispatchToProps)(RegisterScreen)}
      listeners={{
        focus: () => store.dispatch(init()),
        beforeRemove: () => store.dispatch(reset()),
      }}
    />
  )

}

import {
  Look,
} from "@screens/Profile/ProfileScreen.types"

export interface LooksScreenState {
  busy: boolean,
  looks: Look[],
  selectedLooks: Look[],
}

export const INIT = 'LooksScreen.init'
export const INIT_SUCCESS = 'LooksScreen.init.success'
export const INIT_FAILURE = 'LooksScreen.init.failure'

export const RESET = 'LooksScreen.reset'

export const TOGGLE_LOOK = 'LooksScreen.toggleLook'

export const APPLY_LOOKS = 'LooksScreen.applyLooks'

export interface InitAction {
  type: typeof INIT,
}

export interface InitSuccessAction {
  type: typeof INIT_SUCCESS,
  looks: Look[],
}

export interface InitFailureAction {
  type: typeof INIT_FAILURE,
  error?: string,
}

export interface ResetAction {
  type: typeof RESET,
}

export interface ToggleLookAction {
  type: typeof TOGGLE_LOOK,
  look: Look,
}

export interface ApplyLooksAction {
  type: typeof APPLY_LOOKS,
}

export type LooksScreenActionType =
  InitAction |
  InitSuccessAction |
  InitFailureAction |
  ResetAction |
  ToggleLookAction |
  ApplyLooksAction

import React from 'react'

import {
  StyleSheet,
  Image,
  Text,
  View,
} from 'react-native'

import {
  TEXT_MD,
  COLOR_BLACK,
  COLOR_GREY,
  MON_600, MON_500
} from '@common/constants'

import Modal from '@components/Modal.component';
import DarkButton from '@components/DarkButton.component';

const ConfirmModal = ({
  visible,
  message,
  acceptLabel,
  dismissLabel,
  confirmModalAccepted,
  confirmModalDismissed,
}: any) => (

  <Modal
    visible={visible}
    onDismiss={confirmModalDismissed}
    content={<>

      <View style={styles.modalInner}>
        <Image
          style={styles.image}
          source={require('@assets/icons/icon-info.svg' /*'@assets/icons/dark/info.png'*/)}
        />
        <Text
          style={styles.headline}>
            {message}
        </Text>
      </View>

    </>}

    footer={
      <>
        <View style={styles.btnContainer}>
          <DarkButton
            style={styles.acceptButton}
            onPress={confirmModalAccepted}
            label={acceptLabel}
          />
          <View style={styles.dismissContainer}>
            <DarkButton
              style={styles.dismissButton}
              onPress={confirmModalDismissed}
              label={dismissLabel}
            />
          </View>
        </View>
    </>}
  />

)

const styles = StyleSheet.create({

  modalInner: {
    width: 250,
    marginHorizontal: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 40,
    flex: 1,
    zIndex: 2,
  },

  image: {
    position: 'absolute',
    left: -40,
    width: 25,
    height: 25,
  },

  headline: {
    fontSize: TEXT_MD,
    lineHeight: 23,
    fontFamily: MON_600,
    width: '100%',
    color: COLOR_BLACK,
    marginLeft: 8,
  },

  dismissContainer: {
    marginLeft: 6
  },

  dismissButton: {
    backgroundColor: 'transparent',
    color: COLOR_GREY,
    fontFamily: MON_500,
    width: 130,
    height: 44,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  acceptButton: {
    width: 130,
    height: 44,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  btnContainer: {
    marginTop: 25,
    flexDirection: 'row',
    paddingBottom: 40,
  },

})

export default ConfirmModal

export default {
  firstName: {
    presence: {
      allowEmpty: false,
      message: 'Bitte Vorname eingeben',
    },
  },
  lastName: {
    presence: {
      allowEmpty: false,
      message: 'Bitte Nachname eingeben',
    },
  },
  street: {
    presence: {
      allowEmpty: false,
      message: 'Bitte Straße eingeben',
    },
  },
  zipcode: {
    presence: {
      allowEmpty: false,
      message: 'Bitte PLZ eingeben',
    },
  },
  city: {
    presence: {
      allowEmpty: false,
      message: 'Bitte Ort eingeben',
    },
  },
  /*
  dressSize: {
    presence: {
      allowEmpty: false,
      message: 'Bitte eingeben',
    },
  },
  weddingDate: {
    presence: {
      allowEmpty: false,
      message: 'Bitte eingeben',
    },
  },
  */
}

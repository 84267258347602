import { call, put, select } from 'redux-saga/effects'

import http from '../../../axios'

import {
  setFormErrors,
} from '@common/actions'

import {
  setError,
  changeEmailSuccess,
  changeEmailFailure,
} from '../ChangeEmailScreen.actions'

import constraints from '../ChangeEmailForm.constraints'

import validateForm from '../../../sagas/validateForm.saga'

import { goBack } from '@navigation/root'

import { showAlertModal } from '@modals/Alert/AlertModal.actions'

export default function* () {

  try {

    const {
      email,
      emailConfirmation,
      password,
    } = yield select(state => state.changeEmailScreen.formValues)

    yield put(setError())

    yield call(validateFormEx, {
      email,
      emailConfirmation,
      password,
    })

    yield call(http.post, '/account/change-email', {
      email,
      emailConfirmation,
      password,
    })

    yield put(changeEmailSuccess())

    yield call(goBack)

    yield put(showAlertModal('Ihre eMail wurde geändert!', 'Ok'))

  }
  catch (err) {

    yield call(handleError, err)

  }

}

function* validateFormEx(form) {

  yield call(validateForm, 'changeEmail', constraints, form)

  const { email, emailConfirmation } = form;

  if (email !== emailConfirmation) {

    yield put(setFormErrors('changeEmail', {
      emailConfirmation: 'Die eMails stimmen nicht überein',
    }))

    throw Error("Validation failed for 'changeEmail' form")

  }

}

function* handleError(err) {

  yield call(console.error, err)

  yield put(changeEmailFailure(err))

  if (err.response && err.response.data && err.response.data.errors) {

    for (const error of err.response.data.errors) {

      if (error.status === '400') {

        if (error.code === 'VIOLATION::CUSTOMER_EMAIL_NOT_UNIQUE') {

          yield put(setError('Die eMail-Adresse ist bereits vergeben!'))

        }
        else if (error.code === 'VIOLATION::CUSTOMER_PASSWORD_NOT_CORRECT') {

          yield put(setError('Das Passwort ist nicht korrekt!'))

        }
        else {

          // TODO display error properly

          yield put(setError(error.detail))

        }

        break

      }

    }

  }

}

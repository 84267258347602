import React from 'react'

import {
  StyleSheet,
  StyleProp,
  Platform,
  Pressable,
  PressableStateCallbackType,
  View,
  ViewStyle,
} from 'react-native'

import {
  COLOR_GREY_LIGHTER,
  RADIUS_17,
  TEXT_MD,
  COLOR_BLACK,
  MON_600,
  FALLBACK_PRODUCT_COLOR,
} from '@common/constants'

import {
  ProductVariant,
  ViewType,
} from '@screens/Configurator/ConfiguratorScreen.types'

import Text from '@components/Text.component'
import IconButton from '@components/IconButton.component'

import Preview from '@screens/Configurator/Configurator/Preview.component'

const LookListItemRow = ({
  look,
  active,
  onPress,
  onEdit,
  onDelete,
}: any) => (

  <Pressable
    onPress={onPress}
    style={({ pressed }: PressableStateCallbackType): StyleProp<ViewStyle> => ([
      styles.container,
      active && styles.containerActive,
      pressed && styles.containerPressed,
      Platform.OS === 'web' && { cursor: 'pointer' } ,
    ])}>
    <Preview
      style={styles.image}
      layers={[1,2,3,4]}
      selectedItems={look.items}
      scale={0.125}
      viewType={ViewType.FRONT}/>

    <View
      style={{
        flex: 1,
        margin: 16,
      }}>

      <Text
        style={{
          fontFamily: MON_600,
          color: COLOR_BLACK,
          marginBottom: TEXT_MD,
        }}>
        {look.name}
      </Text>

      {look.items.map((item: ProductVariant) => (

        <View
          key={item.id}
          style={{
            marginBottom: 2,
            flexDirection: 'row',
            alignItems: 'center',
          }}>

          <View
            style={{
              width: 14,
              height: 14,
              marginRight: 8,
              borderRadius: 4,
              borderColor: COLOR_GREY_LIGHTER,
              borderWidth: 1,
              backgroundColor: item.color ? item.color.colorHexCode : FALLBACK_PRODUCT_COLOR,
            }}/>

          <Text>
            {item.product.name}
          </Text>

        </View>

      ))}

    </View>

    <View
      style={styles.buttons}>

      {onEdit &&
      <IconButton
        onPress={onEdit}
        size={14}
        source={require('@assets/icons/icon-edit.svg' /*'@assets/icons/dark/edit.png'*/)}/>
      }

      {onDelete &&
      <IconButton
        onPress={onDelete}
        size={18}
        source={require('@assets/icons/icon-delete.svg' /*'@assets/icons/dark/delete.png'*/)}/>
      }

    </View>

  </Pressable>

)

const styles = StyleSheet.create({

  container: {
    overflow: 'hidden',
    height: 162,
    borderRadius: RADIUS_17,
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    marginBottom: 25,
  },

  containerPressed: {
    backgroundColor: COLOR_GREY_LIGHTER,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.12,
    shadowRadius: 10,
  },

  containerActive: {
    backgroundColor: COLOR_GREY_LIGHTER,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.12,
    shadowRadius: 10,
  },


  image: {
    width: 128,
  },

  buttons: {
    justifyContent: 'space-between'
  },

})

export default LookListItemRow

import { call, put, select } from 'redux-saga/effects'

import {
  initSuccess,
  initFailure,
} from '../StoresScreen.actions'

import fetchStores from './fetchStores.saga'

export default function* (): any {

  try {

    const {
      formValues: {
        location,
      },
      stores,
    } = yield select(state => state.storesScreen)

    if (!stores.length) {

      const fetchedStores = yield call(fetchStores, location)

      yield put(initSuccess(fetchedStores))

    } else {

      yield put(initSuccess(stores))

    }

  }
  catch (err) {

    yield call(console.error, err)

    yield put(initFailure(err))

  }

}

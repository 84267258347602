import { DateObject } from 'react-native-calendars';

export interface DateModalState {
  visible: boolean,
  message: string,
  defaultDate: string
}

export const SHOW_DATE_MODAL = 'SHOW_DATE_MODAL'
export const DATE_MODAL_ACCEPTED = 'DATE_MODAL_ACCEPTED'
export const DATE_MODAL_DISMISSED = 'DATE_MODAL_DISMISSED'

export interface ShowDateModalAction {
  type: typeof SHOW_DATE_MODAL,
  message: string,
  defaultDate: string,
}

export interface DateModalAcceptedAction {
  type: typeof DATE_MODAL_ACCEPTED,
  date: DateObject,
}

export interface DateModalDismissedAction {
  type: typeof DATE_MODAL_DISMISSED,
}

export type DateModalActionType =
  ShowDateModalAction |
  DateModalAcceptedAction |
  DateModalDismissedAction

import produce from 'immer'

import {
  CommonActionType,
  ProfileState,
  INIT_SUCCESS,
} from '@common/types'

import {
  LoginScreenActionType,
  LOGIN_SUCCESS,
} from '@screens/Login/LoginScreen.types'
import moment from 'moment'

import {
  ProfileScreenActionType,
  CHANGE_DRESS_SIZE_SUCCESS,
  CHANGE_WEDDING_DATE_SUCCESS,
} from '@screens/Profile/ProfileScreen.types'

import {
  ChangeProfileScreenActionType,
  CHANGE_PROFILE_SUCCESS,
} from '@screens/ChangeProfile/ChangeProfileScreen.types'

const INITIAL_STATE: ProfileState = {
  salutationId            : '',
  firstName               : '',
  lastName                : '',
  email                   : '',
  defaultBillingAddressId : '',
  street                  : '',
  zipcode                 : '',
  city                    : '',
  countryId               : '',
  phoneNumber             : '',
  dressSize               : '',
  weddingDate             : '',
}

export default produce((
  draft: ProfileState,
  action: CommonActionType|LoginScreenActionType|ProfileScreenActionType|ChangeProfileScreenActionType
) => {

  switch (action.type) {

    case INIT_SUCCESS:
    case LOGIN_SUCCESS:
      if (action.customer) {
        draft.salutationId = action.customer.salutationId
        draft.firstName = action.customer.firstName
        draft.lastName = action.customer.lastName
        draft.email = action.customer.email
        draft.defaultBillingAddressId = action.customer.defaultBillingAddressId
        draft.street = action.customer.defaultBillingAddress.street
        draft.zipcode = action.customer.defaultBillingAddress.zipcode
        draft.city = action.customer.defaultBillingAddress.city
        draft.countryId = action.customer.defaultBillingAddress.countryId
        draft.phoneNumber = action.customer.defaultBillingAddress.phoneNumber
        if (action.customer.customFields) {
          draft.dressSize = action.customer.customFields.customer_info_dress_size
          if(action.customer.customFields.customer_info_wedding_date){
            draft.weddingDate = moment(action.customer.customFields.customer_info_wedding_date).format('DD.MM.YYYY')
          } else {
            draft.weddingDate = ''
          }
        }
      }
      break

    case CHANGE_DRESS_SIZE_SUCCESS:
      draft.dressSize = action.dressSize
      break

    case CHANGE_WEDDING_DATE_SUCCESS:
      draft.weddingDate = action.weddingDate
      break

    case CHANGE_PROFILE_SUCCESS:
      draft.salutationId = action.salutationId
      draft.firstName = action.firstName
      draft.lastName = action.lastName
      draft.street = action.street
      draft.zipcode = action.zipcode
      draft.city = action.city
      draft.countryId = action.countryId
      draft.phoneNumber = action.phoneNumber
      break

  }

}, INITIAL_STATE)

import Constants from 'expo-constants';

import { call, put } from '@redux-saga/core/effects'

import AsyncStorage from '@react-native-async-storage/async-storage'

import http, { initAxios } from '../axios'

import {
  initSuccess,
  initFailure,
} from '@common/actions'

export default function* (): any {
 
  try {

    const {
      apiUrl,
      apiKey,
      storefrontUrl,
    } = Constants.manifest?.extra || {}

    let contextToken: string = yield call(AsyncStorage.getItem, 'login.contextToken')

    yield call(initAxios, apiUrl, apiKey, contextToken)
    
    const customer = yield call(fetchCustomer)

    yield put(initSuccess(
      apiUrl,
      apiKey,
      storefrontUrl,
      customer))
    
  }
  catch (err) {

    yield call(console.error, err)

    yield put(initFailure(err))

  }
}

function* fetchCustomer() {

  try {

    const { data: customerData } = yield call(http.post, '/account/customer', {
      associations: {
        defaultBillingAddress: {},
      },
    })

    return customerData

  }
  catch (err) {

  }

}

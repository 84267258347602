
import {
  Look,
} from "@screens/Profile/ProfileScreen.types"

export interface Product {
  id: string,
  name: string,
  price: number,
  images: string[][],
  shape: string,
  enabled: boolean,
  variants?: ProductVariant[],
}

export interface ProductVariant {
  id: string,
  product: Product,
  price: number,
  color: any,
  images: string[],
  shape: string,
}

export enum ViewType {
  FRONT,
  BACK,
}

export interface ConfiguratorScreenState {
  layers: string[],
  products: Product[][],
  browserVisible: boolean,
  browserLoading: boolean[],
  selectedLayer: number,
  selectedItems: ProductVariant[],
  look?: Look,
  price: number,
  viewType: ViewType,
}

export const INIT = 'ConfiguratorScreen.init'
export const INIT_SUCCESS = 'ConfiguratorScreen.init.success'
export const INIT_FAILURE = 'ConfiguratorScreen.init.failure'

export const FETCH_PRODUCTS_ATTEMPT = 'ConfiguratorScreen.fetchProducts.attempt'
export const FETCH_PRODUCTS_SUCCESS = 'ConfiguratorScreen.fetchProducts.success'
export const FETCH_PRODUCTS_FAILURE = 'ConfiguratorScreen.fetchProducts.failure'

export const SET_LAYER = 'ConfiguratorScreen.setLayer'

export const SET_BROWSER_VISIBLE = 'ConfiguratorScreen.setBrowserVisible'

export const SET_ITEM = 'ConfiguratorScreen.setItem'

export const SET_LOOK = 'ConfiguratorScreen.setLook'

export const SET_VIEW_TYPE = 'ConfiguratorScreen.setViewType'

export const NAVIGATE_TO_PROFILE = 'ConfiguratorScreen.navigateToProfile'

export const STORE_LOOK = 'ConfiguratorScreen.storeLook'
export const STORE_LOOK_SUCCESS = 'ConfiguratorScreen.storeLook.success'
export const STORE_LOOK_FAILURE = 'ConfiguratorScreen.storeLook.failure'

export const SHARE_LOOK = 'ConfiguratorScreen.shareLook'
export const SHARE_LOOK_SUCCESS = 'ConfiguratorScreen.shareLook.success'
export const SHARE_LOOK_FAILURE = 'ConfiguratorScreen.shareLook.failure'

export interface InitAction {
  type: typeof INIT,
}

export interface InitSuccessAction {
  type: typeof INIT_SUCCESS,
}

export interface InitFailureAction {
  type: typeof INIT_FAILURE,
  error: any,
}

export interface FetchProductsAttemptAction {
  type: typeof FETCH_PRODUCTS_ATTEMPT,
  layer: number,
}

export interface FetchProductsSuccessAction {
  type: typeof FETCH_PRODUCTS_SUCCESS,
  layer: number,
  products: Product[],
  dressSize: string,
}

export interface FetchProductsFailureAction {
  type: typeof FETCH_PRODUCTS_FAILURE,
  layer: number,
  error: any,
}

export interface SetLayerAction {
  type: typeof SET_LAYER,
  layer: number,
}

export interface SetBrowserVisibleAction {
  type: typeof SET_BROWSER_VISIBLE,
  visible: boolean,
}

export interface SetItemAction {
  type: typeof SET_ITEM,
  item: ProductVariant,
}

export interface SetLookAction {
  type: typeof SET_LOOK,
  look: Look,
}

export interface SetViewTypeAction {
  type: typeof SET_VIEW_TYPE,
  viewType: ViewType,
}

export interface NavigateToProfileAction {
  type: typeof NAVIGATE_TO_PROFILE,
}

export interface StoreLookAction {
  type: typeof STORE_LOOK,
}

export interface StoreLookSuccessAction {
  type: typeof STORE_LOOK_SUCCESS,
  look: Look,
}

export interface StoreLookFailureAction {
  type: typeof STORE_LOOK_FAILURE,
  error: any,
}

export interface ShareLookAction {
  type: typeof SHARE_LOOK,
}

export interface ShareLookSuccessAction {
  type: typeof SHARE_LOOK_SUCCESS,
}

export interface ShareLookFailureAction {
  type: typeof SHARE_LOOK_FAILURE,
  error: any,
}

export type ConfiguratorScreenActionType =
  InitAction |
  InitSuccessAction |
  InitFailureAction |
  FetchProductsAttemptAction |
  FetchProductsSuccessAction |
  FetchProductsFailureAction |
  SetLayerAction |
  SetBrowserVisibleAction |
  SetItemAction |
  SetLookAction |
  SetViewTypeAction |
  NavigateToProfileAction |
  StoreLookAction |
  StoreLookSuccessAction |
  StoreLookFailureAction |
  ShareLookAction |
  ShareLookSuccessAction |
  ShareLookFailureAction


import { connect } from 'react-redux'

import { State } from '@common/types'

import ConfirmModal from './ConfirmModal.component'

import {
  confirmModalAccepted,
  confirmModalDismissed,
} from '@modals/Confirm/ConfirmModal.actions';

const mapStateToProps = ({
  confirmModal: {
    visible,
    message,
    acceptLabel,
    dismissLabel,
  }
}: State) => ({
  visible,
  message,
  acceptLabel,
  dismissLabel,
})

const mapDispatchToProps = {
  confirmModalAccepted,
  confirmModalDismissed,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal)

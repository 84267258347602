import React from 'react'

import {
  StyleSheet,
  ScrollView,
  View,
} from 'react-native'

import Text from '@components/Text.component'
import Button from '@components/DarkButton.component'
import Message from '@components/Message.component'
import BusyScreen from '@components/BusyScreen.component'

import PasswordRecoveryForm from './PasswordRecoveryForm.component'
import {COLOR_GREY, TEXT_MD, MON_500} from '../../common/constants';

const PasswordRecoveryScreen = ({
  route,
  busy,
  formValues,
  formErrors,
  error,
  setFormValue,
  requestPasswordRecoveryEmail,
  redeemPasswordRecoveryToken,
  changePassword,
}: any) => (

  busy ?

  <BusyScreen message='Bitte warten'/>

  :

  <ScrollView
    style={styles.container}>

    {!!error &&
    <Message message={error} />
    }

    {route.params.step === 1 && <>

    <Text style={styles.disclaimer}>
      Um ein neues Passwort zu vergeben, senden wir Dir einen Code an Deine E-Mail-Adresse.
    </Text>

    </>}

    {route.params.step === 2 && <>

    <Text style={styles.disclaimer}>
      Hier kannst Du ein neues Passwort vergeben, gib dazu bitte den Code aus der E-Mail ein.
    </Text>

    </>}

    <PasswordRecoveryForm
      step={route.params.step}
      formName="passwordRecovery"
      formValues={formValues}
      formErrors={formErrors}
      setFormValue={setFormValue}
      submit={() => {

        if (route.params.step === 1)
          requestPasswordRecoveryEmail()
        else
        if (route.params.step === 2)
          changePassword()

      }}/>

    <View
      style={styles.bottomContainer}>

      {route.params.step === 1 && <>

      <Button
        onPress={requestPasswordRecoveryEmail}
        label="E-Mail anfordern" />

      <Button
        style={styles.label}
        onPress={redeemPasswordRecoveryToken}
        label="Code eingeben" />

      </>}

      {route.params.step === 2 && <>

      <Button
        onPress={changePassword}
        label="Passwort ändern" />

      </>}

    </View>

  </ScrollView>

)

const styles = StyleSheet.create({

  container: {
    flex: 1,
  },

  disclaimer: {
    paddingHorizontal: 24,
    color: COLOR_GREY,
    fontSize: TEXT_MD,
    lineHeight: 23,
    fontWeight: '500',
    fontFamily: MON_500,
    marginBottom: 24
  },

  bottomContainer: {
    padding: 24,
  },

  label: {
    backgroundColor: 'transparent',
    fontSize: 13,
    fontWeight: '500',
    color: '#61646C'
  },

})

export default PasswordRecoveryScreen

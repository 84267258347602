
import {
  StoresScreenActionType as ActionType,
  Store,
  INIT,
  INIT_SUCCESS,
  INIT_FAILURE,
  RESET,
  FIND_STORES,
  FIND_STORES_SUCCESS,
  FIND_STORES_FAILURE,
  SET_STORE,
  APPLY_STORE,
} from './StoresScreen.types'

export const init = (): ActionType => ({
  type: INIT,
})

export const initSuccess = (stores: Store[]): ActionType => ({
  type: INIT_SUCCESS,
  stores,
})

export const initFailure = (error: any): ActionType => ({
  type: INIT_FAILURE,
  error,
})

export const reset = (): ActionType => ({
  type: RESET,
})

export const findStores = (): ActionType => ({
  type: FIND_STORES,
})

export const findStoresSuccess = (stores: Store[]): ActionType => ({
  type: FIND_STORES_SUCCESS,
  stores,
})

export const findStoresFailure = (error: any): ActionType => ({
  type: FIND_STORES_FAILURE,
  error,
})

export const setStore = (store: Store): ActionType => ({
  type: SET_STORE,
  store,
})

export const applyStore = (): ActionType => ({
  type: APPLY_STORE,
})

import { call, put, select } from 'redux-saga/effects'

import http from '../../../axios'

import { Look } from '@screens/Profile/ProfileScreen.types'

import {
  makeAppointmentSuccess,
  makeAppointmentFailure,
} from '../AppointmentScreen.actions'

import constraints from '../AppointmentForm.constraints'

import validateForm from '../../../sagas/validateForm.saga'

import { reset } from '@navigation/root'

export default function* () {

  try {

    const {
      looksScreen: {
        selectedLooks
      },
      storesScreen: {
        selectedStore,
      },
      appointmentScreen: {
        formValues: {
          date,
          time,
        },
      },
    } = yield select(state => state)

    yield call(validateForm, 'appointment', constraints, {
      date,
      time,
    })

    yield call(http.post, '/account/make-appointment', {
      storeId: selectedStore.id,
      date,
      time,
      looks: selectedLooks.map((look: Look) => look.id),
    })

    yield put(makeAppointmentSuccess())

    yield call(reset, {
      index: 0,
      routes: [{ name: 'Finish' }],
    })

  }
  catch (err) {

    yield call(console.error, err)

    yield put(makeAppointmentFailure(err))

  }

}

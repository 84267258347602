import React from 'react'

import {
  StyleSheet,
  Pressable,
  PressableStateCallbackType,
  View,
} from 'react-native'

import {
  COLOR_BLACK,
} from '@common/constants'


import IconButton from '@components/IconButton.component';

const Modal = ({
  visible,
  onDismiss,
  content,
  footer,
  style
}: any) => (

  visible && <>

  <Pressable
    style={styles.backdrop}
    onPress={onDismiss}/>

  <View
    style={[
      styles.container,
      style,
    ]}>

    <IconButton
      onPress={onDismiss}
      size={12}
      style={({ pressed }: PressableStateCallbackType) => ({
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 10,
      })}
      source={require('@assets/icons/icon-close.svg' /*'@assets/icons/dark/close.png'*/)}/>

    <View>

      <View>
        {content}
      </View>

      <View style={styles.footerContainer}>
        {footer}
      </View>

    </View>

  </View>

  </>

)

const styles = StyleSheet.create({

  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: COLOR_BLACK,
    opacity: 0.3,
  },

  container: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    backgroundColor: '#fff',
    borderTopLeftRadius: 22,
    borderTopRightRadius: 22,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.15,
    shadowRadius: 20,
  },

  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 8,
    zIndex: 10,
  },

  footerContainer: {
    marginTop: 8,
    justifyContent: 'flex-start',
    marginHorizontal: 'auto',
  },

})

export default Modal

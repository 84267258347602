import { call, put, select } from 'redux-saga/effects'

import { Linking } from 'react-native'

import http from '../../../axios'

import { Look } from '@screens/Profile/ProfileScreen.types'

import { showAlertModal } from '@modals/Alert/AlertModal.actions'

//import { navigate } from '@navigation/root'

export default function* () {

  try {

    const {
      looksScreen: {
        selectedLooks,
      },
      storesScreen: {
        selectedStore,
      },
    } = yield select(state => state)

    if (!selectedStore) {

      yield put(showAlertModal('Bitte wähle einen Store aus!', 'Ok'))

    }
    else {

      yield call(http.post, '/account/make-appointment', {
        storeId: selectedStore.id,
        looks: selectedLooks.map((look: Look) => look.id),
      })

      const urls = {
        'a57f0a9630694469b18791d5391b4c3c': 'https://www.etermin.net/lemoos-store',
        'a5b0db7ec13048a7a4ead66dc42f184b': 'https://www.etermin.net/lds-duesseldorf',
      }

      yield call(Linking.openURL, urls[selectedStore.id])

      //yield call(navigate, 'Appointment')

    }

  }
  catch (err) {

    yield call(console.error, err)

  }

}


import produce from 'immer'

import {
  CommonActionType,
  SET_FORM_VALUE,
  SET_FORM_ERRORS,
} from '@common/types'

import {
  ChangeEmailScreenState,
  ChangeEmailScreenActionType,
  SET_ERROR,
  CHANGE_EMAIL,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_EMAIL_FAILURE,
  RESET,
} from './ChangeEmailScreen.types'

const INITIAL_STATE: ChangeEmailScreenState = {
  busy: false,
  formValues: {
    email: '',
    emailConfirmation: '',
    password: '',
  },
  formErrors: {
  },
}

export default produce((
  draft: ChangeEmailScreenState,
  action: CommonActionType|ChangeEmailScreenActionType
) => {

  switch (action.type) {

    case RESET:
      return INITIAL_STATE

    case SET_FORM_VALUE:
      if (action.form === 'changeEmail') {
        draft.formValues[action.key] = action.val
      }
      break

    case SET_FORM_ERRORS:
      if (action.form === 'changeEmail') {
        draft.formErrors = action.errors
      }
      break

    case SET_ERROR:
      draft.error = action.error
      break

    case CHANGE_EMAIL:
      draft.busy = true
      break

    case CHANGE_EMAIL_SUCCESS:
      draft.busy = false
      break

    case CHANGE_EMAIL_FAILURE:
      draft.busy = false
      break

  }

}, INITIAL_STATE)

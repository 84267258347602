
import {
  ProfileScreenActionType as ActionType,
  ListType,
  Look,
  INIT,
  INIT_SUCCESS,
  INIT_FAILURE,
  CHANGE_DRESS_SIZE,
  CHANGE_DRESS_SIZE_SUCCESS,
  CHANGE_DRESS_SIZE_FAILURE,
  CHANGE_WEDDING_DATE,
  CHANGE_WEDDING_DATE_SUCCESS,
  CHANGE_WEDDING_DATE_FAILURE,
  SET_LIST_TYPE,
  LOAD_LOOK,
  CHANGE_LOOK_NAME,
  CHANGE_LOOK_NAME_SUCCESS,
  CHANGE_LOOK_NAME_FAILURE,
  DELETE_LOOK,
  DELETE_LOOK_SUCCESS,
  DELETE_LOOK_FAILURE,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from './ProfileScreen.types'

export const init = (): ActionType => ({
  type: INIT,
})

export const initSuccess = (looks: Look[], dressSize: string): ActionType => ({
  type: INIT_SUCCESS,
  looks,
  dressSize,
})

export const initFailure = (error: any): ActionType => ({
  type: INIT_FAILURE,
  error,
})

export const changeDressSize = (): ActionType => ({
  type: CHANGE_DRESS_SIZE,
})

export const changeDressSizeSuccess = (dressSize: string): ActionType => ({
  type: CHANGE_DRESS_SIZE_SUCCESS,
  dressSize,
})

export const changeDressSizeFailure = (error: any): ActionType => ({
  type: CHANGE_DRESS_SIZE_FAILURE,
  error,
})

export const changeWeddingDate = (): ActionType => ({
  type: CHANGE_WEDDING_DATE,
})

export const changeWeddingDateSuccess = (weddingDate: string): ActionType => ({
  type: CHANGE_WEDDING_DATE_SUCCESS,
  weddingDate,
})

export const changeWeddingDateFailure = (error: any): ActionType => ({
  type: CHANGE_WEDDING_DATE_FAILURE,
  error,
})

export const setListType = (listType: ListType): ActionType => ({
  type: SET_LIST_TYPE,
  listType,
})

export const loadLook = (look: Look): ActionType => ({
  type: LOAD_LOOK,
  look,
})

export const logout = (): ActionType => ({
  type: LOGOUT,
})

export const logoutSuccess = (): ActionType => ({
  type: LOGOUT_SUCCESS,
})

export const logoutFailure = (error: any): ActionType => ({
  type: LOGOUT_FAILURE,
  error,
})

export const deleteLook = (look: Look): ActionType => ({
  type: DELETE_LOOK,
  look,
})

export const deleteLookSuccess = (lookId: string): ActionType => ({
  type: DELETE_LOOK_SUCCESS,
  lookId,
})

export const deleteLookFailure = (error: any): ActionType => ({
  type: DELETE_LOOK_FAILURE,
  error,
})

export const changeLookName = (look: Look): ActionType => ({
  type: CHANGE_LOOK_NAME,
  look,
})

export const changeLookNameSuccess = (lookId: string, name: string): ActionType => ({
  type: CHANGE_LOOK_NAME_SUCCESS,
  lookId,
  name,
})

export const changeLookNameFailure = (error: any): ActionType => ({
  type: CHANGE_LOOK_NAME_FAILURE,
  error,
})

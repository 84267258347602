
import { takeLatest } from 'redux-saga/effects'

import {
  CHANGE_PASSWORD, REDEEM_PASSWORD_RECOVERY_TOKEN, REQUEST_PASSWORD_RECOVERY_EMAIL,
} from './PasswordRecoveryScreen.types'

import requestPasswordRecoveryEmail from './sagas/requestPasswordRecoveryEmail.saga'
import redeemPasswordRecoveryToken from './sagas/redeemPasswordRecoveryToken.saga'
import changePassword from './sagas/changePassword.saga'

export default function* () {

  yield takeLatest(REQUEST_PASSWORD_RECOVERY_EMAIL, requestPasswordRecoveryEmail)

  yield takeLatest(REDEEM_PASSWORD_RECOVERY_TOKEN, redeemPasswordRecoveryToken)

  yield takeLatest(CHANGE_PASSWORD, changePassword)

}

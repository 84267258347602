import produce from 'immer'

import {
  FinishScreenState,
  /*
  StoresScreenActionType,
  SET_STORE,
  */
} from './FinishScreen.types'

const INITIAL_STATE: FinishScreenState = {

}

export default produce((draft: FinishScreenState, action: object) => {

  /*
  switch (action.type) {

    case SET_STORE:
      draft.selectedStore = action.store
      break

  }
  */

}, INITIAL_STATE)

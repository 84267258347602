
import produce from 'immer'

import {
  CommonActionType,
  SET_FORM_VALUE,
  SET_FORM_ERRORS,
} from '@common/types'

import {
  RegisterScreenState,
  RegisterScreenActionType,
  SET_ERROR,
  INIT,
  INIT_SUCCESS,
  INIT_FAILURE,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  RESET,
} from './RegisterScreen.types'
import moment from "moment";

const INITIAL_STATE: RegisterScreenState = {
  busy: true,
  salutations: [],
  countries: [],
  formValues: {
    salutationId    : '377c7e0aaa9b474a8b0de7f30b15dba9',
    firstName       : '',
    lastName        : '',
    email           : '',
    password        : '',
    passwordConfirm : '',
    street          : '',
    zipcode         : '',
    city            : '',
    countryId       : '4eeaa1a1c0174dce8ce4e251d2efb05d',
    dressSize       : '',
    weddingDate     : '',
    phoneNumber           : '',
  },
  formErrors: {
  },
}

export default produce((
  draft: RegisterScreenState,
  action: CommonActionType|RegisterScreenActionType
) => {

  switch (action.type) {

    case RESET:
      return INITIAL_STATE

    case INIT:
      draft.busy = true
      break

    case INIT_SUCCESS:
      draft.busy = false
      draft.salutations = action.salutations
      draft.countries = action.countries
      draft.formValues.salutationId = action.salutations[0].id
      draft.formValues.countryId = action.countries[0].id
      break

    case INIT_FAILURE:
      draft.busy = false
      break

    case SET_FORM_VALUE:
      if (action.form === 'register') {
        if (action.key === 'weddingDate') {
          draft.formValues['weddingDate'] = moment(action.val.dateString).format('DD.MM.YYYY')
        } else{
          draft.formValues[action.key] = action.val
        }
      }
      break

    case SET_FORM_ERRORS:
      if (action.form === 'register') {
        draft.formErrors = action.errors
      }
      break

    case SET_ERROR:
      draft.error = action.error
      break

    case REGISTER:
      draft.busy = true
      break

    case REGISTER_SUCCESS:
      return INITIAL_STATE

    case REGISTER_FAILURE:
      draft.busy = false
      break

  }

}, INITIAL_STATE)

import React from 'react'

import {
  StyleSheet,
} from 'react-native'

import ResetFlatList from '@components/ResetFlatList.component'

import StoreListItem from './StoreListItem.component'

const StoreList = ({
   style,
   stores,
   selectedStore,
   setStore,
 }: any) => (

  <ResetFlatList
    style={[
      styles.container,
      style,
    ]}
    contentContainerStyle={styles.contentContainer}
    showsVerticalScrollIndicator={false}
    data={stores}
    keyExtractor={(item) => item.id}
    renderItem={({ item }) => (

      <StoreListItem
        item={item}
        onPress={() => setStore(item)}
        selected={selectedStore && selectedStore.id == item.id}/>

    )}
    selectedStore={selectedStore}/>

)

const styles = StyleSheet.create({

  container: {
    borderRadius: 17,
    backgroundColor: 'white',
  },

  contentContainer: {
    paddingTop: 25,
  },
})

export default StoreList

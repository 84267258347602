import React from 'react'

import {
  StyleSheet,
  View,
} from 'react-native'

import {
  TEXT_13,
  MON_500,
} from "../../common/constants";

import Text from "@components/Text.component";
import TextInput from '@components/TextInput.component'
import SecureInput from '@components/SecureInput.component'

const ChangeEmailForm = ({
  formName,
  formValues,
  formErrors,
  setFormValue,
  submit,
}: any) => (

  <View
    style={styles.container}>

    <TextInput
      label="Neue E-Mail:*"
      error={formErrors.email}
      inputProps={{
        value: formValues.email,
        onChangeText: (email: string) => setFormValue(formName, 'email', email),
        onSubmitEditing: submit,
      }}/>

    <TextInput
      label="Neue E-Mail bestätigen:*"
      error={formErrors.emailConfirmation}
      inputProps={{
        value: formValues.emailConfirmation,
        onChangeText: (emailConfirmation: string) => setFormValue(formName, 'emailConfirmation', emailConfirmation),
        onSubmitEditing: submit,
      }}/>

    <SecureInput
      label="Passwort:*"
      error={formErrors.password}
      inputProps={{
        value: formValues.password,
        onChangeText: (password: string) => setFormValue(formName, 'password', password),
        onSubmitEditing: submit,
      }}/>

    <Text style={styles.pflicht}>
      *Pflichtfelder
    </Text>

  </View>

)

const styles = StyleSheet.create({

  container: {
    flex: 1,
    paddingHorizontal: 24,
  },

  pflicht: {
    fontSize: TEXT_13,
    fontFamily: MON_500,
    color: 'rgb(97, 100, 108)',
    marginTop: 16,
  },

})

export default ChangeEmailForm

import React from 'react'

import {
  StyleSheet,
  StyleProp,
  Platform,
  Pressable,
  PressableStateCallbackType,
  View,
  ViewStyle,
} from 'react-native'

import {
  TEXT_MD,
  COLOR_GREY,
  COLOR_BLACK,
  COLOR_GREY_LIGHTER,
  MON_500
} from '@common/constants'

import IconButton from '@components/IconButton.component'
import Text from '@components/Text.component'

const StoreListItem = ({
   item,
   onPress,
   selected,
 }: any) => (

  <Pressable
    onPress={onPress}
    style={({ pressed }: PressableStateCallbackType): StyleProp<ViewStyle> => ([
      styles.item,
      pressed && styles.itemPressed,
      selected && styles.itemSelected,
      Platform.OS === 'web' && { cursor: 'pointer' } ,
    ])}
    children={() => (

      <View
        style={styles.detail}>
        {selected &&
        <View style={styles.blackLine}>

        </View>
        }
        <View
          style={styles.detailInner}>
          <Text style={styles.primaryText} >
            {item.name}
          </Text>
          <Text style={styles.street} >
            {item.street}
          </Text>
        </View>
        <View style={styles.place}>
          <IconButton
            onPress={() => {}}
            source={require('@assets/icons/icon-pin.svg' /*'@assets/icons/dark/pin.png'*/)}
          />
          <Text
            style={styles.km}>
            {item.distance} km
          </Text>
        </View>

      </View>

    )}/>

)

const styles = StyleSheet.create({

  item: {
    paddingHorizontal: 25,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  detail: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },

  detailInner: {
    paddingVertical: 16,
    flex: 1,
  },

  blackLine: {
    position: 'absolute',
    width: 4,
    height: '100%',
    top: 0,
    left: -25,
    bottom: 0,
    backgroundColor: COLOR_BLACK,
    zIndex: 1
  },

  itemPressed: {
    backgroundColor: COLOR_GREY_LIGHTER,
    borderRadius: 0,
  },

  itemSelected: {
    backgroundColor: COLOR_GREY_LIGHTER,
    borderRadius: 0,
  },

  primaryText: {
    fontFamily: MON_500,
    color: COLOR_BLACK,
    fontSize: TEXT_MD,
  },

  street: {
    marginTop: 8,
    color: COLOR_GREY,
    fontSize: TEXT_MD,
    lineHeight: 23,
  },

  place: {
    flex: 1,
    maxWidth: '15%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  km: {
    fontSize: 9,
    fontFamily: MON_500,
    color: '#61646C'
  }

})

export default StoreListItem

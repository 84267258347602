
import {
  Look,
} from '@screens/Profile/ProfileScreen.types'

import {
  LooksScreenActionType as ActionType,
  INIT,
  INIT_SUCCESS,
  INIT_FAILURE,
  RESET,
  TOGGLE_LOOK,
  APPLY_LOOKS,
} from './LooksScreen.types'

export const init = (): ActionType => ({
  type: INIT,
})

export const initSuccess = (looks: Look[]): ActionType => ({
  type: INIT_SUCCESS,
  looks,
})

export const initFailure = (error: any): ActionType => ({
  type: INIT_FAILURE,
  error,
})

export const reset = (): ActionType => ({
  type: RESET,
})

export const toggleLook = (look: Look): ActionType => ({
  type: TOGGLE_LOOK,
  look,
})

export const applyLooks = (): ActionType => ({
  type: APPLY_LOOKS,
})

import produce from 'immer'

import {
  TimeModalState,
  TimeModalActionType,
  SHOW_TIME_MODAL,
  TIME_MODAL_ACCEPTED,
  TIME_MODAL_DISMISSED,
} from './TimeModal.types'

const INITIAL_STATE: TimeModalState = {
  visible: false,
  message: 'message',
  times: [
    '8:30', '9:00', '10:00',
    '10:30', '11:00', '12:00',
    '14:00', '15:30', '16:00',
    '17:00', '17:30', '18:00',
    '18:30', '19:00',
  ],
  selectedTime: '',
}

export default produce((draft: TimeModalState, action: TimeModalActionType) => {

  switch (action.type) {

    case SHOW_TIME_MODAL:
      draft.visible = true
      draft.message = action.message
      draft.selectedTime = action.time
      break

    case TIME_MODAL_ACCEPTED:
      draft.visible = false
      break

    case TIME_MODAL_DISMISSED:
      draft.visible = false
      break

  }

}, INITIAL_STATE)

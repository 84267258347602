
import { takeLatest, call, put } from 'redux-saga/effects'

import {
  INIT,
  CHANGE_DRESS_SIZE,
  CHANGE_WEDDING_DATE,
  LOAD_LOOK,
  CHANGE_LOOK_NAME,
  DELETE_LOOK,
  LOGOUT,
} from './ProfileScreen.types'

import init from './sagas/init.saga'
import changeDressSize from './sagas/changeDressSize.saga'
import changeWeddingDate from './sagas/changeWeddingDate.saga'
import logout from './sagas/logout.saga'
import loadLook from './sagas/loadLook.saga'
import changeLookName from './sagas/changeLookName.saga'
import deleteLook from './sagas/deleteLook.saga'

export default function* () {

  yield takeLatest(INIT, init)

  yield takeLatest(CHANGE_DRESS_SIZE, changeDressSize)

  yield takeLatest(CHANGE_WEDDING_DATE, changeWeddingDate)

  yield takeLatest(LOAD_LOOK, loadLook)

  yield takeLatest(CHANGE_LOOK_NAME, changeLookName)

  yield takeLatest(DELETE_LOOK, deleteLook)

  yield takeLatest(LOGOUT, logout)

}

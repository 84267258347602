
import {
  ConfirmModalActionType as ActionType,
  CONFIRM_MODAL_ACCEPTED,
  CONFIRM_MODAL_DISMISSED,
  SHOW_CONFIRM_MODAL,
} from './ConfirmModal.types'

export const showConfirmModal = (message: string, acceptLabel: string, dismissLabel: string): ActionType => ({
  type: SHOW_CONFIRM_MODAL,
  message,
  acceptLabel,
  dismissLabel,
})

export const confirmModalAccepted = (): ActionType => ({
  type: CONFIRM_MODAL_ACCEPTED,
})

export const confirmModalDismissed = (): ActionType => ({
  type: CONFIRM_MODAL_DISMISSED,
})

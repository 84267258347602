
export interface PasswordRecoveryScreenFormValues {
  email: string,
  recoveryToken: string,
  newPassword: string,
  newPasswordConfirm: string,
}

export interface PasswordRecoveryScreenFormErrors {

}

export interface PasswordRecoveryScreenState {
  busy: boolean,
  formValues: PasswordRecoveryScreenFormValues,
  formErrors: PasswordRecoveryScreenFormErrors,
  error?: string,
}

export const RESET = 'PasswordRecoveryScreen.reset'

export const SET_ERROR = 'PasswordRecoveryScreen.setError'

export const REQUEST_PASSWORD_RECOVERY_EMAIL = 'PasswordRecoveryScreen.requestPasswordRecoveryEmail'
export const REQUEST_PASSWORD_RECOVERY_EMAIL_SUCCESS = 'PasswordRecoveryScreen.requestPasswordRecoveryEmail.success'
export const REQUEST_PASSWORD_RECOVERY_EMAIL_FAILURE = 'PasswordRecoveryScreen.requestPasswordRecoveryEmail.failure'

export const REDEEM_PASSWORD_RECOVERY_TOKEN = 'PasswordRecoveryScreen.redeemPasswordRecoveryToken'

export const CHANGE_PASSWORD = 'PasswordRecoveryScreen.changePassword'
export const CHANGE_PASSWORD_SUCCESS = 'PasswordRecoveryScreen.changePassword.success'
export const CHANGE_PASSWORD_FAILURE = 'PasswordRecoveryScreen.changePassword.failure'

export interface ResetAction {
  type: typeof RESET,
  route: any,
}

export interface SetErrorAction {
  type: typeof SET_ERROR,
  error?: string,
}

export interface RequestPasswordRecoveryEmailAction {
  type: typeof REQUEST_PASSWORD_RECOVERY_EMAIL,
}

export interface RequestPasswordRecoveryEmailSuccessAction {
  type: typeof REQUEST_PASSWORD_RECOVERY_EMAIL_SUCCESS,
}

export interface RequestPasswordRecoveryEmailFailureAction {
  type: typeof REQUEST_PASSWORD_RECOVERY_EMAIL_FAILURE,
  error: any,
}

export interface RedeemPasswordRecoveryTokenAction {
  type: typeof REDEEM_PASSWORD_RECOVERY_TOKEN,
}

export interface ChangePasswordAction {
  type: typeof CHANGE_PASSWORD,
}

export interface ChangePasswordSuccessAction {
  type: typeof CHANGE_PASSWORD_SUCCESS,
}

export interface ChangePasswordFailureAction {
  type: typeof CHANGE_PASSWORD_FAILURE,
  error: any,
}

export type PasswordRecoveryScreenActionType =
  ResetAction |
  SetErrorAction |
  RequestPasswordRecoveryEmailAction |
  RequestPasswordRecoveryEmailSuccessAction |
  RequestPasswordRecoveryEmailFailureAction |
  RedeemPasswordRecoveryTokenAction |
  ChangePasswordAction |
  ChangePasswordSuccessAction |
  ChangePasswordFailureAction

import React from 'react'

import TextInput from '@components/TextInput.component'

const SecureInputComponent = (props: any) => {

  const [ showPassword, setShowPassword ] = React.useState(true)

  return (

    <TextInput
      {...props}
      inputProps={{
        ...props.inputProps,
        secureTextEntry: showPassword,
      }}
      buttonProps={{
        ...props.buttonProps,
        source: showPassword ?
          require('@assets/icons/icon-show-password.svg' /*'@assets/icons/dark/show-password.png'*/) :
          require('@assets/icons/icon-hide-password.svg' /*'@assets/icons/dark/hide-password.png'*/),
        onPress: () => setShowPassword(!showPassword),

      }} />

  )

}

export default SecureInputComponent


import { ViewType } from "@screens/Configurator/ConfiguratorScreen.types"

export interface DetailsScreenState {
  viewType: ViewType,
  detailsVisible: boolean,
}

export const SET_VIEW_TYPE = 'DetailsScreen.setViewType'

export const SET_DETAILS_VISIBLE = 'DetailsScreen.setDetailsVisible'

export interface SetViewTypeAction {
  type: typeof SET_VIEW_TYPE,
  viewType: ViewType,
}

export interface SetDetailsVisibleAction {
  type: typeof SET_DETAILS_VISIBLE,
  visible: boolean,
}

export type DetailsScreenActionType =
  SetViewTypeAction |
  SetDetailsVisibleAction

import {
  call,
  put,
  take,
} from 'redux-saga/effects'

import http from '../../../axios'

import {
  changeLookNameSuccess,
  changeLookNameFailure,
} from '../ProfileScreen.actions'

import {
  ChangeLookNameAction,
} from '../ProfileScreen.types'
import {showPromptModal} from '@modals/Prompt/PromptModal.actions';
import {
  PROMPT_MODAL_ACCEPTED,
  PROMPT_MODAL_DISMISSED,
} from '@modals/Prompt/PromptModal.types';

export default function* (action: ChangeLookNameAction) {

  try {

    yield put(showPromptModal('Gib Deinem Look einen Namen:', 'Abbrechen', 'Ok', action.look.name))

    const result = yield take([PROMPT_MODAL_DISMISSED, PROMPT_MODAL_ACCEPTED])

    if (result.type === PROMPT_MODAL_DISMISSED || !result.input) {

      return

    }

    yield call(http.post, '/look/update', {
      id: action.look.id,
      name: result.input,
    })

    yield put(changeLookNameSuccess(action.look.id, result.input))

  }
  catch (err) {

    yield call(console.error, err)

    yield put(changeLookNameFailure(err))

  }

}

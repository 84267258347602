
export interface RegisterScreenFormValues {
  salutationId: string,
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  passwordConfirm: string,
  street: string,
  zipcode: string,
  city: string,
  countryId: string,
  phoneNumber: string,
  dressSize: string,
  weddingDate: string,
  dataProtectionChecked: boolean,
}

export interface RegisterScreenFormErrors {

}

export interface RegisterScreenState {
  busy: boolean,
  salutations: any[],
  countries: any[],
  formValues: RegisterScreenFormValues,
  formErrors: RegisterScreenFormErrors,
  error?: string,
}

export const INIT = 'RegisterScreen.init'
export const INIT_SUCCESS = 'RegisterScreen.init.success'
export const INIT_FAILURE = 'RegisterScreen.init.failure'

export const RESET = 'RegisterScreen.reset'

export const SET_ERROR = 'RegisterScreen.setError'

export const PICK_DATE = 'RegisterScreen.pickDate'

export const REGISTER = 'RegisterScreen.register'
export const REGISTER_SUCCESS = 'RegisterScreen.register.success'
export const REGISTER_FAILURE = 'RegisterScreen.register.failure'

export interface InitAction {
  type: typeof INIT,
}

export interface InitSuccessAction {
  type: typeof INIT_SUCCESS,
  salutations: any[],
  countries: any[],
}

export interface InitFailureAction {
  type: typeof INIT_FAILURE,
  error?: string,
}

export interface ResetAction {
  type: typeof RESET,
}

export interface SetErrorAction {
  type: typeof SET_ERROR,
  error?: string,
}

export interface PickDateAction {
  type: typeof PICK_DATE,
}

export interface RegisterAction {
  type: typeof REGISTER,
}

export interface RegisterSuccessAction {
  type: typeof REGISTER_SUCCESS,
}

export interface RegisterFailureAction {
  type: typeof REGISTER_FAILURE,
  error: any,
}

export type RegisterScreenActionType =
  InitAction |
  InitSuccessAction |
  InitFailureAction |
  ResetAction |
  PickDateAction |
  SetErrorAction |
  RegisterAction |
  RegisterSuccessAction |
  RegisterFailureAction


import React from 'react'

import { connect } from 'react-redux'

import { State } from '@common/types'

import {
  init,
  setLayer,
  setBrowserVisible,
  setItem,
  setViewType,
  navigateToProfile,
  storeLook,
  shareLook,
} from './ConfiguratorScreen.actions'

import Stack from '@navigation/stack'
import options from './ConfiguratorScreen.options'
import ConfiguratorScreen from './ConfiguratorScreen.component'

import store from '@state/store'

const mapStateToProps = ({
  global: {
    loggedIn,
  },
  configuratorScreen: {
    layers,
    products,
    browserVisible,
    browserLoading,
    selectedLayer,
    selectedItems,
    look,
    price,
    viewType,
  },
}: State) => ({
  loggedIn,
  layers,
  products,
  browserVisible,
  browserLoading,
  selectedLayer,
  selectedItems,
  look,
  price,
  viewType,
})

const mapDispatchToProps = {
  setLayer,
  setBrowserVisible,
  setItem,
  setViewType,
  navigateToProfile,
  storeLook,
  shareLook,
}

export default function() {

  return (
    <Stack.Screen
      name='Configurator'
      options={options}
      component={connect(mapStateToProps, mapDispatchToProps)(ConfiguratorScreen)}
      listeners={{
        focus: () => store.dispatch(init()),
      }}
    />
  )

}

import React from 'react'

import {
  StyleSheet,
  Text,
} from 'react-native'

const TextComponent = ({
  style,
  ...props
}: any) => (

  <Text
    style={[
      styles.default,
      style,
    ]}
    selectable={false}
    { ...props } />

)

const styles = StyleSheet.create({

  default: {
    fontFamily: 'Montserrat_400Regular',
  },

})

export default TextComponent

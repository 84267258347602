import { call } from 'redux-saga/effects'

import http from '../../../axios'

const sortStores = (stores: any[]) => stores.sort((a, b) => a.distance < b.distance ? -1 : (a.distance > b.distance ? 1 : 0))

export default function* (location: string): any {

  const { data: storesData } = yield call(http.post, '/store', {
    location,
    includes: {
      bitit_lemoos_extension_store: ['id', 'name', 'street', 'zipcode', 'city', 'distance'],
    },
  })

  return storesData ? sortStores(storesData.elements) : []

}


export interface TimeModalState {
  visible: boolean,
  message: string,
  times: string[],
  selectedTime: string,
}

export const SHOW_TIME_MODAL = 'SHOW_TIME_MODAL'
export const TIME_MODAL_DISMISSED = 'TIME_MODAL_DISMISSED'
export const TIME_MODAL_ACCEPTED = 'TIME_MODAL_ACCEPTED'

export interface ShowTimeModalAction {
  type: typeof SHOW_TIME_MODAL,
  message: string,
  time: string,
}

export interface TimeModalAcceptedAction {
  type: typeof TIME_MODAL_ACCEPTED,
  time: string,
}

export interface TimeModalDismissedAction {
  type: typeof TIME_MODAL_DISMISSED,
}

export type TimeModalActionType =
  ShowTimeModalAction |
  TimeModalAcceptedAction |
  TimeModalDismissedAction

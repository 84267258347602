import { call, put, select, take } from 'redux-saga/effects'

import moment from 'moment'

import http from '../../../axios'

import {
  changeWeddingDateSuccess,
  changeWeddingDateFailure,
} from '../ProfileScreen.actions'

import { showDateModal } from '@modals/Date/DateModal.actions';

import {
  DATE_MODAL_ACCEPTED,
  DATE_MODAL_DISMISSED,
} from '@modals/Date/DateModal.types';

export default function* () {

  try {

    const { dressSize, weddingDate } = yield select(state => state.profile)

    yield put(showDateModal('Wann ist Deine Hochzeit:', weddingDate))

    const result = yield take([ DATE_MODAL_DISMISSED, DATE_MODAL_ACCEPTED ])

    if (result.type === DATE_MODAL_DISMISSED || !result.date) {

      return

    }

    const dateString = moment(result.date.dateString).format('DD.MM.YYYY')

    yield call(http.post, '/account/change-info', {
      dressSize,
      weddingDate: dateString,
    })

    yield put(changeWeddingDateSuccess(dateString))

  }
  catch (err) {

    yield call(console.error, err)

    yield put(changeWeddingDateFailure(err))

  }

}

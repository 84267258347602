import produce from 'immer'

import {
  ConfirmModalState,
  ConfirmModalActionType,
  CONFIRM_MODAL_ACCEPTED,
  CONFIRM_MODAL_DISMISSED,
  SHOW_CONFIRM_MODAL,
} from './ConfirmModal.types'

const INITIAL_STATE: ConfirmModalState = {
  visible: false,
  message: '',
  acceptLabel: '',
  dismissLabel: '',
}

export default produce((draft: ConfirmModalState, action: ConfirmModalActionType) => {

  switch (action.type) {

    case SHOW_CONFIRM_MODAL:
      draft.visible = true
      draft.message = action.message
      draft.acceptLabel = action.acceptLabel
      draft.dismissLabel = action.dismissLabel
      break

    case CONFIRM_MODAL_ACCEPTED:
      draft.visible = false
      break

    case CONFIRM_MODAL_DISMISSED:
      draft.visible = false
      break

  }

}, INITIAL_STATE)


import { takeLatest } from 'redux-saga/effects'

import {
  CHANGE_PASSWORD,
} from './ChangePasswordScreen.types'

import changePassword from './sagas/changePassword.saga'

export default function* () {

  yield takeLatest(CHANGE_PASSWORD, changePassword)

}

import { call } from 'redux-saga/effects'

import http from '../../../axios'

import {
  Look,
} from '../ProfileScreen.types'

import {
  ProductVariant,
} from '@screens/Configurator/ConfiguratorScreen.types'

import {
  mapProduct,
  mapProductVariant,
} from '@common/utils'

const mediaAssociation = {
  associations: {
    media: {
      associations: {
        thumbnails: {
          sort: [
            {
              field: 'width',
              order: 'ASC',
            },
          ],
        },
      },
    },
  },
  sort: [
    {
      field: 'position',
      order: 'ASC',
    },
  ],
}

const associations = {
  items: {
    associations: {
      product: {},
      variant: {
        associations: {
          media: mediaAssociation,
          options: {},
        },
      },
    },
    sort: [
      {
        field: 'layer',
        order: 'ASC',
      },
    ],
  },
}

const includes = {
  bitit_lemoos_extension_look       : ['id', 'name', 'items'],
  bitit_lemoos_extension_look_item  : ['id', 'layer', 'name', 'product', 'variant'],
  product                           : ['id', 'calculatedPrice', 'name', 'media', 'options'],
  calculated_price                  : ['unitPrice'],
  product_media                     : ['position', 'media'],
  media                             : ['url', 'thumbnails'],
  media_thumbnail                   : ['url'],
  property_group_option             : ['groupId', 'colorHexCode'],
}

const sort = [
  {
    field: 'createdAt',
    order: 'ASC',
  },
]

const mapLooks = (items: any[]): Look[] => items.map(look => ({
  id    : look.id,
  name  : look.name,
  items : look.items ? mapLookItems(look.items) : [],
}))

const mapLookItems = (items: any[]): ProductVariant[] => {

  const res: ProductVariant[] = []

  for (const item of items) {

    res[item.layer] = mapProductVariant(item.variant, mapProduct(item.product))

  }

  return res

}

export default function* (): any {

  try {

    const { data: looksData } = yield call(http.post, '/look', {
      associations,
      includes,
      sort,
    })

    return looksData ? mapLooks(looksData.elements) : []

  }
  catch (err) {

    yield call(console.error, err)

    throw err

  }

}

import React from 'react'

import {
  StyleSheet,
  PressableStateCallbackType,
  View,
  ActivityIndicator,
} from 'react-native'

import {
  BROWSER_ITEM_SIZE,
  BROWSER_ITEM_SPACING,
  TEXT_MD,
  COLOR_BLACK,
  COLOR_GREY_LIGHTER,
  MON_500,
} from '@common/constants'

import { ProductVariant } from '../ConfiguratorScreen.types'

import Text from '@components/Text.component'
import IconButton from '@components/IconButton.component'
import ResetFlatList from '@components/ResetFlatList.component'

import BrowserItem from './BrowserItem.component'

const BrowserHeader = ({
   layers,
   selectedLayer,
   hide,
 }: any) => (

  <View
    style={styles.header}>

    <Text style={styles.headline}>
      {`Dein `}
      {`${layers[selectedLayer]} auswählen`}
    </Text>

    <IconButton
      onPress={hide}
      size={8}
      style={({ pressed }: PressableStateCallbackType) => ({
        width: 24,
        height: 24,
        borderRadius: 12,
        position: 'absolute',
        right: -24,
        top: -24,
        backgroundColor: pressed ? COLOR_GREY_LIGHTER: '#343639',
      })}
      source={({ pressed }: PressableStateCallbackType) => pressed
        ? require('@assets/icons/icon-close.svg' /*'@assets/icons/dark/close.png'*/)
        : require('@assets/icons/icon-close-white.svg' /*'@assets/icons/white/close.png'*/)}
    />
  </View>

)

const Browser = ({
   style,
   loading,
   showPrices,
   layers,
   selectedLayer,
   selectedItems,
   items,
   setItem,
   hide,
   show
 }: any) => (

  <View style={style} >

    <View style={styles.container}>

      <BrowserHeader
        layers={layers}
        selectedLayer={selectedLayer}
        hide={hide}
        show={show}
      />

      {loading ?

        <View
          style={styles.loading}>

          <ActivityIndicator
            size="large"
            color="#333538" />

        </View>

      :

      <ResetFlatList
        showsVerticalScrollIndicator={false}
        data={items}
        keyExtractor={item => item.id}
        getItemLayout={(data, index) => ({
          length: BROWSER_ITEM_SIZE + (index > 0 ? BROWSER_ITEM_SPACING : 0),
          offset: (BROWSER_ITEM_SIZE + (index - 1 > 0 ? BROWSER_ITEM_SPACING : 0)) * index,
          index
        })}
        renderItem={({ item, index }) => {

          const active = selectedItems[selectedLayer] && selectedItems[selectedLayer].product.id === item.id

          return <BrowserItem
            index={index}
            active={active}
            product={item}
            selectedVariant={active && selectedItems[selectedLayer]}
            showPrice={showPrices}
            onPress={(variant: ProductVariant, main: boolean) => setItem(main && active ? null : variant)} />

        }}
      />

      }

    </View>

  </View>

)

const styles = StyleSheet.create({

  header: {
    marginTop: 20,
    marginHorizontal: 16,
    marginBottom: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  headline: {
    fontFamily: MON_500,
  },

  container: {
    flex: 1,
    marginHorizontal: 16,
    marginTop: 25,
    marginBottom: 50,
    borderRadius: 28,
    backgroundColor: 'white',
  },

  loading: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: TEXT_MD,
    color: COLOR_BLACK
  },

})

export default Browser

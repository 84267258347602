import produce from 'immer'

import {
  AlertModalState,
  AlertModalActionType,
  SHOW_ALERT_MODAL,
  ALERT_MODAL_DISMISSED,
} from './AlertModal.types'

const INITIAL_STATE: AlertModalState = {
  visible: false,
  message: '',
  dismissLabel: '',
}

export default produce((draft: AlertModalState, action: AlertModalActionType) => {

  switch (action.type) {

    case SHOW_ALERT_MODAL:
      draft.visible = true
      draft.message = action.message
      draft.dismissLabel = action.dismissLabel
      break

    case ALERT_MODAL_DISMISSED:
      draft.visible = false
      break

  }

}, INITIAL_STATE)

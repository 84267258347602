
import {
  ProductVariant,
} from '@screens/Configurator/ConfiguratorScreen.types'

export enum ListType {
  COLUMNS,
  ROWS,
}

export interface Look {
  id: string,
  name: string,
  items: ProductVariant[],
}

export interface ProfileScreenState {
  listType: ListType,
  looks: Look[],
}

export const INIT = 'ProfileScreen.init'
export const INIT_SUCCESS = 'ProfileScreen.init.success'
export const INIT_FAILURE = 'ProfileScreen.init.failure'

export const CHANGE_DRESS_SIZE = 'ProfileScreen.changeDressSize'
export const CHANGE_DRESS_SIZE_SUCCESS = 'ProfileScreen.changeDressSize.success'
export const CHANGE_DRESS_SIZE_FAILURE = 'ProfileScreen.changeDressSize.failure'

export const CHANGE_WEDDING_DATE = 'ProfileScreen.changeWeddingDate'
export const CHANGE_WEDDING_DATE_SUCCESS = 'ProfileScreen.changeWeddingDate.success'
export const CHANGE_WEDDING_DATE_FAILURE = 'ProfileScreen.changeWeddingDate.failure'

export const SET_LIST_TYPE = 'ProfileScreen.setListType'

export const LOAD_LOOK = 'ProfileScreen.loadLook'

export const LOGOUT = 'ProfileScreen.logout'
export const LOGOUT_SUCCESS = 'ProfileScreen.logout.success'
export const LOGOUT_FAILURE = 'ProfileScreen.logout.failure'

export const CHANGE_LOOK_NAME = 'ProfileScreen.changeLookName'
export const CHANGE_LOOK_NAME_SUCCESS = 'ProfileScreen.changeLookName.success'
export const CHANGE_LOOK_NAME_FAILURE = 'ProfileScreen.changeLookName.failure'

export const DELETE_LOOK = 'ProfileScreen.deleteLook'
export const DELETE_LOOK_SUCCESS = 'ProfileScreen.deleteLook.success'
export const DELETE_LOOK_FAILURE = 'ProfileScreen.deleteLook.failure'

export interface InitAction {
  type: typeof INIT,
}

export interface InitSuccessAction {
  type: typeof INIT_SUCCESS,
  looks: Look[],
  dressSize: string,
}

export interface InitFailureAction {
  type: typeof INIT_FAILURE,
  error?: string,
}

export interface ChangeDressSizeAction {
  type: typeof CHANGE_DRESS_SIZE,
}

export interface ChangeDressSizeSuccessAction {
  type: typeof CHANGE_DRESS_SIZE_SUCCESS,
  dressSize: string,
}

export interface ChangeDressSizeFailureAction {
  type: typeof CHANGE_DRESS_SIZE_FAILURE,
  error: any,
}

export interface ChangeWeddingDateAction {
  type: typeof CHANGE_WEDDING_DATE,
}

export interface ChangeWeddingDateSuccessAction {
  type: typeof CHANGE_WEDDING_DATE_SUCCESS,
  weddingDate: string,
}

export interface ChangeWeddingDateFailureAction {
  type: typeof CHANGE_WEDDING_DATE_FAILURE,
  error: any,
}

export interface SetListTypeAction {
  type: typeof SET_LIST_TYPE,
  listType: ListType,
}

export interface LoadLookAction {
  type: typeof LOAD_LOOK,
  look: Look,
}

export interface LogoutAction {
  type: typeof LOGOUT,
}

export interface LogoutSuccessAction {
  type: typeof LOGOUT_SUCCESS,
}

export interface LogoutFailureAction {
  type: typeof LOGOUT_FAILURE,
  error: any,
}

export interface ChangeLookNameAction {
  type: typeof CHANGE_LOOK_NAME,
  look: Look,
}

export interface ChangeLookNameSuccessAction {
  type: typeof CHANGE_LOOK_NAME_SUCCESS,
  lookId: string,
  name: string,
}

export interface ChangeLookNameFailureAction {
  type: typeof CHANGE_LOOK_NAME_FAILURE,
  error: any,
}

export interface DeleteLookAction {
  type: typeof DELETE_LOOK,
  look: Look,
}

export interface DeleteLookSuccessAction {
  type: typeof DELETE_LOOK_SUCCESS,
  lookId: string,
}

export interface DeleteLookFailureAction {
  type: typeof DELETE_LOOK_FAILURE,
  error: any,
}

export type ProfileScreenActionType =
  InitAction |
  InitSuccessAction |
  InitFailureAction |
  ChangeDressSizeAction |
  ChangeDressSizeSuccessAction |
  ChangeDressSizeFailureAction |
  ChangeWeddingDateAction |
  ChangeWeddingDateSuccessAction |
  ChangeWeddingDateFailureAction |
  SetListTypeAction |
  LoadLookAction |
  LogoutAction |
  LogoutSuccessAction |
  LogoutFailureAction |
  ChangeLookNameAction |
  ChangeLookNameSuccessAction |
  ChangeLookNameFailureAction |
  DeleteLookAction |
  DeleteLookSuccessAction |
  DeleteLookFailureAction

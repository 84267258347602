import React from 'react'

import {
  StyleSheet,
  View,
} from 'react-native'

import { Picker } from '@react-native-picker/picker'

import {
  TEXT_13,
  TEXT_MD,
  MON_500
} from '@common/constants'

import Text from '@components/Text.component'

const PickerComponent = ({
  label,
  children,
  selectedValue,
  onValueChange,
}: any) => (

  <View style={styles.container}>

    <Text
      style={styles.pickerLabel}>
      {label}
    </Text>

    <View
      style={styles.pickerContainer}>

      <Picker
        style={styles.picker}
        selectedValue={selectedValue}
        onValueChange={onValueChange}>
        {children}
      </Picker>

    </View>

  </View>

)

const styles = StyleSheet.create({

  pickerLabel: {
    paddingLeft: 10,
    marginBottom: 8,
    fontSize: TEXT_13,
    fontWeight: '500',
    color: 'rgb(97, 100, 108)',
  },

  pickerContainer: {
    backgroundColor: '#fff',
    borderRadius: 24,
    paddingRight: 20,
    marginBottom: 25,
    height: 54,
  },

  picker: {
    paddingVertical: 20,
    paddingHorizontal: 16,
    border: 0,
    borderRadius: 24,
    fontWeight: '500',
    fontSize: TEXT_MD,
    fontFamily: MON_500,
    backgroundColor: 'transparent',
  },

})

export default PickerComponent

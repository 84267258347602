
import produce from 'immer'

import {
  CommonActionType,
  SET_FORM_VALUE,
  SET_FORM_ERRORS,
} from '@common/types'

import {
  LoginScreenState,
  LoginScreenActionType,
  RESET,
  SET_ERROR,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
} from './LoginScreen.types'

const INITIAL_STATE: LoginScreenState = {
  busy: false,
  formValues: {
    username: '',
    password: '',
  },
  formErrors: {
    username: null,
    password: null,
  },
}

export default produce((
  draft: LoginScreenState,
  action: CommonActionType|LoginScreenActionType
) => {

  switch (action.type) {

    case RESET:
      return INITIAL_STATE

    case SET_FORM_VALUE:
      if (action.form === 'login') {
        draft.formValues[action.key] = action.val
      }
      break

    case SET_FORM_ERRORS:
      if (action.form === 'login') {
        draft.formErrors = action.errors
      }
      break

    case SET_ERROR:
      draft.error = action.error
      break

    case LOGIN:
      draft.busy = true
      break

    case LOGIN_SUCCESS:
      return INITIAL_STATE

    case LOGIN_FAILURE:
      draft.busy = false
      break

  }

}, INITIAL_STATE)

export default {
  firstName: {
    presence: {
      allowEmpty: false,
      message: 'Bitte Vorname eingeben',
    },
  },
  lastName: {
    presence: {
      allowEmpty: false,
      message: 'Bitte Nachname eingeben',
    },
  },
  email: {
    presence: {
      allowEmpty: false,
      message: 'Bitte E-Mail eingeben',
    },
    email: {
      message: 'Bitte korrekte E-Mail eingeben',
    },
  },
  street: {
    presence: {
      allowEmpty: false,
      message: 'Bitte Straße eingeben',
    },
  },
  zipcode: {
    presence: {
      allowEmpty: false,
      message: 'Bitte PLZ eingeben',
    },
  },
  city: {
    presence: {
      allowEmpty: false,
      message: 'Bitte Ort eingeben',
    },
  },
  password: {
    presence: {
      allowEmpty: false,
      message: 'Bitte Passwort eingeben',
    },
    length: {
      minimum: 8,
      message: 'Bitte mindesten 8 Zeichen eingeben',
    },
  },
  passwordConfirm: {
    presence: {
      allowEmpty: false,
      message: 'Bitte Passwort bestätigen',
    },
  },
  dressSize: {
    presence: {
      allowEmpty: false,
      message: 'Bitte eingeben',
    },
  },
}

import React from 'react'

import {
  StyleSheet,
  Platform,
  Pressable,
  PressableStateCallbackType,
  View,
} from 'react-native'

import {
  BROWSER_ITEM_SPACING,
  RADIUS_17,
  COLOR_GREY_LIGHTER
} from '@common/constants'

import {
  ViewType,
} from '@screens/Configurator/ConfiguratorScreen.types'

import IconButton from '@components/IconButton.component'

import Preview from '@screens/Configurator/Configurator/Preview.component'

const LookListItemTile = ({
  look,
  index,
  active,
  onPress,
  onEdit,
  onDelete,
}: any) => (

  <Pressable
    onPress={onPress}
    style={({ pressed }: PressableStateCallbackType) => ([
      styles.container,
      active && styles.containerActive,
      pressed && styles.containerPressed,
      Platform.OS === 'web' && { cursor: 'pointer' } ,
      {
        marginTop: index > 1 ? BROWSER_ITEM_SPACING : 0,
        marginLeft: index % 2 ? BROWSER_ITEM_SPACING / 2 : 0,
        marginRight: !(index % 2) ? BROWSER_ITEM_SPACING / 2 : 0,
      }
    ])}
  >

    <Preview
      style={styles.image}
      layers={[1,2,3,4]}
      selectedItems={look.items}
      scale={0.125}
      viewType={ViewType.FRONT}/>

    <View
      style={styles.buttons}>

      <IconButton
        onPress={onEdit}
        size={14}
        source={require('@assets/icons/icon-edit.svg' /*'@assets/icons/dark/edit.png'*/)}/>

      <IconButton
        onPress={onDelete}
        size={18}
        source={require('@assets/icons/icon-delete.svg' /*'@assets/icons/dark/delete.png'*/)}/>

    </View>

  </Pressable>

)

const styles = StyleSheet.create({

  container: {
    flex: 1/2,
    height: 162,
    borderRadius: RADIUS_17,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
  },

  containerPressed: {
    backgroundColor: COLOR_GREY_LIGHTER,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.12,
    shadowRadius: 10,
  },

  containerActive: {
    backgroundColor: COLOR_GREY_LIGHTER,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.12,
    shadowRadius: 10,
  },

  image: {
    width: '100%',
    height: '100%',
  },

  buttons: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    justifyContent: 'space-between'
  },

})

export default LookListItemTile

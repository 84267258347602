import {
  AppointmentScreenActionType as ActionType,
  RESET,
  PICK_DATE,
  PICK_TIME,
  MAKE_APPOINTMENT,
  MAKE_APPOINTMENT_FAILURE,
  MAKE_APPOINTMENT_SUCCESS,
} from './AppointmentScreen.types'

export const reset = (): ActionType => ({
  type: RESET,
})

export const pickDate = (): ActionType => ({
  type: PICK_DATE,
})

export const pickTime = (): ActionType => ({
  type: PICK_TIME,
})

export const makeAppointment = (): ActionType => ({
  type: MAKE_APPOINTMENT,
})

export const makeAppointmentSuccess = (): ActionType => ({
  type: MAKE_APPOINTMENT_SUCCESS,
})

export const makeAppointmentFailure = (error: any): ActionType => ({
  type: MAKE_APPOINTMENT_FAILURE,
  error,
})

import { call, put, select } from 'redux-saga/effects'

import {
  SetItemAction,
  Product,
} from '../ConfiguratorScreen.types'

import { showAlertModal } from '@modals/Alert/AlertModal.actions'

export default function* (action: SetItemAction): any {

  try {

    const { products, selectedLayer } = yield select(state => state.configuratorScreen)

    const { item } = action

    if (item && !products[selectedLayer].find((product: Product) => product.id == item.product.id).enabled) {

      yield put(showAlertModal('Deine Auswahl passt nicht zu der Vorauswahl. Bitte wähle ein anderes Produkt aus.', 'Ok'))

    }

  }
  catch (err) {

    yield call(console.error, err)

  }

}


export interface ConfirmModalState {
  visible: boolean,
  message: string,
  acceptLabel: string,
  dismissLabel: string,
}

export const SHOW_CONFIRM_MODAL = 'SHOW_CONFIRM_MODAL'
export const CONFIRM_MODAL_ACCEPTED = 'CONFIRM_MODAL_ACCEPTED'
export const CONFIRM_MODAL_DISMISSED = 'CONFIRM_MODAL_DISMISSED'

export interface ShowConfirmModalAction {
  type: typeof SHOW_CONFIRM_MODAL,
  message: string,
  acceptLabel: string,
  dismissLabel: string,
}

export interface ConfirmModalAcceptedAction {
  type: typeof CONFIRM_MODAL_ACCEPTED,
}

export interface ConfirmModalDismissedAction {
  type: typeof CONFIRM_MODAL_DISMISSED,
}

export type ConfirmModalActionType =
  ShowConfirmModalAction |
  ConfirmModalAcceptedAction |
  ConfirmModalDismissedAction

import React from 'react'

import {
  StyleSheet,
  ScrollView,
  Pressable,
} from 'react-native'

import TextInput from '@components/TextInput.component'

const AppointmentForm = ({
  formValues,
  formErrors,
  pickDate,
  pickTime,
}: any) => (

  <ScrollView
    style={styles.container}>

    <Pressable
      onPress={pickDate}>
      <TextInput
        error={formErrors.date}
        style={styles.input}
        inputProps={{
          placeholder: 'Datum',
          value: formValues.date,
          editable: false,
        }}
        buttonProps={{
          onPress: pickDate,
          source: require('@assets/icons/icon-cal.svg' /*'@assets/icons/dark/cal.png'*/),
        }}/>
    </Pressable>

    <Pressable
      onPress={pickTime}>
      <TextInput
        error={formErrors.time}
        style={styles.input}
        inputProps={{
          placeholder: 'Uhrzeit',
          value: formValues.time && `${formValues.time} Uhr`,
          editable: false,
        }}
        buttonProps={{
          onPress: pickTime,
          source: require('@assets/icons/icon-time.svg' /*'@assets/icons/dark/clock.png'*/),
        }}/>
    </Pressable>

  </ScrollView>

)

const styles = StyleSheet.create({

  container: {
    flex: 1,
    paddingHorizontal: 24,
  },

  input: {
    backgroundColor: '#fff',
  },

})

export default AppointmentForm

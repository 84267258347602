import React, { useState } from 'react'

import {
  LayoutChangeEvent,
  View,
} from 'react-native'

import {
  PanGestureHandler,
  FlingGestureHandler,
  Directions,
  State,
} from 'react-native-gesture-handler'

import Preview from './Preview.component'

import IconButton from '@components/IconButton.component'

let minScale = 0
let maxScale = 0
let lastOffset = null
let scaleStep = 0

const PreviewEx = ({
  browserVisible,
  layers,
  selectedItems,
  viewType,
  prevItem,
  nextItem,
}: any) => {

  const [ scale, setScale ] = useState(0)

  const [ offset, setOffset ] = useState({ x: 0, y: 0 })

  function onLayout(event: LayoutChangeEvent) {

    const layout = event.nativeEvent.layout

    minScale = layout.height * 0.00084
    maxScale = minScale * 3

    scaleStep = minScale

    setScale(minScale)

    setOffset({ x: 0, y: 0 })

  }

  function zoomIn() {

    setScale(Math.min(maxScale, scale + scaleStep))

  }

  function zoomOut() {

    const numSteps = Math.round((scale - minScale) / scaleStep)

    let nextScale = Math.max(minScale, scale - scaleStep)

    if (Math.abs(nextScale - minScale) < 0.01) {

      nextScale = minScale

    }

    setScale(nextScale)

    if (numSteps > 1) {

      let deltaX = offset.x / numSteps
      let deltaY = offset.y / numSteps

      setOffset({ x: offset.x - deltaX, y: offset.y - deltaY })

    }
    else {

      setOffset({ x: 0, y: 0 })

    }

  }

  return (<>

  <Preview
    style={{
      position: 'absolute',
      width: '100%',
      height: '100%',
    }}
    layers={layers}
    selectedItems={selectedItems}
    viewType={viewType}
    scale={scale}
    offset={offset}/>

  <PanGestureHandler
    onGestureEvent={({ nativeEvent: event }) => {

      if (scale > minScale) {

        if (event.state === State.ACTIVE) {

          if (!lastOffset) {

            lastOffset = { x: offset.x, y: offset.y }

          }

          setOffset({ x: lastOffset.x + event.translationX, y: lastOffset.y + event.translationY })

        }
        else {

          lastOffset = null

        }

      }

    }}>

    <FlingGestureHandler
      direction={Directions.RIGHT | Directions.LEFT}
      onHandlerStateChange={({ nativeEvent: event }) => {

        if (scale === minScale && event.state === State.ACTIVE) {

          event.translationX > 0 ? prevItem() : nextItem()

        }

      }}>

      <View
        onLayout={onLayout}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}/>

    </FlingGestureHandler>

  </PanGestureHandler>

  {!browserVisible &&
  <View
    style={{
      position: 'absolute',
      top: 72,
      left: 16,
    }}>

    <IconButton
      onPress={zoomIn}
      source={require('@assets/icons/dark/zoom-plus.png')}/>

    <IconButton
      style={{
        marginTop: 8,
      }}
      onPress={zoomOut}
      source={require('@assets/icons/dark/zoom-minus.png')}/>

  </View>
  }

  </>)

}

export default PreviewEx

import React from 'react'

import {
  StyleSheet,
  ScrollView,
  View,
  Text, TextInput, Platform,
} from 'react-native'

import Button from '@components/DarkButton.component'
import Message from '@components/Message.component'
import BusyScreen from '@components/BusyScreen.component'

import ChangeEmailForm from './ChangeEmailForm.component'
import {COLOR_BLACK, COLOR_GREY, COLOR_GREY_LIGHTER, MON_500, TEXT_13, TEXT_MD} from '../../common/constants';
import IconButton from '../../components/IconButton.component';

const ChangeEmailScreen = ({
  busy,
  formValues,
  formErrors,
  error,
  setFormValue,
  changeEmail,
  email,
}: any) => (

  busy ?

  <BusyScreen message='Bitte warten'/>

  :

  <ScrollView
    style={styles.container}>

    {!!error &&
    <Message message={error} />
    }


    <View style={styles.wrapper}>
      <Text style={styles.label}>
        Aktuelle E-Mail
      </Text>
      <Text style={styles.inputContainer}>
        <Text style={styles.input}>
        {email}
        </Text>
      </Text>
    </View>

    <ChangeEmailForm
      formName="changeEmail"
      formValues={formValues}
      formErrors={formErrors}
      setFormValue={setFormValue}
      submit={changeEmail}/>

    <View
      style={styles.bottomContainer}>

      <Button
        onPress={changeEmail}
        label="Übernehmen" />

    </View>

  </ScrollView>

)

const styles = StyleSheet.create({

  container: {
    flex: 1,
  },

  wrapper: {
    paddingHorizontal: 22,
    marginBottom: 25,
  },

  bottomContainer: {
    padding: 24,
  },

  inputContainer: {
    paddingVertical: 8,
    paddingHorizontal: 22,
    borderRadius: 22,
    borderWidth: 2,
    borderColor: 'white',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 54,
    backgroundColor: COLOR_GREY_LIGHTER,
  },

  label: {
    marginBottom: 4,
    paddingLeft: 10,
    fontSize: TEXT_13,
    fontFamily: MON_500,
    color: COLOR_GREY,
  },

  input: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 7,
    maxHeight: 54,
    fontFamily: MON_500,
    fontSize: TEXT_MD
  },

})

export default ChangeEmailScreen

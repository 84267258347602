
export interface ChangeEmailScreenFormValues {
  email: string,
  emailConfirmation: string,
  password: string,
}

export interface ChangeEmailScreenFormErrors {

}

export interface ChangeEmailScreenState {
  busy: boolean,
  formValues: ChangeEmailScreenFormValues,
  formErrors: ChangeEmailScreenFormErrors,
  error?: string,
}

export const RESET = 'ChangeEmailScreen.reset'

export const SET_ERROR = 'ChangeEmailScreen.setError'

export const CHANGE_EMAIL = 'ChangeEmailScreen.changeEmail'
export const CHANGE_EMAIL_SUCCESS = 'ChangeEmailScreen.changeEmail.success'
export const CHANGE_EMAIL_FAILURE = 'ChangeEmailScreen.changeEmail.failure'

export interface ResetAction {
  type: typeof RESET,
}

export interface SetErrorAction {
  type: typeof SET_ERROR,
  error?: string,
}

export interface ChangeEmailAction {
  type: typeof CHANGE_EMAIL,
}

export interface ChangeEmailSuccessAction {
  type: typeof CHANGE_EMAIL_SUCCESS,
}

export interface ChangeEmailFailureAction {
  type: typeof CHANGE_EMAIL_FAILURE,
  error: any,
}

export type ChangeEmailScreenActionType =
  ResetAction |
  SetErrorAction |
  ChangeEmailAction |
  ChangeEmailSuccessAction |
  ChangeEmailFailureAction

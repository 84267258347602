import {call, put, select, take} from 'redux-saga/effects'

import http from '../../../axios'

import {
  LoadLookAction,
} from '../ProfileScreen.types'

import {
  ProductVariant,
} from '@screens/Configurator/ConfiguratorScreen.types'

import {
  setLook,
} from '@screens/Configurator/ConfiguratorScreen.actions'

import { navigate } from '@navigation/root'
import {showConfirmModal} from "@modals/Confirm/ConfirmModal.actions";

import {
  CONFIRM_MODAL_ACCEPTED,
  CONFIRM_MODAL_DISMISSED,
} from "@modals/Confirm/ConfirmModal.types";

export default function* (action: LoadLookAction) {

  try {

    const {
      selectedItems,
      look,
    } = yield select(state => state.configuratorScreen)

    if (look &&
        look.items.map((item: ProductVariant) => item && item.id).join('') !==
        selectedItems.map((item: ProductVariant) => item && item.id).join('')) {

      yield put(showConfirmModal('Möchtest Du die Änderugen am aktuellen Look speichern?', 'Ja', 'Nein'))

      const result = yield take([CONFIRM_MODAL_ACCEPTED, CONFIRM_MODAL_DISMISSED])

      if (result.type === 'CONFIRM_MODAL_ACCEPTED') {

        yield call(http.post, '/look/update', {
          id: look.id,
          items: selectedItems.map((item: ProductVariant) => item && item.id),
        })

      }

    }

    yield put(setLook(action.look))

    yield call(navigate, 'Configurator')
  }
  catch (err) {

    yield call(console.error, err)

  }

}

import produce from 'immer'

import {
  CommonActionType,
  SET_FORM_VALUE,
  SET_FORM_ERRORS,
} from '@common/types'

import {
  StoresScreenState,
  StoresScreenActionType,
  INIT,
  INIT_SUCCESS,
  INIT_FAILURE,
  RESET,
  SET_STORE,
  FIND_STORES_SUCCESS,
  FIND_STORES,
  FIND_STORES_FAILURE,
} from './StoresScreen.types'

const INITIAL_STATE: StoresScreenState = {
  busy: true,
  formValues: {
    location: '',
  },
  formErrors: {
  },
  stores: [],
  selectedStore: null,
}

export default produce((
  draft: StoresScreenState,
  action: CommonActionType|StoresScreenActionType
) => {

  switch (action.type) {

    case INIT:
      draft.busy = true
      break

    case INIT_SUCCESS:
      draft.busy = false
      draft.stores = action.stores
      break

    case INIT_FAILURE:
      draft.busy = false
      break

    case RESET:
      return INITIAL_STATE

    case SET_FORM_VALUE:
      if (action.form === 'stores') {
        draft.formValues[action.key] = action.val
      }
      break

    case SET_FORM_ERRORS:
      if (action.form === 'stores') {
        draft.formErrors = action.errors
      }
      break

    case FIND_STORES:
      draft.busy = true
      break

    case FIND_STORES_SUCCESS:
      draft.busy = false
      draft.stores = action.stores
      draft.selectedStore = INITIAL_STATE.selectedStore
      break

    case FIND_STORES_FAILURE:
      draft.busy = false
      break

    case SET_STORE:
      draft.selectedStore = action.store
      break

  }

}, INITIAL_STATE)


import { connect } from 'react-redux'

import { State } from '@common/types'

import PromptModal from './PromptModal.component'

import {
  promptModalAccepted,
  promptModalDismissed,
  setInputValue,
} from '@modals/Prompt/PromptModal.actions';

const mapStateToProps = ({
  promptModal: {
    visible,
    message,
    acceptLabel,
    dismissLabel,
    inputValue,
  }
}: State) => ({
  visible,
  message,
  acceptLabel,
  dismissLabel,
  inputValue,
})

const mapDispatchToProps = {
  promptModalAccepted,
  promptModalDismissed,
  setInputValue,
}

export default connect(mapStateToProps, mapDispatchToProps)(PromptModal)

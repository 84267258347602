import React from 'react'

import {
  StyleSheet,
  ScrollView,
  View,
} from 'react-native'

import Button from '@components/DarkButton.component'
import Message from '@components/Message.component'
import BusyScreen from '@components/BusyScreen.component'

import ChangeProfileForm from './ChangeProfileForm.component'

const ChangeProfileScreen = ({
  busy,
  salutations,
  countries,
  formValues,
  formErrors,
  error,
  setFormValue,
  changeProfile,
}: any) => (

  busy ?

  <BusyScreen message='Bitte warten'/>

  :

  <ScrollView
    style={styles.container}>

    {!!error &&
    <Message message={error} />
    }

    <ChangeProfileForm
      formName="changeProfile"
      formValues={formValues}
      formErrors={formErrors}
      salutations={salutations}
      countries={countries}
      setFormValue={setFormValue}
      submit={changeProfile}/>

    <View
      style={styles.bottomContainer}>

      <Button
        onPress={changeProfile}
        label="Übernehmen" />

    </View>

  </ScrollView>

)

const styles = StyleSheet.create({

  container: {
    flex: 1,
  },

  bottomContainer: {
    padding: 24,
  },

})

export default ChangeProfileScreen

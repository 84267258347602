import React from 'react'

import {
  StyleSheet,
  ScrollView,
  View,
} from 'react-native'

import Button from '@components/DarkButton.component'
import Message from '@components/Message.component'
import BusyScreen from '@components/BusyScreen.component'

import RegisterForm from './RegisterForm.component'

const RegisterScreen = ({
  busy,
  salutations,
  countries,
  formValues,
  formErrors,
  error,
  setFormValue,
  register,
  pickDate,
  navigation,
}: any) => (

  busy ?

  <BusyScreen message='Bitte warten'/>

  :

  <ScrollView
    style={styles.container}>

    {!!error &&
    <Message message={error} />
    }

    <RegisterForm
      formName="register"
      formValues={formValues}
      formErrors={formErrors}
      salutations={salutations}
      countries={countries}
      setFormValue={setFormValue}
      navigation={navigation}
      pickDate={pickDate}
      submit={register}/>

    <View
      style={styles.bottomContainer}>

      <Button
        onPress={register}
        label="Registrieren" />

    </View>

  </ScrollView>

)

const styles = StyleSheet.create({

  container: {
    flex: 1,
  },

  bottomContainer: {
    marginTop: 24,
    paddingHorizontal: 24,
    paddingBottom: 24,
  },

})

export default RegisterScreen

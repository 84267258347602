import React from 'react'

import {
  StyleSheet,
  View,
} from 'react-native'

import Text from '@components/Text.component'
import Button from '@components/DarkButton.component'
import IconButton from '@components/IconButton.component'

import {
  COLOR_BLACK,
  COLOR_GREY,
  TEXT_MD,
  TEXT_XL,
  UNNA_REG,
  LINE_HEIGHT_XL,
  MON_500
} from "../../common/constants";

const SummaryItem = ({
  text1,
  text2,
  iconSource,
}: any) => (

  <View
    style={styles.summaryItem}>

    <View
      style={styles.flex}>
      <Text
        style={{ fontFamily: MON_500,marginBottom: 8 }}>
        {text1}
      </Text>
      <Text style={{fontWeight: '400', color: '#61646C'}}>
        {text2}
      </Text>
    </View>

    <IconButton
      onPress={() => {}}
      source={iconSource}/>

  </View>

)

const FinishScreen = ({
  selectedStore,
  date,
  time,
  navigateToConfigurator,
}: any) => (

  <View style={styles.container}>
    <View style={styles.inner}>

      <View style={styles.hero}>
        <Text style={styles.headline} >
          Wir freuen uns auf Dich.
        </Text>
        <Text style={styles.text} >
          {`Wir sind sicher, dass Du bei uns Dein Traumkleid finden wirst.\nUnten findest Du noch einmal eine Zusammenfassung Deines Wunschtermins.\n\nWir prüfen Deine Anfrage und melden uns in Kürze bei Dir via E-Mail.`}
        </Text>
        <View style={styles.summary}>
          <Text style={styles.summaryTitle}>
            Dein Wunschtermin
          </Text>
          <SummaryItem
            text1={selectedStore && selectedStore.name}
            text2={selectedStore && selectedStore.street}
            iconSource={require('@assets/icons/icon-pin.svg' /*'@assets/icons/dark/pin.png'*/)}
          />
          <SummaryItem
            text1={date}
            text2={time + ' Uhr'}
            iconSource={require('@assets/icons/icon-cal.svg' /*'@assets/icons/dark/cal.png'*/)}
          />
        </View>
      </View>

      <View style={styles.btnContainer}>
        <Button
          onPress={navigateToConfigurator}
          label="Zurück zum Konfigurator"
        />
      </View>

    </View>
  </View>

)

const styles = StyleSheet.create({

  container: {
    flex: 1,
    paddingBottom: 24,
    paddingHorizontal: 24,
  },

  headline: {
    fontSize: TEXT_XL,
    fontFamily: UNNA_REG,
    lineHeight: LINE_HEIGHT_XL,
    color: COLOR_BLACK,
    fontWeight: '500',
    marginBottom: 25,
  },

  text: {
    fontSize: TEXT_MD,
    lineHeight: 23,
    fontFamily: MON_500,
    color: COLOR_GREY,
    marginBottom: 1,
  },

  flex: {
    flex: 1,
  },

  inner: {
    height: '100%',
    justifyContent: 'space-between',
  },

  hero: {
    marginHorizontal: 'auto',
  },

  summary: {
    marginTop: 40,
    paddingTop: 25,
    paddingBottom: 25,
    paddingHorizontal: 25,
    borderRadius: 22,
    backgroundColor: '#FFFFFF',
  },

  summaryTitle: {
    color: COLOR_BLACK,
    fontSize: TEXT_MD,
    fontFamily: MON_500,
    textAlign: 'center',
    paddingBottom: 20,
  },

  summaryItem: {
    marginVertical: TEXT_MD,
    fontWeight: '400',
    color: '#61646C',
    marginHorizontal: 0,
    flexDirection: 'row',
    alignItems: 'center',
  },

  btnContainer: {
  }

})

export default FinishScreen

import React from 'react'

import {
  StyleSheet,
  View,
} from 'react-native'

import Text from '@components/Text.component'
import Button from '@components/DarkButton.component'
import TextInput from '@components/TextInput.component'
import BusyScreen from '@components/BusyScreen.component'

import StoreList from './StoreList.component'

import {
  COLOR_BLACK,
  TEXT_MD,
  TEXT_XL,
  UNNA_REG,
  LINE_HEIGHT_XL,
} from "../../common/constants";

const StoresScreen = ({
  busy,
  formValues,
  formErrors,
  selectedStore,
  stores,
  setFormValue,
  findStores,
  setStore,
  applyStore,
}: any) => {

  return busy ?

    <BusyScreen message='Stores in der Nähe werden gesucht'/>

    :

    <View
      style={styles.container}>

      <Text
        style={styles.headline}>
        Suche einen Store in der Umgebung aus.
      </Text>

      <TextInput
        style={styles.searchBox}
        error={formErrors.location}
        inputProps={{
          value: formValues.location,
          placeholder: 'Dein Ort',
          onChangeText: (location: string) => setFormValue('stores', 'location', location),
          onSubmitEditing: findStores,
        }}
        buttonProps={{
          onPress: findStores,
          source: require('@assets/icons/icon-zoom.svg' /*'@assets/icons/dark/zoom.png'*/),
          marginRight: 0,
        }}/>

      <StoreList
        style={styles.storeList}
        stores={stores}
        selectedStore={selectedStore}
        setStore={setStore}/>

      <Button
        onPress={applyStore}
        label="Weiter zur Terminauswahl"
      />

    </View>

}

const styles = StyleSheet.create({

  container: {
    flex: 1,
    paddingBottom: 24,
    paddingHorizontal: 24,
  },

  headline: {
    fontSize: TEXT_XL,
    fontFamily: UNNA_REG,
    lineHeight: LINE_HEIGHT_XL,
    color: COLOR_BLACK,
    fontWeight: '500',
    marginBottom: 10,
  },

  searchBox: {
    marginTop: 25,
    borderRadius: 22,
    paddingLeft: 25,
    lineHeight: 40,
    color: '#61646C',
    fontSize: TEXT_MD,
    backgroundColor: '#fff',
    height: 54,
    fontWeight: '400'
  },

  storeList: {
    height: 0, // fix for overflow problem on web
    marginVertical: 25,
  },

})

export default StoresScreen

import React from 'react'

import {
  StyleSheet,
  View,
} from 'react-native'

import { Picker as NativePicker } from '@react-native-picker/picker'

import Picker from '@components/Picker.component'
import TextInput from '@components/TextInput.component'

const RegisterForm = ({
  formName,
  formValues,
  formErrors,
  salutations,
  countries,
  setFormValue,
  submit,
}: any) => (

  <View
    style={styles.container}>

    <Picker
      label='Anrede'
      selectedValue={formValues.salutationId}
      onValueChange={(itemValue, itemIndex) =>
        setFormValue(formName, 'salutationId', itemValue)
      }>
      {salutations.map((item: any) => <NativePicker.Item key={item.id} label={item.displayName} value={item.id} />)}
    </Picker>

    <TextInput
      label="Vorname"
      error={formErrors.firstName}
      inputProps={{
        value: formValues.firstName,
        onChangeText: (firstName: string) => setFormValue(formName, 'firstName', firstName),
        onSubmitEditing: submit,
      }}/>

    <TextInput
      label="Nachname"
      error={formErrors.lastName}
      inputProps={{
        value: formValues.lastName,
        onChangeText: (lastName: string) => setFormValue(formName, 'lastName', lastName),
        onSubmitEditing: submit,
      }}/>

    <TextInput
      label="Straße"
      error={formErrors.street}
      inputProps={{
        value: formValues.street,
        onChangeText: (street: string) => setFormValue(formName, 'street', street),
        onSubmitEditing: submit,
      }}/>

    <TextInput
      label="PLZ"
      error={formErrors.zipcode}
      inputProps={{
        value: formValues.zipcode,
        onChangeText: (zipcode: string) => setFormValue(formName, 'zipcode', zipcode),
        onSubmitEditing: submit,
      }}/>

    <TextInput
      label="Ort"
      error={formErrors.city}
      inputProps={{
        value: formValues.city,
        onChangeText: (city: string) => setFormValue(formName, 'city', city),
        onSubmitEditing: submit,
      }}/>

    <Picker
      label='Land'
      selectedValue={formValues.countryId}
      onValueChange={(itemValue, itemIndex) =>
        setFormValue(formName, 'countryId', itemValue)
      }>
      {countries.map((item: any) => <NativePicker.Item key={item.id} label={item.name} value={item.id} />)}
    </Picker>

    <TextInput
      label="Telefonnummer"
      error={formErrors.phoneNumber}
      inputProps={{
        value: formValues.phoneNumber,
        onChangeText: (phoneNumber: string) => setFormValue(formName, 'phoneNumber', phoneNumber),
        onSubmitEditing: submit,
      }}/>

    {/*}
    <TextInput
      label="Dress size:"
      inputProps={{
        value: formValues.dressSize,
        onChangeText: dressSize => setFormValue(formName, 'dressSize', dressSize)
      }}/>

    <TextInput
      containerStyle={{
        marginBottom: undefined,
      }}
      label="Wedding date:"
      inputProps={{
        value: formValues.weddingDate,
        onChangeText: weddingDate => setFormValue(formName, 'weddingDate', weddingDate)
      }}/>
    */}

  </View>

)

const styles = StyleSheet.create({

  container: {
    flex: 1,
    paddingHorizontal: 24,
  },

})

export default RegisterForm

import React from 'react'

import { connect } from 'react-redux'

import { State } from '@common/types'

import Stack from '@navigation/stack'

import LegalNoticeScreen from "@screens/Profile/Info/LegalNoticeScreen.component";

const mapStateToProps = ({
}: State) => ({
})

const mapDispatchToProps = {
}

export default function() {

  return (
    <Stack.Screen
      name={'LegalNotice'}
      component={connect(mapStateToProps, mapDispatchToProps)(LegalNoticeScreen)}
      options={{
        title:'Impressum',
      }}
    />
  )

}

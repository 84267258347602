import React from 'react'

import { connect } from 'react-redux'

import { State } from '@common/types'

import {
  navigateToConfigurator,
} from './FinishScreen.actions'

import Stack from '@navigation/stack'
import options from './FinishScreen.options'
import FinishScreen from './FinishScreen.component'

const mapStateToProps = ({
  storesScreen: {
    selectedStore,
  },
  appointmentScreen: {
    formValues: {
      date,
      time,
    },
  },
}: State) => ({
  selectedStore,
  date,
  time,
})

const mapDispatchToProps = {
  navigateToConfigurator,
}

export default function() {

  return (
    <Stack.Screen
      name='Finish'
      options={options}
      component={connect(mapStateToProps, mapDispatchToProps)(FinishScreen)}
    />
  )

}

import { call, put, select } from 'redux-saga/effects'

import http from '../../../axios'

import {
  Product,
} from '../ConfiguratorScreen.types'

import {
  mapProduct,
} from '@common/utils'

import {
  fetchProductsAttempt,
  fetchProductsSuccess,
  fetchProductsFailure,
} from '../ConfiguratorScreen.actions'

const mediaAssociation = {
  associations: {
    media: {
      associations: {
        thumbnails: {
          sort: [
            {
              field: 'width',
              order: 'ASC',
            },
          ],
        },
      },
    },
  },
  sort: [
    {
      field: 'position',
      order: 'ASC',
    },
  ],
}

const associations = {
  children: {
    associations: {
      media: mediaAssociation,
      options: {},
    },
    filter: [
      {
        type  : 'equals',
        field : 'active',
        value : 1,
      },
    ],
  },
  media: mediaAssociation,
  options: {},
}

const includes = {
  product               : ['id', 'calculatedPrice', 'productNumber', 'name', 'children', 'customFields', 'media', 'options'],
  calculated_price      : ['unitPrice'],
  product_media         : ['position', 'media'],
  media                 : ['url', 'thumbnails'],
  media_thumbnail       : ['url'],
  property_group_option : ['groupId', 'name', 'position', 'colorHexCode'],
}

const filter = [
  {
    type  : 'equals',
    field : 'parentId',
    value : null,
  },
]

const sort = [
  {
    field: 'productNumber',
    order: 'ASC',
  },
]

const mapProducts = (items: any[]): Product[] => items.map(item => mapProduct(item))

export default function* (layer: number, categoryId: string): any {

  try {

    const { dressSize } = yield select(state => state.profile)

    yield put(fetchProductsAttempt(layer))

    const { data } = yield call(http.post, '/product', {
      associations,
      includes,
      filter: [
        ...filter,
        {
          type: 'contains',
          field: 'categoryIds',
          value: categoryId,
        },
      ],
      sort,
    })

    if (__DEV__) {
      checkMediaPositions(data)
    }

    yield put(fetchProductsSuccess(layer, mapProducts(data.elements), dressSize))

  }
  catch (err) {

    yield call(console.error, err)

    yield put(fetchProductsFailure(layer, err))

  }

}

// helper function to check whether the media items are properly sorted

function checkMediaPositions(products: any[]) {

  const result = []

  function check(variant: any) {

    for (const i in variant.media) {

      if (variant.media[i].position != i) {

        result.push(variant)

        return;

      }

    }

  }

  for (const product of products.elements) {

    for (const variant of product.children) {

      check(variant)

    }

  }

  console.log(result)

}

import { call, put } from 'redux-saga/effects'

import {
  initSuccess,
  initFailure,
  setLayer,
} from '../ConfiguratorScreen.actions'

export default function* (): any {

  try {

    yield put(setLayer(0))

    yield put(initSuccess())

  }
  catch (err) {

    yield call(console.error, err)

    yield put(initFailure(err))

  }

}

import React from 'react'

import {
  StyleSheet,
  View,
  Image,
} from 'react-native'

import IconButton from '@components/IconButton.component'
import Text from '@components/Text.component'

import { formatCurrency } from '@common/utils'

import {
  COLOR_BLACK,
  MON_600,
  TEXT_MD,
  TEXT_13,
  COLOR_GREY,
  MON_700,
  FALLBACK_PRODUCT_COLOR,
} from "../../common/constants";

const Details = ({
  showPrices,
  layers,
  selectedItems,
  price,
  detailsVisible,
  setDetailsVisible,
}: any) => (

  <View
    style={styles.container}>

    <View
      style={styles.header}>

      <Text
        style={styles.headline}>
        Dein Look
      </Text>

      <IconButton
        onPress={setDetailsVisible}
        size={16}
        style={{
          marginLeft: 8,
        }}
        source={detailsVisible
          ? require('@assets/icons/icon-arrow-down.svg' /*'@assets/icons/dark/arrow_down.png'*/)
          : require('@assets/icons/icon-arrow-up.svg' /*'@assets/icons/dark/arrow_up.png'*/)
        }/>

    </View>

    <View
      style={[
        styles.content,
        { height: detailsVisible ? undefined : 0 }
      ]}>

      {layers.map((layer: string, index: number) => {

        const item = selectedItems[index]

        return item && (

          <View
            key={layer}
            style={styles.item}>

            <View
              style={styles.imageContainer}>

              <Image
                style={{
                  width: '100%',
                  height: '100%',
                }}
                source={{ uri: item.images[2] && item.images[2][0] }} />

            </View>

            <View
              style={styles.itemDetails}>

              <View
                style={styles.itemInner}>

                <Text
                  style={styles.itemTitle}>
                  {layer}
                </Text>

                {showPrices &&
                <Text style={styles.itemPrice}>
                  {item && formatCurrency(item.price)}
                </Text>
                }

              </View>

              <View
                style={{
                  marginTop: 8,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>

                <View
                  style={[
                    styles.itemColor,
                    {
                      borderColor: item.color ? item.color.colorHexCode : FALLBACK_PRODUCT_COLOR,
                      backgroundColor: item.color ? item.color.colorHexCode : FALLBACK_PRODUCT_COLOR,
                    }
                  ]}/>

                <Text>
                  {item && item.product.name}
                </Text>

              </View>

            </View>

          </View>
        )

      })}

      {showPrices &&
      <View
          style={styles.priceContainer}>

          <Text
              style={styles.gesamt}>
              Gesamt
          </Text>

          <Text
              style={styles.tax}>
              inkl. 19% MwSt.
          </Text>

          <Text
              style={styles.price}>
            {formatCurrency(price)}
          </Text>

      </View>
      }

    </View>

  </View>

)

const styles = StyleSheet.create({

  container: {
    borderRadius: 17,
    marginBottom: 30,
    padding: 20,
    backgroundColor: '#FFFFFF',
  },

  header: {
    height: 32,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  headline: {
    fontSize: TEXT_MD,
    color: COLOR_BLACK,
    fontFamily: MON_600,
  },

  content: {
    overflow: 'hidden',
  },

  item: {
    height: 64,
    marginVertical: 16,
    flexDirection: 'row',
  },
  itemInner: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemTitle: {
    fontFamily: MON_700,
    color: COLOR_BLACK,
    fontSize: TEXT_13
  },
  itemPrice: {
    fontFamily: MON_600,
    color: COLOR_GREY,
    fontSize: TEXT_13
  },
  itemImage: {
    width: 64,
    height: '100%',
    resizeMode: 'contain',
  },

  itemDetails: {
    flex: 1,
    marginVertical: 8,
    marginLeft: 16,
  },

  itemColor: {
    width: 14,
    height: 14,
    marginRight: 8,
    borderRadius: 4,
    borderWidth: 1,
    fontSize: TEXT_13,
  },

  priceContainer: {
    marginTop: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  gesamt: {
    flex: 1,
    fontSize: TEXT_MD,
    color: COLOR_BLACK,
    fontFamily: MON_600,
  },
  tax: {
    fontSize: 9,
    color: COLOR_GREY,
    fontWeight: '400'
  },

  price: {
    marginLeft: 16,
    fontSize: TEXT_MD,
    color: COLOR_BLACK,
    fontFamily: MON_600,
  },
  imageContainer: {
    width: 64,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ba9688',
    borderRadius: 16,
    overflow: 'hidden',
  },
})

export default Details

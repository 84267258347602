import React from 'react'

import {
  StyleSheet,
  PressableStateCallbackType, StyleProp, ViewStyle, Platform,
} from 'react-native'

import {
  COLOR_GREY_LIGHTER, COLOR_PILE,MON_700
} from '@common/constants'

import Text from '@components/Text.component'
import Button from '@components/Button.component'

const DarkButton = ({ onPress, label, style, pressedColor }) => (

  <Button
    onPress={onPress}
    style={({ pressed }: PressableStateCallbackType): StyleProp<ViewStyle> => ([
      styles.default,
      style,
      pressed && styles.btnPressed,
      pressed && {backgroundColor: pressedColor}
    ])}
    stylePressed={pressedColor}
    children={({ pressed }: PressableStateCallbackType) =>

      <Text
        style={[
          styles.buttonText,
          style,
          pressed && styles.buttonTextPressed,
        ]}>
        { label }
      </Text>

    } />

)

const styles = StyleSheet.create({

  default: {
    height: 54,
  },

  buttonText: {
    color: COLOR_PILE,
    fontFamily: MON_700,
    textAlign: 'center',
  },

  buttonTextPressed: {
    color: '#333538',
    backgroundColor: 'transparent'
  },

  btnPressed: {
    backgroundColor: COLOR_GREY_LIGHTER
  }

})

export default DarkButton

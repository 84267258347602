import React from 'react'

import {
  Platform,
  StyleSheet,
  View,
  Image,
} from 'react-native'

import {
  COLOR_BLACK,
  MON_500
} from '@common/constants'

import Text from '@components/Text.component'
import Button from '@components/Button.component'

const MenuButton = ({
  onPress,
  source,
  label
}: any) => (

  <Button
    onPress={onPress}
    style={styles.navBtn}
    children={
      <>
        <Image
          style={styles.icon}
          source={source}
        />
        <Text style={styles.label}>{label}</Text>
      </>
    }
  />

)

const ProfileMenuScreen = ({
  loggedIn,
  logout,
  navigation,
}: any) => (

  <View
    style={styles.container}>

    {loggedIn && <>

    <MenuButton
      onPress={() => navigation.navigate('ChangeEmail')}
      source={require('@assets/icons/icon-mail.svg' /*'@assets/icons/dark/mail.png'*/)}
      label="E-Mail ändern"/>

    <MenuButton
        onPress={() => navigation.navigate('ChangePassword')}
        source={require('@assets/icons/icon-key.svg' /*'@assets/icons/dark/key.png'*/)}
        label="Passwort ändern"/>

    <MenuButton
        onPress={() => navigation.navigate('ChangeProfile')}
        source={require('@assets/icons/icon-profile.svg' /*'@assets/icons/dark/profile.png'*/)}
        label="Profil ändern"/>

    </>}

    <MenuButton
      onPress={() => navigation.navigate('LegalNotice')}
      source={require('@assets/icons/icon-imprint.svg' /*'@assets/icons/dark/imprint.png'*/)}
      label="Impressum"/>

    <MenuButton
      onPress={() => navigation.navigate('PrivacyNotice')}
      source={require('@assets/icons/icon-data.svg' /*'@assets/icons/dark/data.png'*/)}
      label="Datenschutzerklärung"/>

    <MenuButton
      onPress={() => navigation.navigate('Terms')}
      source={require('@assets/icons/icon-agb.svg' /*'@assets/icons/dark/agb.png'*/)}
      label="AGB"/>

    {loggedIn &&
    <MenuButton
      onPress={logout}
      source={require('@assets/icons/icon-logout.svg' /*'@assets/icons/dark/logout.png'*/)}
      label="Abmelden"/>
    }

    {Platform.OS === 'web' &&
    <MenuButton
      onPress={() => document.querySelector('#open_cookie_preferences_center')?.click()}
      //source={require('@assets/icons/dark/logout.png')}
      label="Cookie-Einstellungen"/>
    }

  </View>

)

export default ProfileMenuScreen

const styles = StyleSheet.create({

  container: {
    flex: 1,
    paddingBottom: 24,
    paddingHorizontal: 24,
  },

  inner: {
    flex: 1,
  },

  navBtn: {
    width: '100&',
    textAlign: 'left',
    justifyContent: 'flex-start',
    backgroundColor: 'transparent',
    paddingHorizontal: 0,
  },

  imgContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24,
  },

  logoutContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  icon: {
    width: 24,
    height: 24,
    marginRight: 8,
  },

  label: {
    fontSize: 15,
    fontFamily: MON_500,
    color: COLOR_BLACK,
  },

})

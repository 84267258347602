export default {
  email: {
    presence: {
      allowEmpty: false,
      message: 'Bitte neue E-Mail eingeben',
    },
    email: {
      message: 'Bitte korrekte E-Mail eingeben',
    },
  },
  emailConfirmation: {
    presence: {
      allowEmpty: false,
      message: 'Bitte neue E-Mail bestätigen',
    },
    email: {
      message: 'Bitte korrekte E-Mail eingeben',
    },
  },
  password: {
    presence: {
      allowEmpty: false,
      message: 'Bitte Passwort eingeben',
    },
  },
}

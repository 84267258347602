import { connect } from 'react-redux'

import { State } from '@common/types'

import DateModal from './DateModal.component'

import {
  dateModalAccepted,
  dateModalDismissed,
} from '@modals/Date/DateModal.actions';

const mapStateToProps = ({
  dateModal: {
    visible,
    defaultDate,
  }
}: State) => ({
  visible,
  defaultDate,
})

const mapDispatchToProps = {
  dateModalAccepted,
  dateModalDismissed,
}

export default connect(mapStateToProps, mapDispatchToProps)(DateModal)

export default {
  username: {
    presence: {
      allowEmpty: false,
      message: 'Bitte Benutzer eingeben',
    },
  },
  password: {
    presence: {
      allowEmpty: false,
      message: 'Bitte Passwort eingeben',
    },
    length: {
      minimum: 8,
      message: 'Bitte mindesten 8 Zeichen eingeben',
    },
  },
}

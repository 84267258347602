import React from 'react'

import { connect } from 'react-redux'

import { State } from '@common/types'

import {
  setFormValue,
} from '@common/actions'

import {
  reset,
  requestPasswordRecoveryEmail,
  redeemPasswordRecoveryToken,
  changePassword,
} from './PasswordRecoveryScreen.actions'

import Stack from '@navigation/stack'
import options from './PasswordRecoveryScreen.options'
import PasswordRecoveryScreen from './PasswordRecoveryScreen.component'

import store from '@state/store'

const mapStateToProps = ({
  passwordRecoveryScreen: {
    busy,
    formValues,
    formErrors,
    error,
  },
}: State) => ({
  busy,
  formValues,
  formErrors,
  error,
})

const mapDispatchToProps = {
  setFormValue,
  requestPasswordRecoveryEmail,
  redeemPasswordRecoveryToken,
  changePassword,
}

export default function() {

  return (
    <Stack.Screen
      name='PasswordRecovery'
      options={options}
      component={connect(mapStateToProps, mapDispatchToProps)(PasswordRecoveryScreen)}
      initialParams={{ step: 1 }}
      getId={({ params }) => params.step}
      listeners={({ route }) => ({
        beforeRemove: () => store.dispatch(reset(route)),
      })}
    />
  )

}


export interface AppointmentScreenFormValues {
  date: string,
  time: string,
}

export interface AppointmentScreenFormErrors {

}

export interface AppointmentScreenState {
  busy: boolean,
  formValues: AppointmentScreenFormValues,
  formErrors: AppointmentScreenFormErrors,
}

export const RESET = 'AppointmentScreen.reset'

export const PICK_DATE = 'AppointmentScreen.pickDate'
export const PICK_TIME = 'AppointmentScreen.pickTime'

export const MAKE_APPOINTMENT = 'AppointmentScreen.makeAppointment'
export const MAKE_APPOINTMENT_SUCCESS = 'AppointmentScreen.makeAppointment.success'
export const MAKE_APPOINTMENT_FAILURE = 'AppointmentScreen.makeAppointment.failure'

export interface ResetAction {
  type: typeof RESET,
}

export interface PickDateAction {
  type: typeof PICK_DATE,
}

export interface PickTimeAction {
  type: typeof PICK_TIME,
}

export interface MakeAppointmentAction {
  type: typeof MAKE_APPOINTMENT,
}

export interface MakeAppointmentSuccessAction {
  type: typeof MAKE_APPOINTMENT_SUCCESS,
}

export interface MakeAppointmentFailureAction {
  type: typeof MAKE_APPOINTMENT_FAILURE,
  error: any,
}

export type AppointmentScreenActionType =
  ResetAction |
  PickTimeAction |
  PickDateAction |
  MakeAppointmentAction |
  MakeAppointmentSuccessAction |
  MakeAppointmentFailureAction

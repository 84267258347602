import React from 'react'

import { connect } from 'react-redux'

import { State } from '@common/types'

import {
  init,
  toggleLook,
  applyLooks,
} from './LooksScreen.actions'

import Stack from '@navigation/stack'
import options from './LooksScreen.options'
import LooksScreen from './LooksScreen.component'

import store from '@state/store'

const mapStateToProps = ({
  global: {
    loggedIn,
  },
  looksScreen: {
    busy,
    looks,
    selectedLooks,
  },
}: State) => ({
  loggedIn,
  busy,
  looks,
  selectedLooks,
})

const mapDispatchToProps = {
  init,
  toggleLook,
  applyLooks,
}

export default function() {

  return (
    <Stack.Screen
      name='Looks'
      options={options}
      component={connect(mapStateToProps, mapDispatchToProps)(LooksScreen)}
      listeners={{
        focus: () => store.dispatch(init()),
      }}
    />
  )

}

import React from 'react'

import { connect } from 'react-redux'

import { State } from '@common/types'

import {
  setFormValue,
} from '@common/actions'

import {
  init,
  findStores,
  setStore,
  applyStore,
} from './StoresScreen.actions'

import Stack from '@navigation/stack'
import options from './StoresScreen.options'
import StoresScreen from './StoresScreen.component'

import store from '@state/store'

const mapStateToProps = ({
  global: {
    loggedIn,
  },
  storesScreen: {
    busy,
    formValues,
    formErrors,
    stores,
    selectedStore,
  },
}: State) => ({
  loggedIn,
  busy,
  formValues,
  formErrors,
  selectedStore,
  stores,
})

const mapDispatchToProps = {
  setFormValue,
  findStores,
  setStore,
  applyStore,
}

export default function() {

  return (
    <Stack.Screen
      name='Stores'
      options={options}
      component={connect(mapStateToProps, mapDispatchToProps)(StoresScreen)}
      listeners={{
        focus: () => store.dispatch(init())
      }}
    />
  )

}

import { call, put, select } from 'redux-saga/effects'

import http from '../../../axios'

import {
  initSuccess,
  initFailure,
} from '../ChangeProfileScreen.actions'

export default function* () {

  try {

    const { data: salutations } = yield call(http.post, '/salutation')

    const { data: countries } = yield call(http.post, '/country')

    const profile = yield select(state => state.profile)

    yield put(initSuccess(salutations.elements, countries.elements, profile))

  }
  catch (err) {

    yield call(console.error, err)

    yield put(initFailure(err))

  }

}

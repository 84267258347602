
import { DefaultTheme } from '@react-navigation/native'

export default {
  ...DefaultTheme,
  dark: false,
  colors: {
    background: '#00000000',
  },
}

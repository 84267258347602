
import {
  TimeModalActionType as ActionType,
  SHOW_TIME_MODAL,
  TIME_MODAL_ACCEPTED,
  TIME_MODAL_DISMISSED,
} from './TimeModal.types';

export const showTimeModal = (message: string, time: string): ActionType => ({
  type: SHOW_TIME_MODAL,
  message,
  time,
})

export const timeModalAccepted = (time: string): ActionType => ({
  type: TIME_MODAL_ACCEPTED,
  time,
})

export const timeModalDismissed = (): ActionType => ({
  type: TIME_MODAL_DISMISSED,
})

import produce from 'immer'

import {
  ConfiguratorScreenState,
  ConfiguratorScreenActionType,
  Product,
  ProductVariant,
  ViewType,
  FETCH_PRODUCTS_ATTEMPT,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  SET_LAYER,
  SET_BROWSER_VISIBLE,
  SET_ITEM,
  SET_LOOK,
  SET_VIEW_TYPE,
  STORE_LOOK_SUCCESS,
} from './ConfiguratorScreen.types'

import {
  ProfileScreenActionType,
  CHANGE_DRESS_SIZE_SUCCESS,
  CHANGE_LOOK_NAME_SUCCESS,
  LOGOUT_SUCCESS,
} from '@screens/Profile/ProfileScreen.types'

const INITIAL_STATE: ConfiguratorScreenState = {
  layers: [
    'Basic Top',
    'Basic Rock',
    'Make Up Top',
    'Make Up Rock',
  ],
  products: [],
  browserVisible: true,
  browserLoading: [],
  selectedLayer: 0,
  selectedItems: [],
  price: 0,
  viewType: ViewType.FRONT,
}

const fittingSkirtShapes = {
  'skirtshape_a_line': ['skirtshape_a_line', 'skirtshape_train'],
  'skirtshape_light_a_line': ['skirtshape_light_a_line', 'skirtshape_a_line', 'skirtshape_train'],
  'skirtshape_slim': ['skirtshape_slim', 'skirtshape_light_a_line', 'skirtshape_a_line', 'skirtshape_train'],
  'skirtshape_mermaid': ['skirtshape_mermaid', 'skirtshape_train'],
  'skirtshape_90cm': ['skirtshape_90cm'],
  'skirtshape_60cm': ['skirtshape_60cm'],
  'skirtshape_pants': ['skirtshape_train'],
  'skirtshape_train': []
}
const fittingTopShapes = {
  'neckline_high': ['neckline_high'],
  'neckline_middle': ['neckline_high', 'neckline_middle'],
  'neckline_low': ['neckline_high', 'neckline_middle', 'neckline_low'],
  'neckline_body': []
}

const calcPrice = (items: ProductVariant[]) => items.filter(item => item).reduce((val, item) => val + item.price, 0)

function getPriceFactor(dressSize: number) {

  let factor = 1

  if (dressSize >= 50) factor = 1.2
  else
  if (dressSize >= 46) factor = 1.1

  return factor

}

function adjustPrices(products: Product[], factor: number) {

  products.forEach(product => {

    product.variants.forEach(variant => {

      variant.price = product.price * factor

    })

  })

}

function adjustAllPrices(draft: ConfiguratorScreenState, dressSize: number) {

  const factor = getPriceFactor(dressSize)

  draft.products.forEach(products => adjustPrices(products, factor))

  draft.selectedItems.forEach(item => {

    if (item) {

      item.price = item.product.price * factor

    }

  })

}

function getCorrespondingLayer(currentLayer: number) {

  let changingLayer = 5
  switch (currentLayer){
    case 0:
      changingLayer = 2
      break
    case 1:
      changingLayer = 3
      break
    case 2:
      changingLayer = 0
      break
    case 3:
      changingLayer = 1
      break
  }
  return changingLayer
}

function adjustCompatibility(products: Product[], shape: string, layer: number) {


  // @ts-ignore
  let func = ((product: Product, shape: string) => fittingTopShapes[product.shape].includes(shape))
  switch (layer) {
    case 0:
      // @ts-ignore
      func = ((product, shape) => fittingTopShapes[product.shape].includes(shape))
      break
    case 1:
      // @ts-ignore
      func = ((product, shape) => fittingSkirtShapes[product.shape].includes(shape))
      break
    case 2:
      // @ts-ignore
      func = ((product, shape) => fittingTopShapes[shape].includes(product.shape))
      break
    case 3:
      // @ts-ignore
      func = ((product, shape) => fittingSkirtShapes[shape].includes(product.shape))
      break
  }

  if(products) {
    products.forEach(product => {
      if(product.shape && shape) {
        product.enabled = func(product, shape)
      } else {
        product.enabled = !shape
      }
    })
  }

}

export default produce((
  draft: ConfiguratorScreenState,
  action: ConfiguratorScreenActionType|ProfileScreenActionType
) => {

  switch (action.type) {

    case FETCH_PRODUCTS_ATTEMPT:
      draft.browserLoading[action.layer] = true
      break

    case FETCH_PRODUCTS_SUCCESS:
      draft.browserLoading[action.layer] = false
      draft.products[action.layer] = action.products
      const correspondingLayer = getCorrespondingLayer(action.layer)
      if (draft.selectedItems[correspondingLayer]) {
        adjustCompatibility(draft.products[action.layer], draft.selectedItems[correspondingLayer].shape, action.layer)
      }
      adjustPrices(draft.products[action.layer], getPriceFactor(Number(action.dressSize)))
      break

    case FETCH_PRODUCTS_FAILURE:
      draft.browserLoading[action.layer] = false
      break

    case SET_LAYER:
      draft.selectedLayer = action.layer
      break

    case SET_BROWSER_VISIBLE:
      draft.browserVisible = action.visible
      break

    case SET_ITEM:

      if (action.item){
        const product = draft.products[draft.selectedLayer].find(product => product.id == action.item.product.id)
        if( product && !product.enabled) {
          break
        }
      }

      const changingLayer = getCorrespondingLayer(draft.selectedLayer)
      adjustCompatibility(draft.products[changingLayer], action.item ? action.item.shape : '', changingLayer)

      draft.selectedItems[draft.selectedLayer] = action.item
      draft.price = calcPrice(draft.selectedItems)
      break

    case SET_LOOK:
      draft.selectedLayer = 0
      draft.selectedItems = action.look.items
      draft.look = action.look
      draft.price = calcPrice(draft.selectedItems)
      break

    case SET_VIEW_TYPE:
      draft.viewType = action.viewType
      break

    case STORE_LOOK_SUCCESS:
      draft.look = action.look
      break

    case CHANGE_DRESS_SIZE_SUCCESS:
      adjustAllPrices(draft, Number(action.dressSize))
      draft.price = calcPrice(draft.selectedItems)
      break

    case CHANGE_LOOK_NAME_SUCCESS:
      if (draft.look && draft.look.id === action.lookId) {
        draft.look.name = action.name
      }
      break

    case LOGOUT_SUCCESS:
      adjustAllPrices(draft, 0)
      draft.browserVisible = INITIAL_STATE.browserVisible
      draft.selectedItems = INITIAL_STATE.selectedItems
      draft.look = undefined
      draft.price = INITIAL_STATE.price
      draft.viewType = INITIAL_STATE.viewType
      break

  }

}, INITIAL_STATE)

import { call, put } from 'redux-saga/effects'

import http from '../../../axios'

import {
  initSuccess,
  initFailure,
} from '../RegisterScreen.actions'

export default function* () {

  try {

    const { data: salutations } = yield call(http.post, '/salutation')

    const { data: countries } = yield call(http.post, '/country')

    yield put(initSuccess(salutations.elements, countries.elements))

  }
  catch (err) {

    yield call(console.error, err)

    yield put(initFailure(err))

  }

}

import React from 'react'

import { connect } from 'react-redux'

import { State } from '@common/types'

import {
  init,
  changeDressSize,
  changeWeddingDate,
  setListType,
  loadLook,
  changeLookName,
  deleteLook,
} from './ProfileScreen.actions'

import {
  makeAppointment,
} from '@common/actions'

import Stack from '@navigation/stack'
import options from './ProfileScreen.options'
import ProfileScreen from './ProfileScreen.component'

import store from '@state/store'

const mapStateToProps = ({
  global: {
    loggedIn,
  },
  profileScreen: {
    listType,
    looks,
  },
  configuratorScreen: {
    look: activeLook,
  },
  profile: {
    dressSize,
    weddingDate,
  },
}: State) => ({
  loggedIn,
  listType,
  activeLook,
  looks,
  dressSize,
  weddingDate,
})

const mapDispatchToProps = {
  changeDressSize,
  changeWeddingDate,
  setListType,
  loadLook,
  changeLookName,
  deleteLook,
  makeAppointment,
}

export default function() {

  return (
    <Stack.Screen
      name='Profile'
      options={options}
      component={connect(mapStateToProps, mapDispatchToProps)(ProfileScreen)}
      listeners={{
        focus: () => store.dispatch(init())
      }}
    />
  )

}

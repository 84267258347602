import React from 'react'

import {
  StyleSheet,
  ScrollView,
} from 'react-native'

import Text from '@components/Text.component'

const PrivacyNoticeScreen = () => (

  <ScrollView
    style={styles.container}>

    <Text>

      <Text style={styles.strong}>Herzlich Willkommen auf unserer Webseite!</Text>

      Wir legen größten Wert auf den Schutz Ihrer Daten und die Wahrung Ihrer Privatsphäre. Nachstehend informieren wir Sie deshalb über die Erhebung und Verwendung persönlicher Daten bei Nutzung unserer Webseite.

      <Text style={styles.strong}>Anonyme Datenerhebung</Text>

      Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Wir speichern in diesem Zusammenhang keinerlei persönliche Daten. Um unser Angebot zu verbessern, werten wir lediglich statistische Daten aus, die keinen Rückschluss auf Ihre Person erlauben.

      Erhebung und Verarbeitung bei Nutzung des Kontaktformulars
      Bei Nutzung des Kontaktformulars erheben wir personenbezogene Daten (Einzelangaben über persönliche oder sachliche Verhältnisse einer bestimmten oder bestimmbaren natürlichen Person) nur in dem von Ihnen zur Verfügung gestellten Umfang. Ihre Emailadresse nutzen wir nur zur Bearbeitung Ihrer Anfrage. Ihre Daten werden anschließend gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.

      <Text style={styles.strong}>Erhebung, Verarbeitung und Nutzung personenbezogener Daten</Text>

      Wir erheben personenbezogene Daten (Einzelangaben über persönliche oder sachliche Verhältnisse einer bestimmten oder bestimmbaren natürlichen Person) nur in dem von Ihnen zur Verfügung gestellten Umfang.
      Die Verarbeitung und Nutzung Ihrer personenbezogenen Daten erfolgt zur Erfüllung und Abwicklung Ihrer Bestellung sowie zur Bearbeitung Ihrer Anfragen.
      Nach vollständiger Vertragsabwicklung werden alle personenbezogenen Daten zunächst unter Berücksichtigung steuer- und handelsrechtlicher Aufbewahrungsfristen gespeichert und dann nach Fristablauf gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.

      <Text style={styles.strong}>Zusätzlich weisen wir auf Folgendes hin: </Text>

      Wir verwenden das Käufersiegel-Bewertungssystem. Nach Ihrer Bestellung möchten wir Sie bitten, Ihren Kauf bei uns zu bewerten und zu kommentieren. Zu diesem Zweck werden Sie von uns im Rahmen der Vertragsabwicklung angeschrieben, wobei wir uns hierbei des technischen Systems des Anbieters des Käufersiegel-Bewertungstools, der Händlerbund Management AG, Essener Str. 39, 04357 Leipzig, im Rahmen einer Auftragsdatenverarbeitung bedienen. Ihre E-Mailadresse wird dabei nur zu diesem Zweck und insbesondere nicht zu weitergehender Werbung benutzt und auch nicht an sonstige Dritte weitergegeben. Die in diesem Zusammenhang im technischen System des Käufersiegel-Bewertungstools gespeicherten personenbezogenen Daten werden 3 Monate nach der zur Bewertung erfassten Warenlieferung gelöscht.

      <Text style={styles.strong}>Weitergabe personenbezogener Daten</Text>

      Eine Weitergabe Ihrer Daten an Dritte ohne Ihre ausdrückliche Einwilligung erfolgt nicht. Ausgenommen hiervon sind lediglich unsere Dienstleistungspartner, die wir zur Abwicklung des Vertragsverhältnisses benötigen. In diesen Fällen beachten wir strikt die Vorgaben des Bundesdatenschutzgesetzes. Der Umfang der Datenübermittlung beschränkt sich auf ein Mindestmaß.

      <Text style={styles.strong}>Cookies</Text>

      Unsere Internetseiten verwenden an mehreren Stellen so genannte Cookies. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Sie dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Des Weiteren ermöglichen Cookies unseren Systemen, Ihren Browser zu erkennen und Ihnen Services anzubieten. Cookies enthalten keine personenbezogenen Daten.

      <Text style={styles.strong}>Verwendung der Remarketing- oder "Ähnliche Zielgruppen"-Funktion der Google Inc.</Text>

      Der Anbieter verwendet auf der Webseite die Remarketing- oder "Ähnliche Zielgruppen"- Funktion der Google Inc. („Google“). Mittels dieser Funktion kann der Anbieter die Besucher der Webseite zielgerichtet mit Werbung ansprechen, indem für Besucher der Webseite des Anbieters personalisierte, interessenbezogene Werbung-Anzeigen geschaltet werden, wenn sie andere Webseiten im Google Display-Netzwerk besuchen. Zur Durchführung der Analyse der Webseiten- Nutzung, welche die Grundlage für die Erstellung der interessenbezogenen Werbeanzeigen bildet, setzt Google sog. Cookies ein. Hierzu speichert Google eine kleine Datei mit einer Zahlenfolge in den Browsern der Besucher der Webseite. Über diese Zahl werden die Besuche der Webseite sowie anonymisierte Daten über die Nutzung der Webseite erfasst. Es erfolgt keine Speicherung von personenbezogenen Daten der Besucher der Webseite. Besuchen Sie nachfolgend eine andere Webseite im Google Display-Netzwerk werden Ihnen Werbeeinblendungen angezeigt, die mit hoher Wahrscheinlichkeit zuvor aufgerufene Produkt- und Informationsbereiche berücksichtigen.
      Sie können die Verwendung von Cookies durch Google dauerhaft deaktivieren, indem Sie dem nachfolgenden Link folgen und das dort bereitgestellte Plug-In herunterladen und installieren:
      https://www.google.com/settings/ads/plugin?hl=de

      Alternativ können Sie die Verwendung von Cookies durch Drittanbieter deaktivieren, indem sie die Deaktivierungsseite der Netzwerkwerbeinitiative (Network Advertising Initiative) unter http://www.networkadvertising.org/choices/ aufrufen und die dort genannten weiterführenden Information zum Opt-Out umsetzen.
      Weiterführende Informationen zu Google Remarketing sowie die Datenschutzerklärung von Google können Sie einsehen unter: ??http://www.google.com/privacy/ads/

      <Text style={styles.strong}>Nutzung von Google Analytics</Text>

      Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren [http://tools.google.com/dlpage/gaoptout?hl=de].

      <Text style={styles.strong}>Verwendung von Facebook-Plugins</Text>

      Auf diesen Internetseiten werden Plugins des sozialen Netzwerkes facebook.com verwendet, das von der Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA betrieben wird ("Facebook" ).
      Wenn Sie mit einen solchen Plugin versehene Internetseiten unserer Internetpräsenz aufrufen, wird eine Verbindung zu den Facebook-Servern hergestellt und dabei das Plugin durch Mitteilung an Ihren Browser auf der Internetseite dargestellt. Hierdurch wird an den Facebook-Server übermittelt, welche unserer Internetseiten Sie besucht haben. Sind Sie dabei als Mitglied  bei Facebook eingeloggt, ordnet Facebook  diese Information Ihrem persönlichen Facebook-Benutzerkonto zu. Bei der Nutzung der Plugin-Funktionen (z.B. Anklicken des „Gefällt mir“-Buttons, Abgabe eines Kommentars) werden auch diese Informationen Ihrem Facebook-Konto zugeordnet, was Sie nur durch Ausloggen vor Nutzung des Plugins verhindern können. Nähere Informationen zur Erhebung und Nutzung der Daten durch Facebook, über Ihre diesbezüglichen Rechte und Möglichkeiten zum Schutz Ihrer Privatsphäre finden Sie in den Datenschutzhinweisen von Facebook.

      <Text style={styles.strong}>Verwendung der Google „+1“-Schaltfläche</Text>

      Auf diesen Internetseiten wird die „+1“-Schaltfläche des sozialen Netzwerkes Google Plus der Google Inc., 1600 Amphitheatre Parkway, Mountain View, Kalifornien, 94043 USA, (nachfolgend „Google“) verwendet. Wenn Sie eine mit der „+1“-Schaltfläche versehene Internetseite unserer Internetpräsenz aufrufen, wird eine Verbindung zu den Google-Servern in den USA hergestellt und dabei die Schaltfläche durch Mitteilung an Ihren Browser auf der Internetseite dargestellt. Hierbei werden sowohl Ihre IP-Adresse als auch die Information, welche unserer Internetseite Sie besucht haben, an den Google-Server übermittelt. Dies gilt unabhängig davon, ob Sie bei Google Plus registriert bzw. eingeloggt sind. Auch bei nicht registrierten bzw. nicht eingeloggten Nutzern findet eine Übermittlung statt. Die „+1“-Schaltfläche wird nicht verwendet, um Ihre Besuche im Internet zu erfassen. Google protokolliert beim Anzeigen einer „+1“-Schaltfläche nicht dauerhaft Ihren Browserverlauf und wertet Ihren Besuch auf einer Seite mit „+1“-Schaltfläche auch nicht in anderer Weise aus. Google speichert etwa zwei Wochen Daten über Ihren Besuch zu Systemwartungs- und Fehlerbehebungszwecken. Diese Daten sind jedoch nicht nach individuellen Profilen, Nutzernamen oder URLs strukturiert und werden auch nicht an uns weitergeleitet.
      Sind Sie darüber hinaus Mitglied bei Google Plus und während des Zeitpunktes, indem Sie das Plugin nutzen, bei Google Plus eingeloggt, werden die gesammelten Informationen über Ihren Websitebesuch mit Ihrem Google Plus Account verknüpft und anderen Nutzern bekannt gegeben. Auch im Falle von Interaktionen, die mit den verschiedenen Google Plugins möglich sind, werden die entsprechenden Informationen über Sie gesammelt und an Google übermittelt und gespeichert. Eine Übersicht über die verschiedenen Arten der Google Plugins können Sie hier einsehen:
      https://developers.google.com/+/plugins

      Falls Sie in den Einstellungen von Google Plus Ihr Profil öffentlich zugänglich gemacht haben, können Ihre „+1“ von Google als Hinweise zusammen mit Ihrem Profilnamen und Ihrem Foto in Google-Diensten, wie etwa in Suchergebnissen oder in Ihrem Google-Profil, oder an anderen Stellen auf Websites und Anzeigen im Internet eingeblendet werden. Wenn Sie nicht möchten, dass Google die gesammelten Informationen unmittelbar Ihrem Google Plus Profil zuordnet, müssen Sie sich vor dem Besuch unserer Seite bei Google Plus ausloggen.
      Nähere Informationen zur Erhebung und Nutzung der Daten durch Google, über Ihre diesbezüglichen Rechte und Möglichkeiten zum Schutz Ihrer Privatsphäre finden Sie in den Datenschutzhinweisen von Google: www.google.com/intl/de/+/policy/+1button.html.

      Sie haben auch die Möglichkeit durch Installation entsprechender Add-Ons in Ihrem Browser, das Laden der Google Plugins zu verhindern.

      Auskunft, Berichtigung, Sperrung und Löschung von Daten
      Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten Daten sowie das Recht auf Berichtigung, Löschung bzw. Sperrung. Kontaktieren Sie uns auf Wunsch. Die Kontaktdaten finden Sie in unserem Impressum.

    </Text>

  </ScrollView>

)

export default PrivacyNoticeScreen

const styles = StyleSheet.create({

  container: {
    flex: 1,
    paddingBottom: 24,
    paddingHorizontal: 24,
  },

  strong: {
    display: 'flex',
    marginVertical: 8,
    fontWeight: '700',
    width: '100%',
  },

})

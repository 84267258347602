import React from 'react'

import { connect } from 'react-redux'

import { State } from '@common/types'

import { logout } from '../ProfileScreen.actions'

import Stack from '@navigation/stack'
import ProfileMenuScreen from './MenuScreen.component'

const mapStateToProps = ({
  global: {
    loggedIn,
  },
}: State) => ({
  loggedIn,
})

const mapDispatchToProps = {
  logout,
}

export default function() {

  return (
    <Stack.Screen
      name={'ProfileMenu'}
      component={connect(mapStateToProps, mapDispatchToProps)(ProfileMenuScreen)}
      options={{
        title: 'Menü',
      }}
    />
  )

}

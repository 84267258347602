
import {
  PromptModalActionType as ActionType,
  SHOW_PROMPT_MODAL,
  PROMPT_MODAL_ACCEPTED,
  PROMPT_MODAL_DISMISSED,
  SET_INPUT_VALUE
} from './PromptModal.types';

export const showPromptModal = (message: string, dismissLabel: string, acceptLabel: string, defaultValue= ''): ActionType => ({
  type: SHOW_PROMPT_MODAL,
  message,
  dismissLabel,
  acceptLabel,
  defaultValue,
})

export const promptModalAccepted = (input: string): ActionType => ({
  type: PROMPT_MODAL_ACCEPTED,
  input,
})

export const promptModalDismissed = (): ActionType => ({
  type: PROMPT_MODAL_DISMISSED,
})

export const setInputValue = (input: string): ActionType => ({
  type: SET_INPUT_VALUE,
  input,
})

import { call, put, select } from 'redux-saga/effects'

import {
  initSuccess,
  initFailure,
} from '../ProfileScreen.actions'

import fetchLooks from './fetchLooks.saga'

export default function* (): any {

  try {

    const {
      global: {
        loggedIn,
      },
      profile: {
        dressSize,
      },
    } = yield select(state => state)

    if (!loggedIn) {

      return

    }

    const looks = yield call(fetchLooks)

    yield put(initSuccess(looks, dressSize))

  }
  catch (err) {

    yield call(console.error, err)

    yield put(initFailure(err))

  }

}


export interface AlertModalState {
  visible: boolean,
  message: string,
  dismissLabel: string,
}

export const SHOW_ALERT_MODAL = 'SHOW_ALERT_MODAL'
export const ALERT_MODAL_DISMISSED = 'ALERT_MODAL_DISMISSED'

export interface ShowAlertModalAction {
  type: typeof SHOW_ALERT_MODAL,
  message: string,
  dismissLabel: string,
}

export interface AlertModalDismissedAction {
  type: typeof ALERT_MODAL_DISMISSED,
}

export type AlertModalActionType =
  ShowAlertModalAction |
  AlertModalDismissedAction

import React from 'react'

import {
  PressableStateCallbackType,
  StyleSheet,
  View,
} from 'react-native'

import {
  BROWSER_WIDTH,
  COLOR_BLACK,
  COLOR_GREY_LIGHTER
} from '@common/constants'

import { ViewType } from '../ConfiguratorScreen.types'

import IconButton from '@components/IconButton.component'

import Browser from './Browser.component'
import Preview from './PreviewEx.component'
import ColorSwitch from './ColorSwitch.component'

const Configurator = ({
  showPrices,
  browserVisible,
  browserLoading,
  layers,
  products,
  selectedLayer,
  selectedItems,
  viewType,
  setItem,
  hideBrowser,
  showBrowser,
  shareLook,
  setViewType,
}: any) => {

  function getPrevItem() {
    if (selectedItems[selectedLayer]) {
      const index = products[selectedLayer].findIndex(item => item.id === selectedItems[selectedLayer].product.id)
      if (index > 0) {
        return products[selectedLayer][index - 1]
      }
    }
  }

  function getNextItem() {
    if (selectedItems[selectedLayer]) {
      const index = products[selectedLayer].findIndex(item => item.id === selectedItems[selectedLayer].product.id)
      if (index < products[selectedLayer].length - 1)
        return products[selectedLayer][index + 1]
    }
  }

  function prevItem() {
    const item = getPrevItem()
    item && setItem(item.variants[0])
  }

  function nextItem() {
    const item = getNextItem()
    item && setItem(item.variants[0])
  }

  function getSelectedProduct() {
    if (products[selectedLayer] && selectedItems[selectedLayer]) {
      return products[selectedLayer].find(product => product.id === selectedItems[selectedLayer].product.id)
    }
  }

  return (

  <View style={styles.container}>

    <View
      style={[
        styles.preview,
        { right: browserVisible ? BROWSER_WIDTH : 0 }
      ]}>

      <Preview
        browserVisible={browserVisible}
        layers={layers}
        selectedItems={selectedItems}
        viewType={viewType}
        prevItem={prevItem}
        nextItem={nextItem}/>

      {!browserVisible &&
      <IconButton
        style={styles.btnAdd}
        onPress={showBrowser}
        color={'#fff'}
        colorPressed={'#313234'}
        size={16}
        source={require('@assets/icons/icon-chevron-left.svg' /*'@assets/icons/dark/arrow_left.png'*/)} />
      }

      {!browserVisible &&
      <IconButton
        style={{
          position: 'absolute',
          top: 16,
          left: 16,
        }}
        onPress={shareLook}
        source={require('@assets/icons/icon-share.svg' /*'@assets/icons/dark/share.png'*/)} />
      }

      {selectedItems[selectedLayer] && !browserVisible && <>

      {getPrevItem() &&
      <IconButton
        style={{
          position: 'absolute',
          top: '50%',
          left: 16,
        }}
        onPress={prevItem}
        source={require('@assets/icons/icon-chevron-left.svg' /*'@assets/icons/dark/arrow_left.png'*/)} />
      }

      {getNextItem() &&
      <IconButton
        style={{
          position: 'absolute',
          top: '50%',
          right: 16,
        }}
        onPress={nextItem}
        source={require('@assets/icons/icon-chevron-right.svg' /*'@assets/icons/dark/arrow_left.png'*/)} />
      }

      </>}

      <IconButton
        style={({ pressed }: PressableStateCallbackType) => ([
          styles.switchBtn,
          pressed && styles.switchBtnPressed,
          browserVisible ? { left: 16 } : { right: 16 },
        ])}
        onPress={() => setViewType(viewType === ViewType.FRONT ? ViewType.BACK : ViewType.FRONT)}
        source={({ pressed }: PressableStateCallbackType) => pressed
          ? require('@assets/icons/frontBackSwitch.svg' /*'@assets/icons/dark/frontBackSwitch.png'*/)
          : require('@assets/icons/frontBackSwitch-white.svg' /*'@assets/icons/white/frontBackSwitch.png'*/)} />

    </View>

    <Browser
      style={[
        styles.browser,
        { right: browserVisible ? 0 : -BROWSER_WIDTH }
      ]}
      loading={browserLoading[selectedLayer]}
      showPrices={showPrices}
      layers={layers}
      selectedLayer={selectedLayer}
      selectedItems={selectedItems}
      items={products[selectedLayer]}
      setItem={setItem}
      hide={hideBrowser}
      show={showBrowser}/>

    {!browserVisible && selectedItems[selectedLayer] &&
    <ColorSwitch
      style={styles.colorSwitch}
      itemSize={32}
      spacing={16}
      product={getSelectedProduct()}
      selectedVariant={selectedItems[selectedLayer]}
      onPress={setItem}/>
    }

  </View>

  )

}

const styles = StyleSheet.create({

  container: {
    flex: 1,
    overflow: 'hidden',
  },

  preview: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  browser: {
    position: 'absolute',
    width: BROWSER_WIDTH,
    height: '100%',
  },

  switchBtn: {
    position: 'absolute',
    bottom: 16,
    backgroundColor: COLOR_BLACK,
  },

  switchBtnPressed: {
    backgroundColor: COLOR_GREY_LIGHTER,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.15,
    shadowRadius: 20,
  },

  btnAdd: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'flex-start',
    right: 0,
    top: 24,
    zIndex: 1,
    width: 72,
    backgroundColor: '#fff',
    height: 44,
    borderRadius: 16,
    paddingLeft: 28,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },

  colorSwitch: {
    position: 'absolute',
    top: 96,
    right: 0,
    padding: 20,
    borderRadius: 16,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: 'white',
  },

})

export default Configurator


import { takeLatest } from 'redux-saga/effects'

import {
  INIT,
  REGISTER,
  PICK_DATE,
} from './RegisterScreen.types'

import init from './sagas/init.saga'
import register from './sagas/register.saga'
import pickDate from "./sagas/pickDate.saga";

export default function* () {

  yield takeLatest(INIT, init)

  yield takeLatest(REGISTER, register)

  yield takeLatest(PICK_DATE, pickDate)

}

import React from 'react'

import { connect } from 'react-redux'

import { State } from '@common/types'

import {
  setFormValue,
} from '@common/actions'

import {
  reset,
  changePassword,
} from './ChangePasswordScreen.actions'

import Stack from '@navigation/stack'
import options from './ChangePasswordScreen.options'
import ChangePasswordScreen from './ChangePasswordScreen.component'

import store from '@state/store'

const mapStateToProps = ({
  changePasswordScreen: {
    busy,
    formValues,
    formErrors,
    error,
  },
}: State) => ({
  busy,
  formValues,
  formErrors,
  error,
})

const mapDispatchToProps = {
  setFormValue,
  changePassword,
}

export default function() {

  return (
    <Stack.Screen
      name='ChangePassword'
      options={options}
      component={connect(mapStateToProps, mapDispatchToProps)(ChangePasswordScreen)}
      listeners={{
        beforeRemove: () => store.dispatch(reset()),
      }}
    />
  )

}

import produce from 'immer'

import {
  PromptModalState,
  PromptModalActionType,
  SHOW_PROMPT_MODAL,
  PROMPT_MODAL_ACCEPTED,
  PROMPT_MODAL_DISMISSED,
  SET_INPUT_VALUE,
} from './PromptModal.types'

const INITIAL_STATE: PromptModalState = {
  visible: false,
  message: '',
  acceptLabel: '',
  dismissLabel: '',
  inputValue: '',
}

export default produce((draft: PromptModalState, action: PromptModalActionType) => {

  switch (action.type) {

    case SET_INPUT_VALUE:
      draft.inputValue = action.input
      break

    case PROMPT_MODAL_ACCEPTED:
      draft.visible = false
      break

    case PROMPT_MODAL_DISMISSED:
      draft.visible = false
      break

    case SHOW_PROMPT_MODAL:
      draft.visible = true
      draft.message = action.message
      draft.dismissLabel = action.dismissLabel
      draft.acceptLabel = action.acceptLabel
      draft.inputValue = action.defaultValue
      break
  }

}, INITIAL_STATE)

import { call } from 'redux-saga/effects'

import { navigate } from '@navigation/root'

export default function* () {

  try {

    yield call(navigate, 'PasswordRecovery', { step: 2 })

  }
  catch (err) {

    yield call(console.error, err)

  }

}

import React from 'react'

import {
  StyleSheet,
  ScrollView,
  View,
} from 'react-native'

import Text from '@components/Text.component'

const LegalNoticeScreen = () => (

  <ScrollView
    style={styles.container}>

    <View>
      <Text style={styles.headline}>LeMoos GmbH</Text>
      <Text style={styles.item}>Amtsgericht Bonn I HRB25143</Text>
      <Text style={styles.item}>UST / VAT-ID: DE328859359</Text>
    </View>

    <Text style={styles.strong}>Geschäftsührerin: Helena Moos</Text>

    <View>
      <Text style={styles.item}>Frauenberger Str. 119</Text>
      <Text style={styles.item}>53879 Euskirchen</Text>
      <Text style={styles.item}>Tel.: 02251-12 92 00</Text>
      <Text style={styles.item}>E-Mail: info@lemoos.de</Text>
    </View>

    <Text style={styles.strong}>copyright 2021  ®Lemoos  ®miXme</Text>

    <Text style={styles.item}>
      Wir weisen darauf hin, dass wir den Inhalt unserer Website mit größter Sorgfalt erstellt haben. Für eventuelle Fehlinformationen oder Irrtümer wird keine Haftung übernommen.
    </Text>

    <Text style={styles.strong}>Links</Text>

    <Text style={styles.item}>
    Sie finden auf unserer Website Links zu Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb schließen wir jede Verantwortung für deren Inhalte aus. Für die Inhalte der verlinkten Seiten ist der jeweilige Anbieter oder Betreiber der Seite verantwrotlich. Wir distanzieren uns hiermit ausdrücklich von allen Inhalten aller verlinkten Seiten auf unserer Webseite und machen uns ihre Inhalte nicht zueigen.
    </Text>

    <Text style={styles.strong}>Markenrechtliche Hinweise</Text>

    <Text style={styles.item}>
    Diese Website enthält Hinweise auf Firmen- und  Produktnamen anderer Firmen. Die verwendeten Begriffe, Fotos und Texte sind Warenzeichen und/oder eingetragene Warenzeichen der entsprechenden Firmen.
    </Text>

  </ScrollView>

)

export default LegalNoticeScreen

const styles = StyleSheet.create({

  container: {
    flex: 1,
    paddingBottom: 24,
    paddingHorizontal: 24,
  },

  headline: {
    fontSize: 15,
    fontWeight: '600',
    marginBottom: 8,
  },

  strong: {
    display: 'flex',
    marginVertical: 8,
    fontWeight: '600',
    width: '100%',
  },

  item: {
    flex: 1,
  },

})

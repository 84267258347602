import React from 'react'

import { connect } from 'react-redux'

import { State } from '@common/types'

import {
  setFormValue,
} from '@common/actions'

import {
  reset,
  changeEmail,
} from './ChangeEmailScreen.actions'

import Stack from '@navigation/stack'
import options from './ChangeEmailScreen.options'
import ChangeEmailScreen from './ChangeEmailScreen.component'

import store from '@state/store'

const mapStateToProps = ({
  changeEmailScreen: {
    busy,
    formValues,
    formErrors,
    error,
  },
  profile: {
    email,
  }
}: State) => ({
  busy,
  formValues,
  formErrors,
  error,
  email,
})

const mapDispatchToProps = {
  setFormValue,
  changeEmail,
}

export default function() {

  return (
    <Stack.Screen
      name='ChangeEmail'
      options={options}
      component={connect(mapStateToProps, mapDispatchToProps)(ChangeEmailScreen)}
      listeners={{
        beforeRemove: () => store.dispatch(reset()),
      }}
    />
  )

}

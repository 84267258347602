
import produce from 'immer'

import {
  CommonActionType,
  SET_FORM_VALUE,
  SET_FORM_ERRORS,
} from '@common/types'

import {
  ChangePasswordScreenState,
  ChangePasswordScreenActionType,
  SET_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  RESET,
} from './ChangePasswordScreen.types'

const INITIAL_STATE: ChangePasswordScreenState = {
  busy: false,
  formValues: {
    password: '',
    newPassword: '',
    newPasswordConfirm: '',
  },
  formErrors: {
  },
}

export default produce((
  draft: ChangePasswordScreenState,
  action: CommonActionType|ChangePasswordScreenActionType
) => {

  switch (action.type) {

    case RESET:
      return INITIAL_STATE

    case SET_FORM_VALUE:
      if (action.form === 'changePassword') {
        draft.formValues[action.key] = action.val
      }
      break

    case SET_FORM_ERRORS:
      if (action.form === 'changePassword') {
        draft.formErrors = action.errors
      }
      break

    case SET_ERROR:
      draft.error = action.error
      break

    case CHANGE_PASSWORD:
      draft.busy = true
      break

    case CHANGE_PASSWORD_SUCCESS:
      draft.busy = false
      break

    case CHANGE_PASSWORD_FAILURE:
      draft.busy = false
      break

  }

}, INITIAL_STATE)

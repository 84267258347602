import {call, put, select, take} from 'redux-saga/effects'

import {
  setFormValue,
} from '@common/actions'
import {showTimeModal} from "@modals/Time/TimeModal.actions";
import {TIME_MODAL_ACCEPTED, TIME_MODAL_DISMISSED} from "@modals/Time/TimeModal.types";

export default function* () {

  try {

    const { time } =  yield select(state => state.appointmentScreen.formValues)

    yield put(showTimeModal('Bitte wähle eine Uhrzeit:', time))

    const result = yield take([TIME_MODAL_DISMISSED, TIME_MODAL_ACCEPTED])

    if (result.type === TIME_MODAL_DISMISSED && !result.date) {

      return

    }
    yield put(setFormValue('appointment', 'time', result.time))

  }
  catch (err) {

    yield call(console.error, err)


  }

}

import React from 'react'

import {
  Image,
  StyleSheet,
  View,
} from 'react-native'

import {
  COLOR_GREY,
  COLOR_BLACK,
  TEXT_SM,
  MON_600, MON_500
} from '@common/constants'

import Text from '@components/Text.component'

const Message = ({
  message,
}: any) => (

  <View
    style={styles.container}>
    <View style={styles.inner}>
      <Image
        style={styles.image}
        source={require('@assets/icons/icon-info.svg' /*'@assets/icons/dark/info.png'*/)} />
      <View>
        <Text style={styles.headline}>Etwas ist schiefgelaufen!</Text>
        <Text
          style={styles.message}>
          {message}
        </Text>
      </View>
    </View>

  </View>

)

const styles = StyleSheet.create({

  container: {
    position: 'absolute',
    paddingHorizontal: 16,
    width: '100%',
    zIndex: 1,
  },
  inner: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: 20,
    borderRadius: 22,
    backgroundColor: '#fff',
    shadowColor: '#000',
    shadowRadius: 10,
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.12,
  },

  image: {
    width: 30,
    height: 30,
    marginRight: 15,
  },

  headline: {
    fontFamily: MON_600,
    color: COLOR_BLACK,
    fontSize: TEXT_SM,
    marginBottom: 4,
  },

  message: {
    fontSize: TEXT_SM,
    fontFamily: MON_500,
    color: COLOR_GREY
  },

})

export default Message


export interface ChangeProfileScreenFormValues {
  salutationId: string,
  firstName: string,
  lastName: string,
  street: string,
  zipcode: string,
  city: string,
  countryId: string,
  phoneNumber: string,
  //dressSize: number,
  //weddingDate: string,
}

export interface ChangeProfileScreenFormErrors {

}

export interface ChangeProfileScreenState {
  busy: boolean,
  salutations: [],
  countries: [],
  formValues: ChangeProfileScreenFormValues,
  formErrors: ChangeProfileScreenFormErrors,
  error?: string,
}

export const INIT = 'ChangeProfileScreen.init'
export const INIT_SUCCESS = 'ChangeProfileScreen.init.success'
export const INIT_FAILURE = 'ChangeProfileScreen.init.failure'

export const RESET = 'ChangeProfileScreen.reset'

export const SET_ERROR = 'ChangeProfileScreen.setError'

export const CHANGE_PROFILE = 'ChangeProfileScreen.register'
export const CHANGE_PROFILE_SUCCESS = 'ChangeProfileScreen.register.success'
export const CHANGE_PROFILE_FAILURE = 'ChangeProfileScreen.register.failure'

export interface InitAction {
  type: typeof INIT,
}

export interface InitSuccessAction {
  type: typeof INIT_SUCCESS,
  salutations: [],
  countries: [],
  profile: any,
}

export interface InitFailureAction {
  type: typeof INIT_FAILURE,
  error?: string,
}

export interface ResetAction {
  type: typeof RESET,
}

export interface SetErrorAction {
  type: typeof SET_ERROR,
  error?: string,
}

export interface ChangeProfileAction {
  type: typeof CHANGE_PROFILE,
}

export interface ChangeProfileSuccessAction {
  type: typeof CHANGE_PROFILE_SUCCESS,
  salutationId: string,
  firstName: string,
  lastName: string,
  street: string,
  zipcode: string,
  city: string,
  countryId: string,
  phoneNumber: string,
}

export interface ChangeProfileFailureAction {
  type: typeof CHANGE_PROFILE_FAILURE,
  error: any,
}

export type ChangeProfileScreenActionType =
  InitAction |
  InitSuccessAction |
  InitFailureAction |
  ResetAction |
  SetErrorAction |
  ChangeProfileAction |
  ChangeProfileSuccessAction |
  ChangeProfileFailureAction


export interface PromptModalState {
  visible: boolean,
  message: string,
  acceptLabel: string,
  dismissLabel: string,
  inputValue: string,
}

export const SHOW_PROMPT_MODAL = 'SHOW_PROMPT_MODAL'
export const PROMPT_MODAL_ACCEPTED = 'PROMPT_MODAL_ACCEPTED'
export const PROMPT_MODAL_DISMISSED = 'PROMPT_MODAL_DISMISSED'
export const SET_INPUT_VALUE = 'SET_INPUT_VALUE'

export interface ShowPromptModalAction {
  type: typeof SHOW_PROMPT_MODAL,
  message: string,
  acceptLabel: string,
  dismissLabel: string,
  defaultValue: string,
}

export interface PromptModalAcceptedAction {
  type: typeof PROMPT_MODAL_ACCEPTED,
  input: string,
}

export interface PromptModalDismissedAction {
  type: typeof PROMPT_MODAL_DISMISSED,
}

export interface SetInputValueAction {
  type: typeof SET_INPUT_VALUE,
  input: string,
}

export type PromptModalActionType =
  ShowPromptModalAction |
  PromptModalAcceptedAction |
  PromptModalDismissedAction |
  SetInputValueAction
